import { useState } from 'react';
import Datepicker from 'tailwind-datepicker-react';
import { IDatePickerProps } from 'tailwind-datepicker-react/types/Components/DatePicker';
import { dateObjToInputVal } from '../../utils/dateFunctions';

type Props = Omit<IDatePickerProps, 'show' | 'setShow' | 'onChange'> & {
   onChange?: (selectedDate: string) => void;
   inputClassName?: string;
};

export default function TailwindDatepicker({
   options,
   onChange,
   inputClassName,
   ...props
}: Props) {
   const [show, setShow] = useState<boolean>(false);

   const handleChange = (selectedDate: Date) => {
      onChange?.(dateObjToInputVal(selectedDate, true));
   };

   const handleClose = (state: boolean) => {
      setShow(state);
   };

   return (
      <Datepicker
         show={show}
         setShow={handleClose}
         onChange={handleChange}
         classNames='relative'
         options={{
            inputDateFormatProp: { dateStyle: 'short' },
            inputPlaceholderProp: 'Select Date',
            defaultDate: null,
            theme: {
               background: '',
               clearBtn: '',
               disabledText: '',
               icons: '',
               inputIcon: '',
               selected: '',
               text: '',
               todayBtn: '',
               input:
                  inputClassName ??
                  'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500',
            },
            ...options,
         }}
         {...props}
      />
   );
}
