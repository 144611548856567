import { TStudentResponse } from '../../../types/ReligiousEducation/Student';

type Props = {
   student: TStudentResponse;
};

export default function RegistrationsTable({ student }: Props) {
   // const location = useLocation();
   // const navigate = useNavigate();

   return (
      <div className='px-4 sm:px-6 lg:px-8 mt-10'>
         <div className='sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
               <h2 className='text-lg font-semibold text-gray-900'>
                  Registrations
               </h2>
            </div>

            {/* <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
               <Link to='/religious-education/new-registration'>
                  <button
                     type='button'
                     className='inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                     <PlusSmIcon className='h-5 w-5' />
                  </button>
               </Link>
            </div> */}
         </div>
         <div className='mt-10 flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
               <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                     <table className='min-w-full divide-y divide-gray-300'>
                        <thead className='bg-gray-50'>
                           <tr>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 School Year
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Grade
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Photography Permission
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Safety Class Permission
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Registration Fee
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Payment Status
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              ></th>
                           </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 bg-white'>
                           {/* <!-- Odd row --> */}
                           {student?.registrationId &&
                              student.registrationId.map(registration => {
                                 if (typeof registration !== 'object')
                                    return null;

                                 const eduYear =
                                    typeof registration?.schoolYear ===
                                       'object' && registration?.schoolYear;
                                 const schoolClass =
                                    typeof registration?.schoolClass ===
                                       'object' && registration?.schoolClass;
                                 return (
                                    <tr
                                       className='hover:bg-gray-100 cursor-pointer'
                                       key={registration._id}
                                       // onClick={() =>
                                       //    navigate(
                                       //       `/religious-education/registrations/${registration?.id}`
                                       //    )
                                       // }
                                    >
                                       <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                          {typeof eduYear === 'object'
                                             ? eduYear?.yearName
                                             : ''}
                                       </td>
                                       <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                          {typeof schoolClass === 'object'
                                             ? schoolClass?.className
                                             : ''}
                                       </td>
                                       {registration?.photographyPermission?.toLowerCase() ===
                                       'yes' ? (
                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-green-500'>
                                             <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='h-6 w-6'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                             >
                                                <path
                                                   strokeLinecap='round'
                                                   strokeLinejoin='round'
                                                   d='M5 13l4 4L19 7'
                                                />
                                             </svg>
                                          </td>
                                       ) : (
                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-red-500'>
                                             <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='h-6 w-6'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                             >
                                                <path
                                                   strokeLinecap='round'
                                                   strokeLinejoin='round'
                                                   d='M6 18L18 6M6 6l12 12'
                                                />
                                             </svg>
                                          </td>
                                       )}

                                       {registration?.safetyClassPermission?.toLowerCase() ===
                                       'yes' ? (
                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-green-500'>
                                             <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='h-6 w-6'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                             >
                                                <path
                                                   strokeLinecap='round'
                                                   strokeLinejoin='round'
                                                   d='M5 13l4 4L19 7'
                                                />
                                             </svg>
                                          </td>
                                       ) : (
                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-red-500'>
                                             <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='h-6 w-6'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                             >
                                                <path
                                                   strokeLinecap='round'
                                                   strokeLinejoin='round'
                                                   d='M6 18L18 6M6 6l12 12'
                                                />
                                             </svg>
                                          </td>
                                       )}

                                       <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                          {registration?.tuition
                                             ? `$${registration.tuition}`
                                             : ''}
                                       </td>

                                       <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                          {registration?.paid?.toLowerCase() ===
                                          'yes'
                                             ? 'Paid'
                                             : 'Unpaid'}
                                       </td>
                                       <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                          {/* <Link
                                             state={{
                                                registration: {
                                                   ...registration,
                                                   student,
                                                },
                                                from: location,
                                             }}
                                             to={`/religious-education/registrations/${registration.id}/edit`}
                                             className='text-indigo-600 hover:text-indigo-900'
                                          >
                                             Edit
                                          </Link> */}
                                       </td>
                                    </tr>
                                 );
                              })}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
