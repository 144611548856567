import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useInput from '../../../../hooks/useInput';
import ParentsCombobox from './ParentsCombobox';
import { useEffect } from 'react';
import MaritalInputsRCIA from './MaritalInputsRCIA';
import { dateObjToInputVal } from '../../../../utils/dateFunctions';
import BirthCertInput from './BirthCertificateInput';

const EditStudentForm = ({
   student,
   parents,
   editStudentFn,
   studentLoader,
}) => {
   const navigate = useNavigate();
   const { state: urlState } = useLocation();

   //Form Field States
   const [firstname, fNameAttr] = useInput(student.firstname || '');
   const [middlename, mNameAttr] = useInput(student?.middlename || '');
   const [lastname, lNameAttr] = useInput(student?.lastname || '');
   const [altID, altIDAttr] = useInput(student?.altID ?? '');
   const [gender, genderAttr] = useInput(student?.gender || '');
   const [type, typeAttr] = useInput(student?.type || '');
   const [livesWith, lwAttr] = useInput(student?.livesWith || '');
   const [status, statusAttr] = useInput(student?.status || '');
   const [schoolExclusions, otherSchoolAttr] = useInput(
      student?.school_excluded_from_sacraments || ''
   );

   //RCIA Student's States
   const [address, addressAttr] = useInput(student?.address || '');
   const [address2, address2Attr] = useInput(student?.address2 || '');
   const [city, cityAttr] = useInput(student?.city || '');
   const [state, stateAttr] = useInput(student?.state || '');
   const [zip, zipAttr] = useInput(student?.zip || '');
   const [email, emailAttr] = useInput(student?.email || '');
   const [religiousAffiliation, affiliationAttr] = useInput(
      student?.religiousAffiliation || ''
   );
   const [maritalStatus, maritalStatusAttr] = useInput(
      student?.maritalStatus || ''
   );
   const [marriageAuthor, marriageAuthorAttr] = useInput(
      student?.marriageAuthor || ''
   );
   const [marriageHistory, marriageHistoryAttr] = useInput(
      student?.marriageHistory || ''
   );
   const [prevMarriageAuthor, prevMarriageAuthorAttr] = useInput(
      student?.prevMarriageAuthor || ''
   );

   const [spouse_religiousAffiliation, spouse_affiliationAttr] = useInput(
      student?.spouse_religiousAffiliation || ''
   );
   const [spouse_marriageHistory, spouse_marriageHistoryAttr] = useInput(
      student?.spouse_marriageHistory || ''
   );
   const [spouse_prevMarriageAuthor, spouse_prevMarriageAuthorAttr] = useInput(
      student?.spouse_prevMarriageAuthor || ''
   );

   const [dateOfBirth, dobAttr] = useInput(
      dateObjToInputVal(student?.dateOfBirth)
   );
   const [notes, notesAttr] = useInput(student?.notes || '');

   const [professionOfFaith, setProfessionOfFaith] = useState(
      student?.professionOfFaith === true
   );
   const [professionOfFaithDate, professionOfFaithDateAttr] = useInput(
      dateObjToInputVal(student?.professionOfFaithDate)
   );

   //Parents State
   const [mother, setMother] = useState(null);
   const [father, setFather] = useState(null);
   const [guardian, setGuardian] = useState(null);

   useEffect(() => {
      if (student?.mother) {
         setMother(
            typeof student?.mother === 'object'
               ? student.mother
               : parents.find(parent => parent._id === student.mother)
         );
      }
      if (student?.father) {
         setFather(
            typeof student?.father === 'object'
               ? student.father
               : parents.find(parent => parent._id === student.father)
         );
      }
      if (student?.legalGuardian) {
         setGuardian(
            typeof student?.legalGuardian === 'object'
               ? student.legalGuardian
               : parents.find(parent => parent._id === student.legalGuardian)
         );
      }
   }, [student, parents]);

   //Files State
   const [birthCertFile, setBirthCertFile] = useState(
      student?.birthCertificate?.src ? { name: 'Birth_Certificate.pdf' } : null
   );

   const handleSubmit = async ev => {
      ev.preventDefault();

      if (!firstname || !lastname)
         return toast.error('Firstname and Lastname is required!');

      const formData = new FormData();

      formData.append('firstname', firstname.trim());
      formData.append('middlename', middlename.trim());
      formData.append('lastname', lastname.trim());
      if (altID) formData.append('altID', altID);
      !!gender && formData.append('gender', gender);
      formData.append('type', type);
      livesWith && formData.append('livesWith', livesWith);
      formData.append('status', status);
      schoolExclusions &&
         formData.append('school_excluded_from_sacraments', schoolExclusions);

      //RCIA Infos
      !!address && formData.append('address', address.trim());
      !!address2 && formData.append('address2', address2.trim());
      !!city && formData.append('city', city.trim());
      !!state && formData.append('state', state.trim());
      !!zip && formData.append('zip', zip.trim());
      !!email && formData.append('email', email.trim());
      !!religiousAffiliation &&
         formData.append('religiousAffiliation', religiousAffiliation);
      !!maritalStatus && formData.append('maritalStatus', maritalStatus);
      !!marriageAuthor && formData.append('marriageAuthor', marriageAuthor);
      !!marriageHistory && formData.append('marriageHistory', marriageHistory);
      !!prevMarriageAuthor &&
         formData.append('prevMarriageAuthor', prevMarriageAuthor);
      !!spouse_religiousAffiliation &&
         formData.append(
            'spouse_religiousAffiliation',
            spouse_religiousAffiliation
         );
      !!spouse_marriageHistory &&
         formData.append('spouse_marriageHistory', spouse_marriageHistory);
      !!spouse_prevMarriageAuthor &&
         formData.append(
            'spouse_prevMarriageAuthor',
            spouse_prevMarriageAuthor
         );

      if (dateOfBirth) formData.append('dateOfBirth', dateOfBirth);
      formData.append('notes', notes);
      mother?._id && formData.append('mother', mother._id);
      father?._id && formData.append('father', father._id);
      guardian?._id && formData.append('legalGuardian', guardian._id);
      birthCertFile != null &&
         birthCertFile?.path != null &&
         formData.append('birthCertificate', birthCertFile);

      if (student?.baptized === 'Baptized Protestant')
         formData.append('professionOfFaith', professionOfFaith);
      if (professionOfFaithDate)
         formData.append('professionOfFaithDate', professionOfFaithDate);

      try {
         await editStudentFn(student._id, formData);

         toast.success('Student data updated successfully');
         navigate(urlState?.from ? urlState.from : '/login', {
            replace: true,
         });
      } catch (error) {
         if (error) {
            toast.error(
               error?.message ||
                  'Failed to update student information, Please try again.'
            );
         }
      }
   };

   return (
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
         {/* <!--add podcast input page--> */}
         <div className='px-4 sm:px-6 lg:px-8 mt-20 mb-40'>
            <div className='sm:flex sm:items-center'>
               <div className='sm:flex-auto'>
                  <h1 className='text-xl font-semibold text-gray-900'>
                     Edit Student
                  </h1>
                  <p className='mt-2 text-sm text-gray-700'>
                     Edit Student details.
                  </p>
               </div>
               <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'></div>
            </div>

            <form
               className='mt-10 space-y-8 divide-y divide-gray-200'
               onSubmit={handleSubmit}
            >
               <div className='space-y-8 divide-y divide-gray-200'>
                  <div>
                     <fieldset>
                        <legend className='block text-sm font-medium text-gray-700'>
                           Student Name
                        </legend>
                        <div className='mt-1 -space-y-px rounded-md bg-white shadow-sm'>
                           <div className='flex -space-x-px'>
                              <div className='w-1/3 min-w-0 flex-1'>
                                 <label htmlFor='firstname' className='sr-only'>
                                    First Name
                                 </label>
                                 <input
                                    type='text'
                                    name='firstname'
                                    id='firstname'
                                    {...fNameAttr}
                                    className='relative block w-full rounded-none rounded-bl-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    placeholder='First Name'
                                 />
                              </div>
                              <div className='min-w-0 flex-1'>
                                 <label
                                    htmlFor='middlename'
                                    className='sr-only'
                                 >
                                    Middle Name
                                 </label>
                                 <input
                                    type='text'
                                    name='middlename'
                                    id='middlename'
                                    {...mNameAttr}
                                    className='relative block w-full rounded-none rounded-br-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    placeholder='Middle Name'
                                 />
                              </div>
                              <div className='min-w-0 flex-1'>
                                 <label htmlFor='lastname' className='sr-only'>
                                    Last Name
                                 </label>
                                 <input
                                    type='text'
                                    name='lastname'
                                    id='lastname'
                                    {...lNameAttr}
                                    className='relative block w-full rounded-none rounded-br-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    placeholder='Last Name'
                                 />
                              </div>
                           </div>
                        </div>
                     </fieldset>
                     <fieldset className='mt-5'>
                        <legend className='block text-sm font-medium text-gray-700'>
                           Alt ID
                        </legend>
                        <div className='mt-1 -space-y-px rounded-md bg-white shadow-sm'>
                           <div className='flex -space-x-px'>
                              <div className='w-1/3 min-w-0'>
                                 <label htmlFor='altID' className='sr-only'>
                                    Alt ID
                                 </label>
                                 <input
                                    type='text'
                                    name='altID'
                                    id='altID'
                                    {...altIDAttr}
                                    className='relative block w-full rounded-none rounded-bl-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    placeholder='Alt ID'
                                 />
                              </div>
                           </div>
                        </div>
                     </fieldset>

                     {/* <!--gender--> */}

                     <div className='-space-y-px rounded-md bg-white shadow-sm mt-5'>
                        <div className='flex -space-x-px'>
                           <div className='w-1/2 min-w-0 flex-1'>
                              <label
                                 htmlFor='gender'
                                 className='block text-sm font-medium text-gray-700 mb-2'
                              >
                                 Gender
                              </label>
                              <select
                                 id='gender'
                                 name='gender'
                                 {...genderAttr}
                                 className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                              >
                                 <option value=''>--Choose Gender--</option>
                                 <option>Male</option>
                                 <option>Female</option>
                              </select>
                           </div>

                           <div className='w-1/2 min-w-0 flex-1'>
                              <label
                                 htmlFor='dateofbirth'
                                 className='block text-sm font-medium text-gray-700 mb-2'
                              >
                                 Birthdate
                              </label>
                              <input
                                 type='date'
                                 name='dateofbirth'
                                 id='dateofbirth'
                                 {...dobAttr}
                                 className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                 placeholder='Date of Birth'
                              />
                           </div>
                        </div>
                     </div>

                     {/* <!--types and lives with--> */}

                     <div className='mt-5'>
                        <fieldset>
                           <div className='mt-1 -space-y-px rounded-md bg-white shadow-sm'>
                              <div className='flex -space-x-px'>
                                 <div className='w-1/4 min-w-0 flex-1'>
                                    <legend className='block text-sm font-medium text-gray-700 mb-2'>
                                       Student Type
                                    </legend>

                                    <label htmlFor='type' className='sr-only'>
                                       Student Type
                                    </label>
                                    <select
                                       id='type'
                                       name='type'
                                       {...typeAttr}
                                       className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    >
                                       <option value=''>
                                          --Choose Student Type --
                                       </option>
                                       <option>Church Student</option>
                                       <option>School Student</option>
                                       <option value='RCIA'>OCIA</option>
                                    </select>
                                 </div>

                                 <div className='w-1/4 min-w-0 flex-1'>
                                    <legend className='block text-sm font-medium text-gray-700 mb-2'>
                                       Lives With...
                                    </legend>

                                    <label
                                       htmlFor='liveswith'
                                       className='sr-only'
                                    >
                                       Lives With
                                    </label>
                                    <select
                                       id='liveswith'
                                       name='liveswith'
                                       {...lwAttr}
                                       className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    >
                                       <option value=''>
                                          --Choose Lives With--
                                       </option>
                                       <option>Both Parents</option>
                                       <option>Mother</option>
                                       <option>Father</option>
                                       <option>Guardian</option>
                                    </select>
                                 </div>

                                 <div className='w-1/4 min-w-0 flex-1'>
                                    <legend className='block text-sm font-medium text-gray-700 mb-2'>
                                       Status
                                    </legend>

                                    <label htmlFor='status' className='sr-only'>
                                       Status
                                    </label>
                                    <select
                                       id='status'
                                       name='status'
                                       {...statusAttr}
                                       className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    >
                                       <option value=''>
                                          --Choose Exclusions--
                                       </option>
                                       <option>Current</option>
                                       <option>Inactive</option>
                                       <option>Graduated</option>
                                    </select>
                                 </div>

                                 <div className='w-1/4 min-w-0 flex-1'>
                                    <legend className='block text-sm font-medium text-gray-700 mb-2'>
                                       School Exclusions
                                    </legend>

                                    <label
                                       htmlFor='exclusions'
                                       className='sr-only'
                                    >
                                       Exclusions
                                    </label>
                                    <select
                                       id='exclusions'
                                       name='exclusions'
                                       {...otherSchoolAttr}
                                       className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    >
                                       <option value=''>
                                          --Choose Exclusions--
                                       </option>
                                       <option>Sacramental Program</option>
                                       <option>Excluded from Sacraments</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                        </fieldset>
                     </div>

                     {/* <!--address--> */}
                     {type.toLowerCase() === 'rcia' && (
                        <>
                           <legend className='block text-sm font-medium text-gray-700 mt-5'>
                              Address Details
                           </legend>
                           <div className='mt-1 -space-y-px rounded-md bg-white shadow-sm'>
                              <div className='flex -space-x-px'>
                                 <div className='w-1/3 min-w-0 flex-1'>
                                    <label
                                       htmlFor='address'
                                       className='sr-only'
                                    >
                                       Address
                                    </label>
                                    <input
                                       type='text'
                                       name='address'
                                       id='address'
                                       {...addressAttr}
                                       className='relative block w-full rounded-none rounded-bl-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                       placeholder='Address'
                                    />
                                 </div>
                                 <div className='min-w-0 flex-1'>
                                    <label
                                       htmlFor='address2'
                                       className='sr-only'
                                    >
                                       Address2
                                    </label>
                                    <input
                                       type='text'
                                       name='address2'
                                       id='address2'
                                       {...address2Attr}
                                       className='relative block w-full rounded-none rounded-br-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                       placeholder='Address2'
                                    />
                                 </div>
                                 <div className='min-w-0 flex-1'>
                                    <label htmlFor='zip' className='sr-only'>
                                       Zip
                                    </label>
                                    <input
                                       type='text'
                                       name='zip'
                                       id='zip'
                                       {...zipAttr}
                                       className='relative block w-full rounded-none rounded-br-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                       placeholder='Zip'
                                    />
                                 </div>
                                 <div className='min-w-0 flex-1'>
                                    <label htmlFor='city' className='sr-only'>
                                       City
                                    </label>
                                    <input
                                       type='text'
                                       name='city'
                                       id='city'
                                       {...cityAttr}
                                       className='relative block w-full rounded-none rounded-br-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                       placeholder='City'
                                    />
                                 </div>
                                 <div className='min-w-0 flex-1'>
                                    <label htmlFor='state' className='sr-only'>
                                       State
                                    </label>
                                    <input
                                       type='text'
                                       name='state'
                                       id='state'
                                       {...stateAttr}
                                       className='relative block w-full rounded-none rounded-br-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                       placeholder='State'
                                    />
                                 </div>
                              </div>
                           </div>
                           <div>
                              <label
                                 htmlFor='email'
                                 className='mt-5 block text-sm font-medium text-gray-700'
                              >
                                 Email
                              </label>
                              <div className='mt-1'>
                                 <input
                                    type='email'
                                    name='email'
                                    id='email'
                                    {...emailAttr}
                                    className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                    placeholder='you@example.com'
                                 />
                              </div>
                           </div>
                        </>
                     )}

                     {/* Start Profession Of Faith fields */}
                     {student?.baptized === 'Baptized Protestant' && (
                        <div className='mt-5 -space-y-px'>
                           <div>
                              <h3 className='text-sm font-bold leading-6 text-gray-700'>
                                 Profession of Faith
                              </h3>
                           </div>
                           <div className='ml-10'>
                              <fieldset>
                                 <div className='mt-2 space-y-4'>
                                    <div className='relative flex items-start'>
                                       <div className='flex h-5 items-center'>
                                          <input
                                             id='professionOfFaith'
                                             name='professionOfFaith'
                                             type='checkbox'
                                             checked={professionOfFaith}
                                             onChange={() =>
                                                setProfessionOfFaith(
                                                   prev => !prev
                                                )
                                             }
                                             className='h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                                          />
                                       </div>
                                       <div className='ml-3 text-sm'>
                                          <label
                                             htmlFor='professionOfFaith'
                                             className='font-medium text-gray-700'
                                          >
                                             Profession of Faith
                                          </label>
                                       </div>
                                    </div>
                                    {professionOfFaith && (
                                       <div className='w-1/3 min-w-0 flex-1'>
                                          {/* <!--checkbox end--> */}
                                          <legend className='block text-sm font-medium text-gray-700'>
                                             Profession of Faith Date
                                          </legend>

                                          <label
                                             htmlFor='professionOfFaithDate'
                                             className='sr-only'
                                          >
                                             Profession of Faith Date
                                          </label>
                                          <input
                                             type='date'
                                             name='professionOfFaithDate'
                                             id='professionOfFaithDate'
                                             {...professionOfFaithDateAttr}
                                             className='relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                          />
                                       </div>
                                    )}
                                 </div>
                              </fieldset>
                           </div>
                        </div>
                     )}
                     {/* End Profession Of Faith fields */}

                     {/* <!--mother lookup--> */}
                     <>
                        <ParentsCombobox
                           selectedState={[mother, setMother]}
                           items={parents.filter(
                              parent => parent.role.toLowerCase() === 'mother'
                           )}
                           label='Assign Mother'
                        />
                        {/* <!--mother lookup end--> */}

                        {/* <!--father lookup--> */}
                        <ParentsCombobox
                           label='Assign Father'
                           selectedState={[father, setFather]}
                           items={parents.filter(
                              parent => parent.role.toLowerCase() === 'father'
                           )}
                        />

                        {/* <!--father lookup end--> */}

                        {/* <!--guardian lookup--> */}

                        <ParentsCombobox
                           label='Assign Legal Guardian'
                           selectedState={[guardian, setGuardian]}
                           items={parents.filter(
                              parent =>
                                 parent.role.toLowerCase() === 'legal guardian'
                           )}
                        />
                     </>
                     {/* <!--guardian lookup end--> */}

                     {/* <!--marital status of RCIA student only--> */}
                     {type.toLowerCase() === 'rcia' && (
                        <MaritalInputsRCIA
                           maritalStatus={maritalStatus}
                           marriageHistory={marriageHistory}
                           spouse_marriageHistory={spouse_marriageHistory}
                           affiliationAttr={affiliationAttr}
                           maritalStatusAttr={maritalStatusAttr}
                           marriageAuthorAttr={marriageAuthorAttr}
                           marriageHistoryAttr={marriageHistoryAttr}
                           prevMarriageAuthorAttr={prevMarriageAuthorAttr}
                           spouse_affiliationAttr={spouse_affiliationAttr}
                           spouse_marriageHistoryAttr={
                              spouse_marriageHistoryAttr
                           }
                           spouse_prevMarriageAuthorAttr={
                              spouse_prevMarriageAuthorAttr
                           }
                        />
                     )}
                     {/* <!--end new martial info--> */}

                     <div className='mt-20 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                        <div className='sm:col-span-6'>
                           <label
                              htmlFor='about'
                              className='block text-sm font-medium text-gray-700'
                           >
                              Notes
                           </label>
                           <div className='mt-1'>
                              <textarea
                                 id='about'
                                 name='about'
                                 rows='3'
                                 {...notesAttr}
                                 className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md'
                              ></textarea>
                           </div>
                        </div>

                        {/* <!--baptism cert upload--> */}

                        {/* <!--first communion cert upload--> */}

                        {/* <!--confirmation cert upload--> */}
                        {birthCertFile ? (
                           <div className='sm:col-span-6'>
                              <label
                                 htmlFor='birthCertificate'
                                 className='block text-sm font-medium text-gray-700'
                              >
                                 Birth Certificate - {birthCertFile.name}
                              </label>
                              <div className='mt-1 flex items-center'>
                                 <button
                                    type='button'
                                    onClick={() => setBirthCertFile(null)}
                                    className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                 >
                                    Change
                                 </button>
                              </div>
                           </div>
                        ) : (
                           <BirthCertInput setFile={setBirthCertFile} />
                        )}
                     </div>
                  </div>
               </div>

               <div className='pt-5'>
                  <div className='flex justify-end'>
                     <button
                        type='button'
                        onClick={() => {
                           navigate(-1);
                        }}
                        className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                     >
                        Cancel
                     </button>
                     <button
                        type='submit'
                        disabled={studentLoader}
                        className='disabled:bg-gray-400 disabled:cursor-progress disabled:hover:bg-gray-400 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                     >
                        {studentLoader ? 'Saving...' : 'Save'}
                     </button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
};

export default EditStudentForm;
