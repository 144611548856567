import React, { useState } from 'react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Cookie from 'js-cookie';

type Props = {
   debug?: boolean;
};

const CookieConsent = ({ debug }: Props) => {
   const [showConsent, setShowConsent] = useState(false);
   useEffect(() => {
      if (Cookie.get('cookie_consent') === undefined || debug) {
         setShowConsent(true);
      }
   }, [debug]);

   const acceptConsent = () => {
      Cookie.set('cookie_consent', 'true', {
         sameSite: 'strict',
         expires: 365,
      });
      setShowConsent(false);
   };

   return (
      <>
         <Outlet />
         {showConsent && (
            <div className='bg-gray-200 text-gray-800 p-4 fixed bottom-0 left-0 right-0 flex items-center justify-center'>
               <div className='text-center'>
                  <p className='text-lg mb-2'>
                     We use cookies to improve your experience on our website.
                     By continuing to browse, you consent to our use of cookies.
                  </p>
                  <button
                     onClick={acceptConsent}
                     className='bg-blue-500 hover:bg-blue-600 text-white text-sm py-2 px-4 rounded-full'
                  >
                     Accept
                  </button>
               </div>
            </div>
         )}
      </>
   );
};

export default CookieConsent;
