import { HomeIcon } from '@heroicons/react/outline';
import SidebarItemsComp from '../Common/SidebarItemsComp';

const WebsiteSidebarItems = ({ mobile, setSidebarOpen }) => {
   const primarySidebarItems = [
      {
         name: 'Home',
         href: '/website',
         svg: HomeIcon,
         end: true,
      },
   ];
   return (
      <SidebarItemsComp
         mobile={mobile}
         primarySidebarItems={primarySidebarItems}
         setSidebarOpen={setSidebarOpen}
      />
   );
};

export default WebsiteSidebarItems;
