import { toast } from 'react-toastify';

const AddAttendanceStep1 = ({
   selectedClass,
   selectedDate,
   setStep,
   schoolClasses,
   dateInputAttr,
   classInputAttr,
}) => {
   const handleSubmit = e => {
      e.preventDefault();
      if (!selectedDate || selectedDate == null)
         return toast.error('Date is required!');
      if (!selectedClass || selectedClass == null)
         return toast.error('Please select a class!');

      setStep(2);
   };
   return (
      <div className='max-w-7xl xl:w-3/5 xl:min-w-0 lg:min-w-3/4 md:min-w-4/5 sm:min-w-5/6 sm:w-auto w-full mx-auto px-4 sm:px-6 lg:px-8'>
         <nav aria-label='Progress' className='mt-20'>
            <ol className='space-y-4 md:flex md:space-y-0 md:space-x-8'>
               <li className='md:flex-1'>
                  {/* <!-- Completed Step --> */}
                  <a
                     href='#k'
                     className='flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0'
                  >
                     <span className='text-sm font-medium text-indigo-600'>
                        Step 1
                     </span>
                     <span className='text-sm font-medium'>Choose Date</span>
                  </a>
               </li>

               <li className='md:flex-1'>
                  {/* <!-- Current Step --> */}
                  <a
                     href='#k'
                     className='group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0'
                     aria-current='step'
                  >
                     <span className='text-sm font-medium text-gray-500 group-hover:text-gray-700'>
                        Step 2
                     </span>
                     <span className='text-sm font-medium'>
                        Choose Students
                     </span>
                  </a>
               </li>

               <li className='md:flex-1'>
                  {/* <!-- Upcoming Step --> */}
                  <a
                     href='#k'
                     className='group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0'
                  >
                     <span className='text-sm font-medium text-gray-500 group-hover:text-gray-700'>
                        Step 3
                     </span>
                     <span className='text-sm font-medium'>Preview</span>
                  </a>
               </li>
            </ol>
         </nav>
         <form onSubmit={handleSubmit}>
            <div className='px-4 sm:px-6 lg:px-8 mt-20'>
               <div className='sm:flex-auto'>
                  <h1 className='text-xl font-semibold text-gray-900'>
                     Select Date
                  </h1>
               </div>

               <label htmlFor='classDate' className='sr-only'>
                  Class Date
               </label>
               <input
                  type='date'
                  name='classDate'
                  id='classdate'
                  {...dateInputAttr}
                  className='relative block w-full rounded-none rounded-bl-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  placeholder='Date of Class'
               />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 mt-10'>
               <div className='sm:flex-auto'>
                  <h1 className='text-xl font-semibold text-gray-900'>
                     Select Class
                  </h1>
               </div>

               <label htmlFor='class' className='sr-only'>
                  Class
               </label>

               <select
                  id='class'
                  name='class'
                  {...classInputAttr}
                  className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
               >
                  <option value=''>Choose Class</option>
                  {schoolClasses?.map(eachClass => (
                     <option key={eachClass?._id} value={eachClass?._id}>
                        {eachClass?.className}
                     </option>
                  ))}
               </select>
            </div>
            <div className='px-4 sm:px-6 lg:px-8 mt-10'>
               <div className='flex justify-end'>
                  <button
                     type='submit'
                     className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                     Next
                  </button>
               </div>
            </div>
         </form>
      </div>
   );
};

export default AddAttendanceStep1;
