import React from 'react';

const MaritalInputsRCIA = ({
   maritalStatus,
   marriageHistory,
   spouse_marriageHistory,
   affiliationAttr,
   maritalStatusAttr,
   marriageAuthorAttr,
   marriageHistoryAttr,
   prevMarriageAuthorAttr,
   spouse_affiliationAttr,
   spouse_marriageHistoryAttr,
   spouse_prevMarriageAuthorAttr,
}) => {
   return (
      <>
         <div className='py-10'>
            <fieldset>
               <div className='mt-1 -space-y-px rounded-md bg-white shadow-sm'>
                  <h2 className='text-2xl font-bold text-center'>
                     RCIA Student's Marriage History
                  </h2>

                  <div className='flex -space-x-px pt-5'>
                     <div className='w-1/3 min-w-0 flex-1'>
                        <legend className='block text-sm font-medium text-gray-700 mb-2'>
                           Current Religious Affiliation
                        </legend>

                        <label
                           htmlFor='religiousAffiliation'
                           className='sr-only'
                        >
                           Current Religious Affiliation
                        </label>
                        <select
                           id='religiousAffiliation'
                           name='religiousAffiliation'
                           {...affiliationAttr}
                           className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                        >
                           <option value=''>
                              --Choose Current Religious Affilation--
                           </option>
                           <option>None</option>
                           <option>Catholic</option>
                           <option>Protestant</option>
                           <option>Orthodox</option>
                           <option>Muslim</option>
                           <option>Jewish</option>
                           <option>Other</option>
                        </select>
                     </div>
                  </div>

                  <div className='flex -space-x-px pt-5'>
                     <div className='w-1/3 min-w-0 flex-1'>
                        <legend className='block text-sm font-medium text-gray-700 mb-2'>
                           Current Marital Status
                        </legend>

                        <label htmlFor='maritalStatus' className='sr-only'>
                           Current Marital Status
                        </label>
                        <select
                           id='maritalStatus'
                           name='maritalStatus'
                           {...maritalStatusAttr}
                           className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                        >
                           <option value=''>--Choose Marriage Status--</option>
                           <option>Single</option>
                           <option>Married</option>
                           <option>Engaged</option>
                        </select>
                     </div>
                  </div>

                  {maritalStatus === 'Married' ? (
                     <div className='flex -space-x-px pt-5'>
                        <div className='w-1/3 min-w-0 flex-1'>
                           <legend className='block text-sm font-medium text-gray-700 mb-2'>
                              Married By Authority Of
                           </legend>

                           <label htmlFor='marriageAuthor' className='sr-only'>
                              Married By Authority Of
                           </label>
                           <select
                              id='marriageAuthor'
                              name='marriageAuthor'
                              {...marriageAuthorAttr}
                              className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                           >
                              <option value=''>
                                 --Choose Marriage Authority--
                              </option>
                              <option>Catholic Priest</option>
                              <option>Christian Minister</option>
                              <option>Non-Christian Minister</option>
                              <option>Civil Government</option>
                           </select>
                        </div>
                     </div>
                  ) : null}

                  <div className='flex -space-x-px pt-5'>
                     <div className='w-1/3 min-w-0 flex-1'>
                        <legend className='block text-sm font-medium text-gray-700 mb-2'>
                           Marriage History
                        </legend>

                        <label htmlFor='marriageHistory' className='sr-only'>
                           Marriage History
                        </label>
                        <select
                           id='marriageHistory'
                           name='marriageHistory'
                           {...marriageHistoryAttr}
                           className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                        >
                           <option value=''>
                              --Choose Prior Marriage Status--
                           </option>
                           <option>Never Married</option>
                           <option>First Marriage</option>
                           <option>Previously Married</option>
                        </select>
                     </div>
                  </div>

                  {marriageHistory === 'Previously Married' ? (
                     <div className='flex -space-x-px pt-5'>
                        <div className='w-1/3 min-w-0 flex-1'>
                           <legend className='block text-sm font-medium text-gray-700 mb-2'>
                              Previous Marriage Authority
                           </legend>

                           <label
                              htmlFor='prevMarriageAuthor'
                              className='sr-only'
                           >
                              Spouse Marriage History
                           </label>
                           <select
                              id='prevMarriageAuthor'
                              name='prevMarriageAuthor'
                              {...prevMarriageAuthorAttr}
                              className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                           >
                              <option value=''>
                                 --Choose Prior Marriage Status--
                              </option>
                              <option>Catholic Priest</option>
                              <option>Christian Minister</option>
                              <option>Non-Christian Minister</option>
                              <option>Civil Government</option>
                           </select>
                        </div>
                     </div>
                  ) : null}
               </div>
            </fieldset>
         </div>

         {maritalStatus === 'Married' ? (
            <div className='py-10'>
               <fieldset>
                  <div className='mt-1 -space-y-px rounded-md bg-white shadow-sm'>
                     <h2 className='text-2xl font-bold text-center'>
                        Spouse's Sacramental Details
                     </h2>

                     <div className='flex -space-x-px pt-5'>
                        <div className='w-1/3 min-w-0 flex-1'>
                           <legend className='block text-sm font-medium text-gray-700 mb-2'>
                              Current Religious Affiliation
                           </legend>

                           <label
                              htmlFor='spouse_religiousAffiliation'
                              className='sr-only'
                           >
                              Spouse Marriage History
                           </label>
                           <select
                              id='spouse_religiousAffiliation'
                              name='spouse_religiousAffiliation'
                              {...spouse_affiliationAttr}
                              className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                           >
                              <option value=''>
                                 --Choose Prior Marriage Status--
                              </option>
                              <option>None</option>
                              <option>Catholic</option>
                              <option>Protestant</option>
                              <option>Orthodox</option>
                              <option>Muslim</option>
                              <option>Jewish</option>
                              <option>Other</option>
                           </select>
                        </div>
                     </div>

                     <div className='flex -space-x-px pt-5'>
                        <div className='w-1/3 min-w-0 flex-1'>
                           <legend className='block text-sm font-medium text-gray-700 mb-2'>
                              Spouse's Marriage History
                           </legend>

                           <label
                              htmlFor='spouse_marriageHistory'
                              className='sr-only'
                           >
                              Spouse Marriage History
                           </label>
                           <select
                              id='spouse_marriageHistory'
                              name='spouse_marriageHistory'
                              {...spouse_marriageHistoryAttr}
                              className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                           >
                              <option value=''>
                                 --Choose Prior Marriage Status--
                              </option>
                              <option>First Marriage</option>
                              <option>Previously Married</option>
                           </select>
                        </div>
                     </div>
                     {spouse_marriageHistory === 'Previously Married' ? (
                        <div className='flex -space-x-px pt-5'>
                           <div className='w-1/3 min-w-0 flex-1'>
                              <legend className='block text-sm font-medium text-gray-700 mb-2'>
                                 Previous Marriage Authority
                              </legend>

                              <label
                                 htmlFor='spouse_prevMarriageAuthor'
                                 className='sr-only'
                              >
                                 Spouse Marriage History
                              </label>
                              <select
                                 id='spouse_prevMarriageAuthor'
                                 name='spouse_prevMarriageAuthor'
                                 {...spouse_prevMarriageAuthorAttr}
                                 className='relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                              >
                                 <option value=''>
                                    --Choose Prior Marriage Status--
                                 </option>
                                 <option>Catholic Priest</option>
                                 <option>Christian Minister</option>
                                 <option>Non-Christian Minister</option>
                                 <option>Civil Government</option>
                              </select>
                           </div>
                        </div>
                     ) : null}
                  </div>
               </fieldset>
            </div>
         ) : null}
      </>
   );
};

export default MaritalInputsRCIA;
