import { useQuery } from '@tanstack/react-query';
import NotFound from '../../../components/NotFound';
import PageSpinner from '../../../components/UI/PageSpinner';
import { useTeacherContext } from '../../../context/ReligiousEducation/TeacherContext';
import AttendanceComp from '../../../components/ReligiousEducation/Reports/Attendance/AttendanceComp';

export default function Attendance() {
   const {
      getCurrentSchoolYear,
      getClasses,
      getCurrentRegistrations,
      getSchoolYears,
   } = useTeacherContext();

   const { isLoading: isYearLoading, data: currentSchoolYear } = useQuery({
      queryFn: getCurrentSchoolYear,
      queryKey: ['currentYear'],
   });

   const { data: schoolYears, isLoading: isYearsLoading } = useQuery({
      queryFn: getSchoolYears,
      queryKey: ['schoolYears'],
   });

   const { isLoading: isClassLoading, data: schoolClasses } = useQuery({
      queryKey: ['classes'],
      queryFn: getClasses,
   });

   const { isLoading: isRegistrationsLoading, data: registrations } = useQuery({
      queryKey: ['registrations', 'all'],
      queryFn: () => getCurrentRegistrations(),
   });

   if (
      isYearLoading ||
      isClassLoading ||
      isRegistrationsLoading ||
      isYearsLoading
   )
      return <PageSpinner />;

   if (!currentSchoolYear || !schoolClasses || !registrations || !schoolYears)
      return <NotFound />;

   return (
      <AttendanceComp
         currentSchoolYear={currentSchoolYear}
         registrations={registrations}
         schoolClasses={schoolClasses}
         schoolYear={schoolYears}
      />
   );
}
