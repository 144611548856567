import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PersistLogin from './components/AuthComponents/PersistLogin';
import Login from './components/AuthComponents/Login';
import ProtectedRoute from './components/AuthComponents/ProtectedRoute';
import { SearchProvider } from './context/SidebarSearchContext';
import CookieConsent from './Pages/CookieConsent';
import DashboardLayout from './Layouts/Common/Layout';
import { PriestProvider } from './context/PriestContext';
import ErrorBoundary from './components/UI/ErrorBoundary';
import { AppProvider } from './context/AppContext';
import Dashboard from './Pages/Dashboard/Dashboard';
import HandleParentsUnsub from './Pages/PublicPages/HandleParentsUnsub';
import AuthorizeTeacher from './Pages/Religious_Education/UserLogins/AuthorizeTeacher';
import TeacherDashboardRoutes from './Pages/Teacher/Routes';
import { WeddingProvider } from './context/WeddingContext';
import VideoTrainings from './Pages/VideoTraining/VideoTrainings';
import BaptismRoutes from './Pages/Baptisms/Routes';
import GiftStoreRoutes from './Pages/GiftStore/Routes';

//Lazy Imports
// const SignUp = lazy(() => import('./components/AuthComponents/SignUp'));
const SignupHold = lazy(() => import('./components/AuthComponents/SignupHold'));
const PublicRoutes = lazy(() => import('./Pages/PublicPages/Routes'));
const NotFound = lazy(() => import('./components/NotFound'));
const Unauthorized = lazy(
   () => import('./components/AuthComponents/Unauthorized')
);
const Future = lazy(() => import('./components/Future'));
const ParentDashboardRoutes = lazy(() => import('./Pages/Parents/Routes'));
const FAQRoutes = lazy(() => import('./Pages/FAQ/Routes'));
const AuthorizeParent = lazy(
   () => import('./Pages/Religious_Education/UserLogins/AuthorizeParent')
);
const EducationUserRoutes = lazy(
   () => import('./Pages/Religious_Education/UserLogins/Routes')
);
const SuperAdminRegister = lazy(
   () => import('./components/AuthComponents/SuperAdminRegister')
);
// const SignUp = lazy(() => import('./components/AuthComponents/SignUp'));
const PrivacyPolicy = lazy(() => import('./Pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./Pages/TermsOfService'));
const ForgotPassword = lazy(
   () => import('./components/AuthComponents/ForgotPassword')
);
const ResetPassword = lazy(
   () => import('./components/AuthComponents/ResetPassword')
);
const AdminRoutes = lazy(() => import('./Pages/SuperAdmin/AdminRoutes'));
const WeddingRoutes = lazy(() => import('./Pages/Weddings/Routes'));
const EducationRoutes = lazy(
   () => import('./Pages/Religious_Education/Routes')
);
const BillingRoutes = lazy(() => import('./Pages/Billing/Routes'));
const SettingsRoutes = lazy(() => import('./Pages/Settings/Routes'));
const WebsiteRoutes = lazy(() => import('./Pages/Website/Routes'));
const FuneralRoutes = lazy(() => import('./Pages/Funerals/Routes'));

export const ROLES = {
   ChurchUser: 1210,
   Admin: 2120,
   SchoolUser: 1215,
};

function App() {
   return (
      <Routes>
         <Route element={<ErrorBoundary />}>
            <Route element={<PersistLogin />}>
               <Route element={<CookieConsent />}>
                  <Route path='/*' element={<PublicRoutes />} />
                  <Route path='/login' element={<Login />} />
                  <Route
                     path='/:churchAndYearId/re/*'
                     element={<EducationUserRoutes />}
                  />
                  <Route path='login/re/parent' element={<AuthorizeParent />} />
                  <Route
                     path='login/re/teacher'
                     element={<AuthorizeTeacher />}
                  />
                  <Route
                     path='/forgotten-password'
                     element={<ForgotPassword />}
                  />
                  <Route path='/reset-password' element={<ResetPassword />} />
                  {/* <Route path='/register' element={<SignUp />} /> */}
                  <Route path='/register' element={<SignupHold />} />
                  <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                  <Route
                     path='/terms-of-service'
                     element={<TermsOfService />}
                  />
               </Route>
               <Route path='/admin-register' element={<SuperAdminRegister />} />
               <Route path='/unauthorized' element={<Unauthorized />} />
               <Route element={<AppProvider />}>
                  <Route element={<SearchProvider />}>
                     <Route element={<PriestProvider />}>
                        <Route element={<DashboardLayout />}>
                           <Route element={<ErrorBoundary />}>
                              <Route
                                 element={
                                    <ProtectedRoute
                                       allowedRoles={[ROLES.ChurchUser]}
                                    />
                                 }
                              >
                                 <Route element={<WeddingProvider />}>
                                    <Route
                                       element={<Dashboard />}
                                       path='/dashboard'
                                    />
                                    <Route
                                       path='/dashboard/video-training'
                                       element={<VideoTrainings />}
                                    />
                                    <Route
                                       path='/weddings/*'
                                       element={<WeddingRoutes />}
                                    />
                                 </Route>
                                 <Route
                                    path='/baptisms/*'
                                    element={<BaptismRoutes />}
                                 />
                                 <Route
                                    path='/funerals/*'
                                    element={<FuneralRoutes />}
                                 />
                                 <Route
                                    path='/gift-store/*'
                                    element={<GiftStoreRoutes />}
                                 />

                                 {/* New Dropdown Items */}
                                 <Route
                                    path='/donor-management/*'
                                    element={<Future />}
                                 />
                                 <Route
                                    path='/sacramental-records/*'
                                    element={<Future />}
                                 />
                                 <Route
                                    path='/website/*'
                                    element={<WebsiteRoutes />}
                                 />
                              </Route>
                              <Route
                                 element={
                                    <ProtectedRoute
                                       allowedRoles={[
                                          ROLES.ChurchUser,
                                          ROLES.SchoolUser,
                                          'teacher',
                                       ]}
                                    />
                                 }
                              >
                                 <Route
                                    path='/religious-education/*'
                                    element={<EducationRoutes />}
                                 />
                                 <Route
                                    path='/profile/*'
                                    element={<Future />}
                                 />
                                 <Route
                                    path='/billing/*'
                                    element={<BillingRoutes />}
                                 />
                                 <Route
                                    path='/settings/*'
                                    element={<SettingsRoutes />}
                                 />
                              </Route>
                           </Route>
                        </Route>
                     </Route>
                     <Route
                        element={
                           <ProtectedRoute allowedRoles={[ROLES.Admin]} />
                        }
                     >
                        <Route
                           path='/super-admin/*'
                           element={<AdminRoutes />}
                        />
                     </Route>
                  </Route>
               </Route>
               <Route element={<ProtectedRoute allowedRoles={['parent']} />}>
                  <Route
                     path='/re/parent/*'
                     element={<ParentDashboardRoutes />}
                  />
               </Route>
               <Route element={<ProtectedRoute allowedRoles={['teacher']} />}>
                  <Route
                     path='/re/teacher/*'
                     element={<TeacherDashboardRoutes />}
                  />
               </Route>
            </Route>
            <Route
               path='/unsubscribe-parent'
               element={<HandleParentsUnsub />}
            />
            <Route path='/faq/*' element={<FAQRoutes />} />
            <Route path='/*' element={<NotFound />} />
         </Route>
      </Routes>
   );
}

export default App;
