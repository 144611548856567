import { useMemo, useState } from 'react';
import FilterDropdown from '../../UI/FilterDropdown';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DocumentDownloadIcon } from '@heroicons/react/outline';
import ClassStudentsTable from './StudentsTable/ClassStudentsTable';
import WithParentsTable from './StudentsTable/WithParentsTable';
import useURLState from '../../../hooks/useURLState';
import { TSchoolClassResponse } from '../../../types/ReligiousEducation/SchoolClass';
import { useTeacherContext } from '../../../context/ReligiousEducation/TeacherContext';
import { TSchoolYearResponse } from '../../../types/ReligiousEducation/SchoolYear';

type TProps = {
   schoolClass: TSchoolClassResponse;
};

export default function ClassDetailsComp({ schoolClass }: TProps) {
   const navigate = useNavigate();

   const {
      getCurrentSchoolYear,
      getSchoolYears,
      downloadClassRosterXLSX,
      getCurrentRegistrationsByClass,
   } = useTeacherContext();

   const { data: currentSchoolYear } = useQuery({
      queryFn: getCurrentSchoolYear,
      queryKey: ['currentYear'],
   });

   const { data: schoolYears, isLoading: isYearsLoading } = useQuery({
      queryFn: getSchoolYears,
      queryKey: ['schoolYears'],
   });

   const { data: registrations } = useQuery({
      queryKey: ['registrations', schoolClass._id],
      queryFn: () => getCurrentRegistrationsByClass(schoolClass._id),
   });

   // const [sortBy, setSortBy] = useState('firstname');

   const [tableFilter, setTableFilter] = useURLState('table', 'Student Roster');

   const [yearFilter, setYearFilter] = useState<{
      name?: string;
      value?: TSchoolYearResponse;
   }>({});
   const [schoolExlclusionsFilter, setSchoolExclusionsFilter] = useState({
      name: 'All',
      value: '',
   });

   // const currentRegistrations = registrations.filter(
   //    (registration) => registration?.schoolYear === currentSchoolYear?._id
   // );
   const classRosterMutation = useMutation({
      mutationFn: () =>
         downloadClassRosterXLSX({
            classId: schoolClass?._id,
            className: schoolClass?.className,
            yearId: yearFilter?.value?._id ?? '',
            yearName: yearFilter?.value?.yearName,
         }),
   });

   const currentStudentRegistrations = useMemo(
      () =>
         Array.isArray(registrations)
            ? registrations.filter(
                 registration =>
                    typeof registration?.student === 'object' &&
                    registration?.student?.status === 'Current'
              )
            : [],
      [registrations]
   );

   const yearFilteredRegistrations = useMemo(
      () =>
         !yearFilter?.value
            ? currentStudentRegistrations
            : currentStudentRegistrations
                 .filter(registration => {
                    return registration?.schoolYear === yearFilter?.value?._id;
                 })
                 .sort((a, b) =>
                    typeof a?.student === 'object' &&
                    typeof b?.student === 'object'
                       ? a?.student?.firstname > b?.student?.firstname
                          ? 1
                          : b?.student?.firstname > a?.student?.firstname
                          ? -1
                          : 0
                       : 0
                 ),
      [currentStudentRegistrations, yearFilter?.value]
   );

   const schoolExclusionsFilteredRegistrations = useMemo(
      () =>
         !schoolExlclusionsFilter?.value
            ? yearFilteredRegistrations
            : yearFilteredRegistrations.filter(
                 registration =>
                    typeof registration?.student === 'object' &&
                    registration?.student?.school_excluded_from_sacraments ===
                       schoolExlclusionsFilter?.value
              ),
      [schoolExlclusionsFilter, yearFilteredRegistrations]
   );

   const tableDropdownItems = useMemo(
      () => ['Student Roster', 'Parent Contact Info'],
      []
   );

   const yearDropdownItems = useMemo(
      () =>
         Array.isArray(schoolYears)
            ? schoolYears.map(year => ({
                 name: year?.yearName,
                 value: year,
              }))
            : [],
      [schoolYears]
   );

   const schoolExclusionsDropdownItems = useMemo(
      () => [
         { name: 'All', value: '' },
         { name: 'Sacramental Program', value: 'Sacramental Program' },
         {
            name: 'Excluded from Sacraments',
            value: 'Excluded from Sacraments',
         },
      ],
      []
   );

   return (
      <main className='flex-1'>
         {/* <!-- BREADCRUMBS --> */}
         <nav
            className='bg-white border-b border-gray-200 flex'
            aria-label='Breadcrumb'
         >
            <ol className='max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8'>
               <li className='flex'>
                  <div className='flex items-center'>
                     <a href='#k' className='text-gray-400 hover:text-gray-500'>
                        {/* <!-- Heroicon name: mini/home --> */}
                        <svg
                           className='flex-shrink-0 h-5 w-5'
                           xmlns='http://www.w3.org/2000/svg'
                           viewBox='0 0 20 20'
                           fill='currentColor'
                           aria-hidden='true'
                        >
                           <path
                              fillRule='evenodd'
                              d='M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z'
                              clipRule='evenodd'
                           />
                        </svg>
                        <span className='sr-only'>Home</span>
                     </a>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <a
                        href='#k'
                        className='ml-4 text-sm font-light text-gray-500 hover:text-gray-700'
                     >
                        Religious Education Dashboard
                     </a>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <a
                        href='#k'
                        className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                     >
                        Students
                     </a>
                  </div>
               </li>
            </ol>
            <div className='mr-10 mt-3'>
               {' '}
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
                  />
               </svg>
            </div>
         </nav>

         {/* <!--table with names--> */}
         <div className='px-4 sm:px-6 lg:px-8 mt-10 mb-40'>
            {/* <!--new record header --> */}

            <div className='overflow-hidden rounded-lg bg-white shadow border mb-10'>
               <h2 className='sr-only' id='profile-overview-title'>
                  Profile Overview
               </h2>
               <div className='bg-white p-6'>
                  <div className='sm:flex sm:items-center sm:justify-between'>
                     <div className='sm:flex sm:space-x-5'>
                        <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
                           <p className='text-sm font-medium text-gray-600'>
                              {yearFilter?.name}
                           </p>
                           <p className='text-xl font-bold text-gray-900 sm:text-2xl'>
                              {schoolClass?.className || ''}
                           </p>
                           <p
                              className='text-sm font-medium text-gray-600 cursor-pointer'
                              onClick={() =>
                                 navigate(
                                    `/religious-education/teachers/${schoolClass?.teacher?._id}/edit`,
                                    {
                                       state: schoolClass?.teacher,
                                    }
                                 )
                              }
                           >
                              {schoolClass?.teacher?.firstname || ''}{' '}
                              {schoolClass?.teacher?.lastname || ''}
                           </p>
                        </div>
                     </div>
                     {/* <div className='mt-5 flex justify-center sm:mt-0'>
                        <Link
                           to='edit'
                           state={{ schoolClass }}
                           className='flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
                        >
                           Edit
                        </Link>
                     </div> */}
                  </div>
               </div>
            </div>
            {/* <!--end--> */}
            {/* <!--students in class table--> */}
            <div className='sm:flex sm:items-center'>
               <div className='sm:flex-auto'>
                  <h2 className='text-lg font-semibold text-gray-900'>
                     Students
                  </h2>
               </div>
               <div>
                  <FilterDropdown
                     items={tableDropdownItems}
                     selectedState={[tableFilter, setTableFilter]}
                  />
               </div>
               <div>
                  <FilterDropdown
                     items={schoolExclusionsDropdownItems}
                     selectedState={[
                        schoolExlclusionsFilter,
                        setSchoolExclusionsFilter,
                     ]}
                     defaultValue='School Exclusions'
                  />
               </div>
               <div>
                  {!isYearsLoading && (
                     <FilterDropdown
                        items={yearDropdownItems}
                        selectedState={[yearFilter, setYearFilter]}
                        defaultValue='School Year'
                        selectedValue={
                           currentSchoolYear ||
                           (Array.isArray(schoolYears) && schoolYears[0])
                        }
                     />
                  )}
               </div>
               <div className='sm:ml-3 mt-4 sm:mt-0'>
                  <button
                     type='button'
                     onClick={() => classRosterMutation.mutate()}
                     className='flex items-center justify-center'
                  >
                     <DocumentDownloadIcon className='h-8 w-8' />
                  </button>
               </div>
               {/* <!-- Dropdown end --> */}

               {/* <!--filter for dates--> */}
            </div>
            <div className='mt-10 flex flex-col'>
               <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                  <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                     {tableFilter === 'Student Roster' ? (
                        <ClassStudentsTable
                           registrations={schoolExclusionsFilteredRegistrations}
                        />
                     ) : (
                        <WithParentsTable
                           registrations={schoolExclusionsFilteredRegistrations}
                        />
                     )}
                  </div>
               </div>
            </div>
            {/* <!--students in class table end--> */}
         </div>
         {/* <!--end talbe with names--> */}
      </main>
   );
}
