import { TRegistrationResponse } from '../../../../types/ReligiousEducation/Registration';

type Props = { registrations: TRegistrationResponse[] };

export default function WithParentsTable({ registrations }: Props) {
   return (
      <table className='min-w-full divide-y divide-gray-300'>
         <thead>
            <tr>
               <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
               >
                  Name
               </th>
               <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
               >
                  Mother
               </th>
               <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
               >
                  Father
               </th>
            </tr>
         </thead>
         <tbody className='divide-y divide-gray-200 bg-white'>
            {(registrations ?? []).map(registration => (
               <tr key={registration._id}>
                  <td className='whitespace-nowrap py-5 pr-3 text-sm sm:pl-0'>
                     <div className='flex items-center'>
                        <div className=''>
                           <div className='font-medium text-gray-900'>
                              {`${registration?.student?.firstname || ''}${
                                 registration?.student?.middlename
                                    ? ' ' + registration?.student.middlename
                                    : ''
                              } ${registration?.student?.lastname || ''}`}
                           </div>
                           <div className='mt-1 text-gray-500'>
                              {typeof registration.schoolClass === 'object'
                                 ? registration?.schoolClass?.className
                                 : ''}
                           </div>
                        </div>
                     </div>
                  </td>
                  <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                     {typeof registration.student.mother === 'object' ? (
                        <>
                           <div className='text-gray-900'>
                              {`${
                                 registration.student.mother?.firstname ?? ''
                              }${
                                 registration.student.mother?.middlename
                                    ? ' ' +
                                      registration.student.mother.middlename
                                    : ''
                              } ${registration.student.mother?.lastname ?? ''}`}
                           </div>
                           <div className='mt-1 text-gray-500 text-sm'>
                              {registration.student.mother?.cellPhone ?? ''}
                           </div>
                           <div className='mt-1 text-gray-500 text-sm'>
                              {registration.student.mother?.email ?? ''}
                           </div>
                        </>
                     ) : null}
                  </td>
                  <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                     {typeof registration.student.father === 'object' ? (
                        <>
                           <div className='text-gray-900'>
                              {`${
                                 registration.student.father?.firstname ?? ''
                              }${
                                 registration.student.father?.middlename
                                    ? ' ' +
                                      registration.student.father.middlename
                                    : ''
                              } ${registration.student.father?.lastname ?? ''}`}
                           </div>
                           <div className='mt-1 text-gray-500 text-sm'>
                              {registration.student.father?.cellPhone}
                           </div>
                           <div className='mt-1 text-gray-500 text-sm'>
                              {registration.student.father?.email}
                           </div>
                        </>
                     ) : null}
                  </td>
               </tr>
            ))}
         </tbody>
      </table>
   );
}
