import { useState, useMemo } from 'react';
import AddAttendanceStep1 from '../../../components/ReligiousEducation/Forms/Attendance/AddAttendanceStep1';
import AddAttendanceStep2 from '../../../components/ReligiousEducation/Forms/Attendance/AddAttendanceStep2';
import AddAttendanceStep3 from '../../../components/ReligiousEducation/Forms/Attendance/AddAttendanceStep3';
import AddAttendanceStep4 from '../../../components/ReligiousEducation/Forms/Attendance/AddAttendanceStep4';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTeacherContext } from '../../../context/ReligiousEducation/TeacherContext';
import PageSpinner from '../../../components/UI/PageSpinner';
import { dateObjToInputVal } from '../../../utils/dateFunctions';
import useInput from '../../../hooks/useInput';

const AddAttendance = () => {
   const {
      getClasses,
      getCurrentSchoolYear,
      getCurrentRegistrationsByClass,
      addAttendance,
   } = useTeacherContext();
   const queryClient = useQueryClient();

   const [step, setStep] = useState(1);

   const [selectedClass, classInputAttr] = useInput('');
   const [selectedDate, dateInputAttr] = useInput(
      dateObjToInputVal(new Date(), true)
   );
   const [selectedStudents, setSelectedStudents] = useState([]);

   const { isLoading: isClassLoading, data: schoolClasses } = useQuery({
      queryKey: ['classes'],
      queryFn: getClasses,
   });

   const { data: currentSchoolYear } = useQuery({
      queryFn: getCurrentSchoolYear,
      queryKey: ['currentYear'],
   });

   const { data: registrations } = useQuery({
      queryKey: ['registrations', selectedClass],
      queryFn: () => getCurrentRegistrationsByClass(selectedClass),
      enabled: selectedClass != null && selectedClass !== '',
   });

   const addAttendanceMutation = useMutation({
      mutationFn: () => addAttendance(selectedDate, selectedStudents),
      onSuccess: () => {
         queryClient.invalidateQueries(['registrations', selectedClass]);
         queryClient.invalidateQueries(['registrations', 'all']);
         setStep(4);
      },
   });

   const yearFilteredRegistrations = useMemo(
      () =>
         !currentSchoolYear?._id
            ? []
            : registrations?.filter(registration => {
                 return typeof registration?.schoolYear === 'string'
                    ? registration?.schoolYear === currentSchoolYear?._id
                    : registration?.schoolYear?._id === currentSchoolYear?._id;
              }),
      [registrations, currentSchoolYear?._id]
   );

   if (isClassLoading) return <PageSpinner />;

   return step === 1 ? (
      <AddAttendanceStep1
         setStep={setStep}
         schoolClasses={schoolClasses}
         selectedClass={selectedClass}
         selectedDate={selectedDate}
         classInputAttr={classInputAttr}
         dateInputAttr={dateInputAttr}
      />
   ) : step === 2 ? (
      <AddAttendanceStep2
         setStep={setStep}
         registrations={yearFilteredRegistrations}
         selectedStudents={selectedStudents}
         setSelectedStudents={setSelectedStudents}
      />
   ) : step === 3 ? (
      <AddAttendanceStep3
         setStep={setStep}
         selectedStudents={selectedStudents}
         selectedDate={selectedDate}
         addAttendanceFn={addAttendanceMutation.mutate}
         allRegistrations={registrations}
      />
   ) : step === 4 ? (
      <AddAttendanceStep4
         selectedStudents={selectedStudents}
         selectedDate={selectedDate}
         allRegistrations={registrations}
      />
   ) : null;
};

export default AddAttendance;
