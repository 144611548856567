import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTeacherContext } from '../../../context/ReligiousEducation/TeacherContext';
import PageSpinner from '../../../components/UI/PageSpinner';
import NotFound from '../../../components/NotFound';
import stringToUrlString from '../../../utils/stringToUrlString';
import ClassDetailsComp from '../../../components/Teacher/Classes/ClassDetailsComp';

const ClassDetails = () => {
   const { className } = useParams();

   const { getClasses } = useTeacherContext();

   const classesQuery = useQuery({
      queryKey: ['classes'],
      queryFn: getClasses,
   });

   if (classesQuery.isLoading) return <PageSpinner />;

   if (classesQuery?.data == null) return <NotFound />;

   const schoolClassFromContext = classesQuery.data.filter(
      eachClass => stringToUrlString(eachClass.className) === className
   )[0];

   return <ClassDetailsComp schoolClass={schoolClassFromContext} />;
};

export default ClassDetails;
