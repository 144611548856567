import { toast } from 'react-toastify';
import useAxiosPrivate from './useAxiosPrivate';
import { saveAs } from 'file-saver';
import axios from 'axios';

type SaveFileOptions = {
   name?: string;
   filenameToSave?: string;
   secure?: boolean;
};

export default function useDownload() {
   const axiosPrivate = useAxiosPrivate();

   const saveFile = async (
      href: string,
      { name, filenameToSave, secure = false }: SaveFileOptions = {}
   ) => {
      try {
         let data;

         if (secure) {
            const response = await axiosPrivate.get(href, {
               responseType: 'blob',
            });
            data = response.data;
         } else {
            const response = await axios.get(href, {
               responseType: 'blob',
            });
            data = response.data;
         }

         if (filenameToSave || name) {
            saveAs(data, filenameToSave || name);
         } else {
            saveAs(data);
         }
      } catch (error) {
         toast(`Failed to download${name ? ' ' + name : ''}!`);
      }
   };

   return saveFile;
}
