import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BaptismForm from '../../components/Baptisms/Forms/BaptismForm';
import useBaptismContext from '../../context/Baptisms/BaptismContext';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageSpinner from '../../components/UI/PageSpinner';
import { IBaptism } from '../../types/Baptisms/Baptisms';

export default function EditBaptism() {
   const { baptismId } = useParams();
   const { getBaptism, updateBaptism } = useBaptismContext();
   const queryClient = useQueryClient();
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();

   const from = searchParams.get('from');

   const { data: baptismDoc, isLoading } = useQuery({
      queryFn: () => getBaptism(baptismId!),
      queryKey: ['baptisms', baptismId],
   });

   const baptismMutation = useMutation({
      mutationFn: updateBaptism,
      onSuccess: () => {
         queryClient.invalidateQueries(['baptisms']);
         queryClient.invalidateQueries(['baptisms', baptismId]);
         queryClient.invalidateQueries(['upcomingDatesWithBaptisms']);
         queryClient.invalidateQueries(['infinitePastBaptisms']);
         navigate(from ?? '/baptisms', { replace: true });
         toast.success('Baptism updated successfully.');
      },
   });

   if (isLoading) return <PageSpinner />;

   return (
      <BaptismForm
         submitFn={baptismMutation.mutate}
         baptismDoc={baptismDoc as IBaptism}
         isLoading={baptismMutation.isLoading}
      />
   );
}
