import { useEffect } from 'react';
import { TStudentResponse } from '../../../types/ReligiousEducation/Student';
import { getFormattedDate } from '../../../utils/dateFunctions';
import { TSchoolYearResponse } from '../../../types/ReligiousEducation/SchoolYear';
import { TSchoolClassResponse } from '../../../types/ReligiousEducation/SchoolClass';
import StudentDetailsHeader from './StudentDetailsHeader';
import ParentsTable from './ParentsTable';
import RegistrationsTable from './RegistrationsTable';
import SacramentalTable from './SacramentalTable';
import BirthCertTable from './BirthCertTable';

type Props = {
   student: TStudentResponse;
   currentYear: TSchoolYearResponse;
   schoolClasses: TSchoolClassResponse[];
};

export default function StudentDetailsComp({
   student,
   currentYear,
   schoolClasses,
}: Props) {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <main className='flex-1 pb-40'>
         {/* <!-- Page title & actions --> */}
         {/* <!-- BREADCRUMB --> */}
         <nav
            className='bg-white border-b border-gray-200 flex'
            aria-label='Breadcrumb'
         >
            <ol className='max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8'>
               <li className='flex'>
                  <div className='flex items-center'>
                     <a href='#k' className='text-gray-400 hover:text-gray-500'>
                        {/* <!-- Heroicon name: mini/home --> */}
                        <svg
                           className='flex-shrink-0 h-5 w-5'
                           xmlns='http://www.w3.org/2000/svg'
                           viewBox='0 0 20 20'
                           fill='currentColor'
                           aria-hidden='true'
                        >
                           <path
                              fillRule='evenodd'
                              d='M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z'
                              clipRule='evenodd'
                           />
                        </svg>
                        <span className='sr-only'>Home</span>
                     </a>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <a
                        href='#k'
                        className='ml-4 text-sm font-light text-gray-500 hover:text-gray-700'
                     >
                        Students
                     </a>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <a
                        href='#k'
                        className='ml-4 text-sm font-light text-gray-500 hover:text-gray-700'
                        aria-current='page'
                     >
                        Student Record
                     </a>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <span
                        className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                        aria-current='page'
                     >
                        {`${student?.firstname || ''}${
                           student?.middlename ? ' ' + student.middlename : ''
                        } ${student?.lastname || ''}`}
                     </span>
                  </div>
               </li>
            </ol>
            <div className='mr-10 mt-3'>
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
                  />
               </svg>
            </div>
         </nav>

         {/* <!-- Pinned projects --> */}
         <div className='px-4 sm:px-6 lg:px-8 mt-10'>
            {student?.baptized === 'Baptized Protestant' ? (
               student?.professionOfFaith === true ? (
                  <div className='border-l-4 border-green-400 bg-green-50 p-4 mb-10'>
                     <div className='flex'>
                        <div className='flex-shrink-0'>
                           {/* <!-- Heroicon name: mini/exclamation-triangle --> */}
                           <svg
                              className='h-5 w-5 text-green-400'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 20 20'
                              fill='currentColor'
                              aria-hidden='true'
                           >
                              <path
                                 fillRule='evenodd'
                                 d='M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z'
                                 clipRule='evenodd'
                              />
                           </svg>
                        </div>
                        <div className='ml-3'>
                           <p className='text-sm text-green-700'>
                              Baptized Protestant. {/* Link to span */}
                              <span className='font-medium text-green-700 underline hover:text-yellow-600'>
                                 A Profession of Faith is Required was completed
                                 {student?.professionOfFaithDate
                                    ? ' on ' +
                                      getFormattedDate(
                                         student.professionOfFaithDate,
                                         'mm/dd/yyyy'
                                      )
                                    : ''}
                                 .
                              </span>
                           </p>
                        </div>
                     </div>
                  </div>
               ) : (
                  <div className='border-l-4 border-yellow-400 bg-yellow-50 p-4 mb-10'>
                     <div className='flex'>
                        <div className='flex-shrink-0'>
                           {/* <!-- Heroicon name: mini/exclamation-triangle --> */}
                           <svg
                              className='h-5 w-5 text-yellow-400'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 20 20'
                              fill='currentColor'
                              aria-hidden='true'
                           >
                              <path
                                 fillRule='evenodd'
                                 d='M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z'
                                 clipRule='evenodd'
                              />
                           </svg>
                        </div>
                        <div className='ml-3'>
                           <p className='text-sm text-yellow-700'>
                              Baptized Protestant. {/* Link to span */}
                              <span className='font-medium text-yellow-700 underline hover:text-yellow-600'>
                                 A Profession of Faith is Required.
                              </span>
                           </p>
                        </div>
                     </div>
                  </div>
               )
            ) : null}
            <StudentDetailsHeader
               student={student}
               schoolClasses={schoolClasses}
               currentYear={currentYear}
            />
         </div>

         {/* <!--parents--> */}
         <ParentsTable student={student} />

         {/* <!--registraions--> */}
         <RegistrationsTable student={student} />

         {/* <!-- podcasts table --> */}
         <SacramentalTable student={student} />

         <BirthCertTable student={student} />

         {student?.notes && (
            <div className='px-4 sm:px-6 mt-10 lg:px-8'>
               <div className='mt-8 flex flex-col'>
                  <div className='sm:flex-auto mb-5'>
                     <h2 className='text-lg font-semibold text-gray-900'>
                        Notes
                     </h2>
                  </div>
                  <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                     <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                           <table className='table-auto min-w-full divide-y divide-gray-300'>
                              <thead className='bg-gray-50'>
                                 <tr>
                                    <th
                                       scope='col'
                                       className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                                    >
                                       Notes
                                    </th>
                                 </tr>
                              </thead>
                              <tbody className='divide-y divide-gray-200 bg-white'>
                                 <tr>
                                    <td className='px-6 py-3 max-w-0 w-full text-sm font-medium text-gray-900 whitespace-normal'>
                                       {student.notes}
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </main>
   );
}
