import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import App from './App';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
import { Bounce, ToastContainer, toast } from 'react-toastify';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import PageSpinner from './components/UI/PageSpinner';

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         staleTime: 2 * 60 * 1000,
         onError: (error: any) => {
            if (error?.response?.data?.message) {
               toast.error(error?.response?.data?.message);
            } else {
               toast.error(error.message);
            }
         },
      },
      mutations: {
         onError: (error: any) => {
            if (error?.response?.data?.message) {
               toast(error?.response?.data?.message);
            } else {
               toast(error.message);
            }
         },
      },
   },
});

const root = ReactDOM.createRoot(
   document.getElementById('root') as HTMLElement
);
root.render(
   <React.StrictMode>
      <BrowserRouter>
         <QueryClientProvider client={queryClient}>
            <AuthProvider>
               <Suspense fallback={<PageSpinner />}>
                  <Routes>
                     <Route path='/*' element={<App />} />
                  </Routes>
               </Suspense>
               <ToastContainer
                  position='top-right'
                  autoClose={10000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick={false}
                  rtl={false}
                  transition={Bounce}
                  limit={5}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
               />
            </AuthProvider>
         </QueryClientProvider>
      </BrowserRouter>
   </React.StrictMode>
);
