import { useMemo, useState } from 'react';
import FilterDropdown from '../../../UI/FilterDropdown';
import { TRegistrationResponse } from '../../../../types/ReligiousEducation/Registration';
import { TSchoolClassResponse } from '../../../../types/ReligiousEducation/SchoolClass';

type Props = {
   registrations: TRegistrationResponse[];
   schoolClasses: TSchoolClassResponse[];
};

const NotesReportComp = ({ registrations, schoolClasses }: Props) => {
   const [classFilter, setClassFilter] = useState<{
      name?: string;
      value?: any;
   }>({});

   const classFilteredRegistrations = useMemo(
      () =>
         !classFilter?.value
            ? registrations
            : registrations?.filter(registration => {
                 return typeof registration.schoolClass === 'string'
                    ? registration.schoolClass === classFilter?.value?._id
                    : registration?.schoolClass?._id ===
                         classFilter?.value?._id;
              }),
      [classFilter?.value, registrations]
   );

   const classDropdownItems = useMemo(
      () => [
         { name: 'All', value: '' },
         ...schoolClasses
            ?.sort((a, b) =>
               a?.className < b?.className
                  ? -1
                  : b?.className < a?.className
                  ? 1
                  : 0
            )
            ?.map(eachClass => ({
               name: eachClass?.className,
               value: eachClass,
            }))!,
      ],
      [schoolClasses]
   );

   return (
      <main className='flex-1'>
         <div className='max-w-4xl sm:px-6 lg:pr-8 mb-40'>
            <div className='flex items-center justify-between flex-wrap'>
               <h1 className='text-4xl font-bold mt-20 ml-5 mb-5 sm:mb-10'>
                  Religious Education Notes Report
               </h1>
               <div className='sm:mt-20 mt-0 mb-10 sm:ml-0 ml-5'>
                  {schoolClasses.length > 1 && (
                     <FilterDropdown
                        items={classDropdownItems}
                        selectedState={[classFilter, setClassFilter]}
                        defaultValue='Class'
                        className='relative inline-block text-left lg:ml-5 mt-4 lg:mt-0'
                     />
                  )}
               </div>
            </div>
            {classFilteredRegistrations?.map(registration =>
               registration?.notes || registration.student?.notes ? (
                  <div
                     className='bg-gray-100 border-dashed'
                     key={registration._id}
                  >
                     <div className='mx-auto max-w-lg overflow-hidden rounded-lg lg:flex lg:max-w-none'>
                        <div className='flex-1 bg-white px-6 lg:p-6'>
                           <div className=''>
                              <div className='flex items-center'>
                                 <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                                    {registration?.student
                                       ? `${
                                            registration.student?.firstname ||
                                            ''
                                         }${
                                            registration.student?.middlename
                                               ? ' ' +
                                                 registration.student
                                                    ?.middlename
                                               : ''
                                         } ${
                                            registration.student?.lastname || ''
                                         }`
                                       : ''}
                                 </h4>
                                 <div className='flex-1 border-t-2 border-gray-200'></div>
                              </div>
                           </div>

                           {registration.student?.notes ? (
                              <>
                                 <p className='text-base font-bold text-gray-900 sm:tracking-tight mt-5 ml-5'>
                                    Student Notes
                                 </p>
                                 <p className='mt-6 text-base text-gray-500 ml-5'>
                                    {registration.student.notes}
                                 </p>
                              </>
                           ) : null}

                           {registration?.notes ? (
                              <>
                                 <p className='text-base font-bold text-gray-900 sm:tracking-tight mt-5 ml-5'>
                                    Registration Notes
                                 </p>
                                 <p className='mt-6 text-base text-gray-500 ml-5'>
                                    {registration.notes}
                                 </p>
                              </>
                           ) : null}
                        </div>
                     </div>
                  </div>
               ) : null
            )}
         </div>
      </main>
   );
};

export default NotesReportComp;
