type Props = {
   height?: string;
};

const PageSpinner = ({ height }: Props) => {
   return (
      <div
         className='flex items-center justify-center w-full'
         style={{ height: height ?? '80vh' }}
      >
         <div className='w-24 h-24 border-l-2 border-t-2 border-gray-900 rounded-full animate-spin'></div>
      </div>
   );
};

export default PageSpinner;
