import { useMemo } from 'react';
import { TStudentResponse } from '../../../types/ReligiousEducation/Student';
import { TParentsResponse } from '../../../types/ReligiousEducation/Parents';

type Props = { student: TStudentResponse };

export default function ParentsTable({ student }: Props) {
   // const navigate = useNavigate();
   // const { parents } = useEducationContext();

   // const studentParentsId = [
   //    student?.father,
   //    student?.mother,
   //    student?.legalGuardian,
   // ].filter(Boolean);

   const studentParents = useMemo(
      () =>
         [
            typeof student?.father === 'object' && student?.father,
            typeof student?.mother === 'object' && student?.mother,
            typeof student?.legalGuardian === 'object' &&
               student?.legalGuardian,
         ].filter(Boolean) as TParentsResponse[],
      [student]
   );

   // const studentParents = useMemo(
   //    () =>
   //       parents.filter(eachParent =>
   //          studentParentsId.includes(eachParent._id)
   //       ),
   //    [parents, studentParentsId]
   // );

   return (
      <div className='px-4 sm:px-6 lg:px-8 mt-10'>
         <div className='sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
               <h2 className='text-lg font-semibold text-gray-900'>Parents</h2>
            </div>

            {/* <div className='sm:mt-0 sm:ml-16 sm:flex-none'>
               <Link to='/religious-education/add-parents'>
                  <button
                     type='button'
                     className='inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                     <PlusSmIcon className='h-5 w-5' />
                  </button>
               </Link>
            </div> */}
         </div>

         <div className='mt-5 flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
               <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                     <table className='min-w-full divide-y divide-gray-300'>
                        <thead className='bg-gray-50'>
                           <tr>
                              <th
                                 scope='col'
                                 className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                              >
                                 Name
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Parent Role
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Email
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Mobile Phone
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Primary Contact
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              ></th>
                           </tr>
                        </thead>

                        <tbody className='divide-y divide-gray-200 bg-white'>
                           {studentParents.map(parent => (
                              <tr
                                 className='hover:bg-gray-100'
                                 key={parent._id}
                              >
                                 <td
                                    className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 cursor-pointer'
                                    // onClick={() =>
                                    //    navigate(
                                    //       `/religious-education/parents/${parent?._id}`
                                    //    )
                                    // }
                                 >
                                    {`${parent.firstname}${
                                       parent?.middlename
                                          ? ' ' + parent.middlename
                                          : ''
                                    } ${parent.lastname}`}
                                 </td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {parent?.role || ''}
                                 </td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {parent?.email || ''}
                                 </td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {parent?.cellPhone || ''}
                                 </td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'></td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {/* <Link
                                       state={parent}
                                       to={`/religious-education/parents/${parent._id}/edit`}
                                       className='text-indigo-600 hover:text-indigo-900'
                                    >
                                       Edit
                                    </Link> */}
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
