import { useContext } from 'react';
import WeddingContext from '../context/WeddingContext';

const useWeddingContext = () => {
   const context = useContext(WeddingContext);
   if (!context)
      throw new Error(
         'useWeddingContext must be used within a WeddingProvider'
      );
   return context;
};

export default useWeddingContext;
