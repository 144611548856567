import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useBaptismContext from '../../../context/Baptisms/BaptismContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PageSpinner from '../../../components/UI/PageSpinner';
import { toast } from 'react-toastify';
import BaptismDateForm from '../../../components/Baptisms/Forms/BaptismDateForm';
import { TBaptismDate } from '../../../types/Baptisms/BaptismDates';

export default function EditBaptismDate() {
   const { dateId } = useParams();
   const { getBaptismDate, updateBaptismDate } = useBaptismContext();
   const navigate = useNavigate();
   const queryClient = useQueryClient();
   const [searchParams] = useSearchParams();

   const from = searchParams.get('from');

   const { data: baptismDate, isLoading } = useQuery({
      queryFn: () => getBaptismDate(dateId!),
      queryKey: ['baptismDates', {}, dateId],
   });

   const baptismDateMutation = useMutation({
      mutationFn: updateBaptismDate,
      onSuccess: () => {
         queryClient.invalidateQueries(['baptismDates']);
         queryClient.invalidateQueries(['baptismDates', dateId]);
         toast.success('Baptism Date updated successfully.');
         navigate(from ?? `/baptisms/dates`, { replace: true });
      },
   });

   if (isLoading) return <PageSpinner />;

   return (
      <main className='flex-1'>
         <BaptismDateForm
            isLoading={baptismDateMutation.isLoading}
            submitFn={baptismDateMutation.mutate}
            baptismDate={baptismDate as TBaptismDate}
         />
      </main>
   );
}
