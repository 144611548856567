import { useState } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';

function classNames(...classes) {
   return classes.filter(Boolean).join(' ');
}

const ParentsCombobox = ({ items = [], selectedState, label }) => {
   const [query, setQuery] = useState('');
   const [selectedItem, setSelectedItem] = selectedState;

   const filteredItems =
      query === ''
         ? items
         : items.filter(item => {
              const fullName = `${item.firstname}${
                 item?.middlename ? ' ' + item.middlename : ''
              } ${item?.lastname ?? ''}`;

              return fullName
                 .toLowerCase()
                 .replace(/\s+/g, '')
                 .includes(query.toLowerCase().replace(/\s+/g, ''));
           });

   return (
      <Combobox
         as='div'
         className='mt-10'
         value={selectedItem}
         onChange={setSelectedItem}
      >
         {label && (
            <Combobox.Label className='block text-sm font-medium text-gray-700'>
               {label}
            </Combobox.Label>
         )}
         <div className='relative mt-1'>
            <Combobox.Input
               className='w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
               onChange={event => setQuery(event.target.value)}
               displayValue={
                  selectedItem
                     ? item =>
                          `${item.firstname}${
                             item?.middlename ? ` ${item.middlename}` : ''
                          } ${item?.lastname ?? ''}`
                     : ''
               }
            />
            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
               <SelectorIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
               />
            </Combobox.Button>

            <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
               {filteredItems.length === 0 && query !== '' ? (
                  <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                     Nothing found.
                  </div>
               ) : (
                  filteredItems.map(item => (
                     <Combobox.Option
                        key={item._id}
                        value={item}
                        className={({ active }) =>
                           classNames(
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                              active
                                 ? 'bg-indigo-600 text-white'
                                 : 'text-gray-900'
                           )
                        }
                     >
                        {({ active, selected }) => (
                           <>
                              <span
                                 className={classNames(
                                    'block truncate',
                                    selected && 'font-semibold'
                                 )}
                              >
                                 {`${item.firstname}${
                                    item?.middlename
                                       ? ` ${item.middlename}`
                                       : ''
                                 } ${item?.lastname ?? ''}`}
                              </span>

                              {selected && (
                                 <span
                                    className={classNames(
                                       'absolute inset-y-0 right-0 flex items-center pr-4',
                                       active ? 'text-white' : 'text-indigo-600'
                                    )}
                                 >
                                    <CheckIcon
                                       className='h-5 w-5'
                                       aria-hidden='true'
                                    />
                                 </span>
                              )}
                           </>
                        )}
                     </Combobox.Option>
                  ))
               )}
            </Combobox.Options>
         </div>
      </Combobox>
   );
};

export default ParentsCombobox;
