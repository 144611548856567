import { Link } from 'react-router-dom';

type Props = {
   eventsCount?: string | number;
   funeralsCount?: string | number;
   weddingsCount?: string | number;
   baptismsCount?: string | number;
};

export default function Stats({
   baptismsCount,
   eventsCount,
   funeralsCount,
   weddingsCount,
}: Props) {
   return (
      <div className='bg-gray-100 border-dashed'>
         <div className='mx-auto max-w-lg overflow-hidden rounded-lg lg:flex lg:max-w-none'>
            <div className='flex-1 bg-white px-6 lg:p-6'>
               <div className=''>
                  <div className='flex items-center'>
                     <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                        Sacramatic Dashboard
                     </h4>
                     <div className='flex-1 border-t-2 border-gray-200'></div>
                  </div>
               </div>

               <div className='mt-10 bg-white pb-12 sm:pb-16'>
                  <div className='relative'>
                     <div className='absolute inset-0 h-1/2 bg-gray-50'></div>
                     <div className='relative mx-auto max-w-7xl px-6 lg:px-8'>
                        <div className='mx-auto max-w-4xl'>
                           <dl className='rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4'>
                              <Link to='/baptisms'>
                                 <div className='flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r'>
                                    <dt className='order-2 mt-2 text-lg font-medium leading-6 text-gray-500'>
                                       Baptisms
                                    </dt>
                                    <dd className='order-1 text-5xl font-bold tracking-tight'>
                                       {baptismsCount
                                          ? baptismsCount.toString()
                                          : 0}
                                    </dd>
                                 </div>
                              </Link>
                              <Link to='/funerals'>
                                 <div className='flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r'>
                                    <dt className='order-2 mt-2 text-lg font-medium leading-6 text-gray-500'>
                                       Funerals
                                    </dt>
                                    <dd className='order-1 text-5xl font-bold tracking-tight'>
                                       {funeralsCount
                                          ? funeralsCount?.toString()
                                          : 0}
                                    </dd>
                                 </div>
                              </Link>
                              <Link to='/website'>
                                 <div className='flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l'>
                                    <dt className='order-2 mt-2 text-lg font-medium leading-6 text-gray-500'>
                                       Parish Events
                                    </dt>
                                    <dd className='order-1 text-5xl font-bold tracking-tight'>
                                       {eventsCount
                                          ? eventsCount?.toString()
                                          : 0}
                                    </dd>
                                 </div>
                              </Link>
                              <Link to='/weddings'>
                                 <div className='flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l'>
                                    <dt className='order-2 mt-2 text-lg font-medium leading-6 text-gray-500'>
                                       Weddings
                                    </dt>
                                    <dd className='order-1 text-5xl font-bold tracking-tight'>
                                       {weddingsCount
                                          ? weddingsCount?.toString()
                                          : 0}
                                    </dd>
                                 </div>
                              </Link>
                           </dl>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
