import { useMutation, useQueryClient } from '@tanstack/react-query';
import BaptismForm from '../../components/Baptisms/Forms/BaptismForm';
import BreadCrumb from '../../components/UI/BreadCrumb';
import useBaptismContext from '../../context/Baptisms/BaptismContext';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AddBaptism() {
   const { addBaptism } = useBaptismContext();
   const queryClient = useQueryClient();
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();

   const from = searchParams.get('from');

   const baptismMutation = useMutation({
      mutationFn: addBaptism,
      onSuccess: () => {
         queryClient.invalidateQueries(['baptisms']);
         queryClient.invalidateQueries(['upcomingDatesWithBaptisms']);
         queryClient.invalidateQueries(['infinitePastBaptisms']);
         navigate(from ?? '/baptisms', { replace: true });
         toast.success('Baptism added successfully.');
      },
   });

   return (
      <main className='flex-1'>
         {/* <!-- BREADCRUMBS --> */}
         <BreadCrumb
            homeLink='/dashboard'
            items={[
               { link: '/baptisms', title: 'Baptisms' },
               { title: 'Add Baptism', active: true },
            ]}
         />

         <BaptismForm
            submitFn={baptismMutation.mutate}
            isLoading={baptismMutation.isLoading}
         />
      </main>
   );
}
