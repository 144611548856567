import { useQuery } from '@tanstack/react-query';
import BreadCrumb from '../../../components/UI/BreadCrumb';
import { useAppContext } from '../../../context/AppContext';
import PageSpinner from '../../../components/UI/PageSpinner';

export default function GiftStoreDashboard() {
   const { fetchProducts } = useAppContext();

   const productsQuery = useQuery({
      queryFn: fetchProducts,
      queryKey: ['products'],
   });

   return (
      <main className='flex-1'>
         {/* <!-- BREADCRUMBS --> */}
         <BreadCrumb
            homeLink='/dashboard'
            items={[{ active: true, title: 'Gift Store' }]}
         />

         {/* <!--table with names--> */}
         <div>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-20'>
               <div className='sm:flex sm:items-center'>
                  <div className='sm:flex-auto'>
                     <h1 className='text-2xl font-semibold text-gray-900'>
                        Religious Gifts for Resale
                     </h1>
                     <p className='mt-2 text-sm text-gray-700'></p>
                  </div>
                  {/* <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
                     <button
                        type='button'
                        className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                     >
                     </button>
                  </div> */}
               </div>
            </div>
            <div className='mt-8 flow-root overflow-hidden'>
               <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                  {productsQuery.isLoading ? (
                     <PageSpinner height='25rem' />
                  ) : (
                     <table className='w-full text-left'>
                        <thead className='bg-white'>
                           <tr>
                              <th
                                 scope='col'
                                 className='relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'
                              >
                                 Product Name
                                 <div className='absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200'></div>
                                 <div className='absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200'></div>
                              </th>
                              <th
                                 scope='col'
                                 className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell'
                              >
                                 Stock Price
                              </th>
                              <th
                                 scope='col'
                                 className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell'
                              >
                                 Drop Ship Price
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Suggested Retail Price
                              </th>
                              {/* <th scope='col' className='relative py-3.5 pl-3'>
                              <span className='sr-only'>Edit</span>
                           </th> */}
                           </tr>
                        </thead>
                        <tbody>
                           {(productsQuery?.data ?? []).map(product => (
                              <tr>
                                 <td className='relative py-4 pr-3 text-sm font-medium text-gray-900'>
                                    {Array.isArray(product?.images) &&
                                    product.images.length > 0 ? (
                                       <>
                                          <img
                                             src={product.images[0].src}
                                             alt={`${product.productName} thumbnail`}
                                             className='h-24 w-24'
                                          />{' '}
                                       </>
                                    ) : null}
                                    {product.productName}
                                    <div className='absolute bottom-0 right-full h-px w-screen bg-gray-100'></div>
                                    <div className='absolute bottom-0 left-0 h-px w-screen bg-gray-100'></div>
                                 </td>
                                 <td className='hidden px-3 py-4 text-sm text-gray-500 sm:table-cell'>
                                    ${product.churchWholesalePrice}
                                 </td>
                                 <td className='hidden px-3 py-4 text-sm text-gray-500 md:table-cell'>
                                    $
                                    {Number.isInteger(
                                       product.churchWholesalePrice * 1.75
                                    )
                                       ? product.churchWholesalePrice * 1.75
                                       : (
                                            product.churchWholesalePrice * 1.75
                                         ).toFixed(2)}
                                 </td>
                                 <td className='px-3 py-4 text-sm text-gray-500'>
                                    ${product.suggestedRetail}
                                 </td>
                                 {/* <td className='relative py-4 pl-3 text-right text-sm font-medium'>
                                    <a
                                       href='#'
                                       className='text-indigo-600 hover:text-indigo-900'
                                    ></a>
                                 </td> */}
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  )}
               </div>
            </div>
         </div>
      </main>
   );
}
