import { useQuery } from '@tanstack/react-query';
import { useTeacherContext } from '../../../context/ReligiousEducation/TeacherContext';
import PageSpinner from '../../../components/UI/PageSpinner';
import NotFound from '../../../components/NotFound';
import { useMemo } from 'react';
import { TStudentResponse } from '../../../types/ReligiousEducation/Student';
import MissingDocsComp from '../../../components/ReligiousEducation/Reports/MissingDocs/MissingDocsComp';

export default function MissingDocs() {
   const { getClasses, getCurrentRegistrations, getCurrentSchoolYear } =
      useTeacherContext();

   const { data: currentSchoolYear, isLoading: isYearLoading } = useQuery({
      queryFn: getCurrentSchoolYear,
      queryKey: ['currentSchoolYear'],
   });

   const { isLoading: isClassLoading, data: schoolClasses } = useQuery({
      queryKey: ['classes'],
      queryFn: getClasses,
   });

   const { isLoading: isRegistrationsLoading, data: registrations } = useQuery({
      queryKey: ['registrations', { year: currentSchoolYear?._id }],
      queryFn: () => getCurrentRegistrations(currentSchoolYear?._id),
      enabled: currentSchoolYear?._id != null,
   });

   const students = useMemo(
      () =>
         registrations
            ? (registrations
                 .map(registration => {
                    const { student, ...regWithoutStudent } = registration;
                    return {
                       ...(student as TStudentResponse),
                       registrationId: [regWithoutStudent],
                    };
                 })
                 .filter(
                    (student, index, self) =>
                       index ===
                       self.findIndex(t =>
                          typeof t === 'object'
                             ? t._id === student._id
                             : t === student
                       )
                 ) as TStudentResponse[])
            : [],
      [registrations]
   );

   if (isClassLoading || isRegistrationsLoading || isYearLoading)
      return <PageSpinner />;

   if (!schoolClasses || !registrations || !currentSchoolYear)
      return <NotFound />;

   return <MissingDocsComp schoolClasses={schoolClasses} students={students} />;
}
