import { useNavigate } from 'react-router-dom';
import { TSchoolClassResponse } from '../../../types/ReligiousEducation/SchoolClass';
import stringToUrlString from '../../../utils/stringToUrlString';
import useAuth from '../../../hooks/useAuth';

type Props = {
   classes: TSchoolClassResponse[];
};

export default function ClassesTable({ classes }: Props) {
   const navigate = useNavigate();
   const { auth } = useAuth();

   return (
      <table className='min-w-full divide-y divide-gray-300'>
         <thead className='bg-gray-50'>
            <tr>
               <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
               >
                  Class Name
               </th>
               <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
               >
                  Teacher Name
               </th>
               <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
               >
                  Meets
               </th>
               <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
               >
                  Location
               </th>
               <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
               >
                  # of Students
               </th>
               <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
               >
                  Assistant
               </th>
               <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
               >
                  Volunteer
               </th>
               <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
               >
                  Tags
               </th>
            </tr>
         </thead>
         <tbody className='divide-y divide-gray-200 bg-white'>
            {/* <!-- Odd row --> */}
            {classes
               .sort((a, b) =>
                  a?.className < b?.className
                     ? -1
                     : b?.className < a?.className
                     ? 1
                     : 0
               )
               .map(schoolClass => (
                  <tr
                     className='hover:bg-gray-100 cursor-pointer'
                     key={schoolClass._id}
                     onClick={() =>
                        navigate(stringToUrlString(schoolClass.className))
                     }
                  >
                     <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                        {schoolClass?.className || ''}
                     </td>
                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {'firstname' in auth ? auth.firstname : ''}{' '}
                        {'lastname' in auth ? auth.lastname : ''}
                     </td>
                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <span className='sr-only'>Meet</span>
                     </td>
                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <span className='sr-only'>Location</span>
                     </td>
                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <span className='sr-only'>Number of students</span>
                     </td>

                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <span className='sr-only'>Assistant Name</span>
                     </td>

                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <span className='sr-only'>Volunteer Name</span>
                     </td>

                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'></td>
                  </tr>
               ))}
         </tbody>
      </table>
   );
}
