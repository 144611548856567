import axios from '../api/axios';
import { AUTH_API } from '../utils/constants';
import useAuth from './useAuth';

const useRefreshToken = () => {
   const { setAuth } = useAuth();

   const refresh = async () => {
      const response = await axios.get(`${AUTH_API}/refresh`, {
         withCredentials: true,
      });
      setAuth(prev => {
         return {
            ...prev,
            ...response.data,
         };
      });
      return response.data.accessToken;
   };
   return refresh;
};

export default useRefreshToken;
