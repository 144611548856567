import { useParams } from 'react-router-dom';
import { useTeacherContext } from '../../../context/ReligiousEducation/TeacherContext';
import { useQuery } from '@tanstack/react-query';
import NotFound from '../../../components/NotFound';
import StudentDetailsComp from '../../../components/Teacher/Student/StudentDetailsComp';
import PageSpinner from '../../../components/UI/PageSpinner';

export default function StudentRecord() {
   const { studentId } = useParams();
   const { getStudent, getCurrentSchoolYear, getClasses } = useTeacherContext();

   const { isLoading: isYearLoading, data: currentSchoolYear } = useQuery({
      queryFn: getCurrentSchoolYear,
      queryKey: ['currentYear'],
   });

   const { isLoading: isStudentLoading, data: student } = useQuery({
      queryKey: ['students', studentId],
      queryFn: () => getStudent(studentId),
   });

   const { isLoading: isClassesLoading, data: schoolClasses } = useQuery({
      queryFn: getClasses,
      queryKey: ['classes'],
   });

   if (isYearLoading || isStudentLoading || isClassesLoading)
      return <PageSpinner />;

   if (!student || !currentSchoolYear || !schoolClasses) return <NotFound />;

   return (
      <StudentDetailsComp
         student={student}
         currentYear={currentSchoolYear}
         schoolClasses={schoolClasses}
      />
   );
}
