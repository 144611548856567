import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import jwtDecode from 'jwt-decode';

const ProtectedRoute = ({ allowedRoles }) => {
   const { auth } = useAuth();
   const location = useLocation();

   const decoded = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

   const roles = decoded?.UserInfo?.roles || [];

   return <Outlet />; //This is temporary for allowing any role to access the site

   // return roles.find((role) => allowedRoles?.includes(role)) ? (
   //    <Outlet />
   // ) : auth?.accessToken ? (
   //    <Navigate to='/unauthorized' replace />
   // ) : (
   //    <Navigate to='/login' state={{ from: location }} replace />
   // );
};

export default ProtectedRoute;
