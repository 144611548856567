import { IResponseFuneral } from '../../types/FunenalTypes';
import { getFormattedDate } from '../../utils/dateFunctions';

type Props = {
   funerals: IResponseFuneral[];
};

export default function FuneralsTable({ funerals }: Props) {
   return (
      <div className='max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto mb-20'>
         <div className='mb-10'>
            <div className='flex items-center'>
               <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                  Upcoming Funerals
               </h4>

               <div className='flex-1 border-t-2 border-gray-200'></div>
            </div>
         </div>

         {/* <!-- Grid --> */}

         <div className='px-4 sm:px-6 lg:px-8'>
            <div className='mt-8 flow-root'>
               <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                  <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                     <table className='min-w-full divide-y divide-gray-300'>
                        <thead>
                           <tr>
                              <th
                                 scope='col'
                                 className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                              >
                                 Date
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Deceased
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Type
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Time
                              </th>

                              <th
                                 scope='col'
                                 className='relative py-3.5 pl-3 pr-4 sm:pr-3'
                              >
                                 <span className='sr-only'></span>
                              </th>
                           </tr>
                        </thead>

                        <tbody className='bg-white'>
                           {funerals.map(funeral => (
                              <tr className='even:bg-gray-50'>
                                 <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                                    {getFormattedDate(funeral?.dateOfFuneral)}
                                 </td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {`${funeral?.firstname || ''}${
                                       funeral?.middlename
                                          ? ' ' + funeral.middlename
                                          : ''
                                    } ${funeral?.lastname || ''}`}
                                 </td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    Funeral {funeral?.serviceType} /{' '}
                                    {funeral?.remains}
                                 </td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {funeral?.funeralTime ?? ''}
                                    {funeral?.funeralTime != null &&
                                       (parseInt(
                                          funeral.funeralTime.split(':')[0]
                                       ) > 11
                                          ? 'pm'
                                          : 'am')}
                                 </td>

                                 <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3'>
                                    <a
                                       href='#'
                                       className='text-indigo-600 hover:text-indigo-900'
                                    >
                                       View
                                    </a>
                                 </td>
                              </tr>
                           ))}

                           {/* <!-- More people... --> */}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>

         {/* <!-- End Grid --> */}
      </div>
   );
}
