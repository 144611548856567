import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { TSchoolYearResponse } from '../../../types/ReligiousEducation/SchoolYear';
import { TStudentResponse } from '../../../types/ReligiousEducation/Student';
import { calcAge } from '../../../utils/dateFunctions';
import { TSchoolClassResponse } from '../../../types/ReligiousEducation/SchoolClass';
import { Link, useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react';

type Props = {
   student: TStudentResponse;
   currentYear: TSchoolYearResponse;
   schoolClasses: TSchoolClassResponse[];
};

export default function StudentDetailsHeader({
   student,
   currentYear,
   schoolClasses,
}: Props) {
   const { pathname } = useLocation();

   const editButtonRef = useRef<HTMLButtonElement | null>(null);
   const [editDropdownOpen, setEditDropdownOpen] = useState(false);

   const currentYearRegistration = useMemo(
      () =>
         student?.registrationId?.find(
            registration =>
               typeof registration === 'object' &&
               (typeof registration?.schoolYear === 'object'
                  ? registration.schoolYear._id === currentYear?._id
                  : registration?.schoolYear === currentYear?._id)
         ),
      [student, currentYear?._id]
   );

   const matchedClass = schoolClasses.find(
      schoolClass =>
         schoolClass?._id ===
         (typeof currentYearRegistration === 'object' &&
            (typeof currentYearRegistration.schoolClass === 'object'
               ? currentYearRegistration.schoolClass._id
               : currentYearRegistration?.schoolClass))
   );

   const studentName = `${student?.firstname || ''}${
      student?.middlename ? ' ' + student.middlename : ''
   } ${student?.lastname || ''}`;

   useEffect(() => {
      const closeEditDropdown = (ev: MouseEvent) => {
         if (!editButtonRef.current?.contains(ev.target as Node))
            setEditDropdownOpen(false);
      };
      document.addEventListener('click', closeEditDropdown);

      return () => {
         document.removeEventListener('click', closeEditDropdown);
      };
   }, []);

   return (
      <div className='overflow-hidden rounded-lg bg-white shadow border mb-10'>
         <h2 className='sr-only' id='profile-overview-title'>
            Profile Overview
         </h2>
         <div className='bg-white p-6'>
            <div className='sm:flex sm:items-center sm:justify-between'>
               <div className='sm:flex sm:space-x-5'>
                  <div className='flex-shrink-0'>
                     <span
                        className={`inline-flex h-20 w-20 items-center justify-center rounded-full ${
                           student?.gender?.toLowerCase() === 'male'
                              ? 'bg-blue-500'
                              : student?.gender?.toLowerCase() === 'female'
                              ? 'bg-pink-500'
                              : 'bg-gray-500'
                        }`}
                     >
                        <span className='font-medium text-2xl leading-none text-white'>
                           {student?.dateOfBirth &&
                              calcAge(student?.dateOfBirth)}
                        </span>
                     </span>{' '}
                  </div>
                  <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
                     <p className='text-sm font-medium text-gray-600'>
                        Student
                     </p>
                     <p className='text-xl font-bold text-gray-900 sm:text-2xl'>
                        {studentName}
                     </p>
                     <p className='text-sm font-medium text-gray-600'>
                        {matchedClass?.className ?? ''}
                     </p>
                  </div>
               </div>
               <div className='mt-5 flex justify-center sm:mt-0'>
                  <div className='relative inline-block text-left'>
                     <div>
                        <button
                           type='button'
                           className='inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                           id='menu-button'
                           aria-expanded='true'
                           aria-haspopup='true'
                           onClick={() => setEditDropdownOpen(prev => !prev)}
                           ref={editButtonRef}
                        >
                           Edit
                           <svg
                              className='-mr-1 h-5 w-5 text-gray-400'
                              viewBox='0 0 20 20'
                              fill='currentColor'
                              aria-hidden='true'
                           >
                              <path
                                 fillRule='evenodd'
                                 d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
                                 clipRule='evenodd'
                              />
                           </svg>
                        </button>
                     </div>
                     <Transition
                        show={editDropdownOpen}
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                     >
                        <div
                           className='absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                           role='menu'
                           aria-orientation='vertical'
                           aria-labelledby='menu-button'
                           tabIndex={-1}
                        >
                           <div className='py-1' role='none'>
                              <Link
                                 to='edit'
                                 state={{ from: pathname }}
                                 className='text-gray-700 group flex items-center px-4 py-2 text-sm'
                                 role='menuitem'
                                 tabIndex={-1}
                                 id='menu-item-0'
                              >
                                 <svg
                                    className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                    aria-hidden='true'
                                 >
                                    <path d='M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z' />
                                    <path d='M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z' />
                                 </svg>
                                 Edit Full Record
                              </Link>
                              <Link
                                 to='edit-sacraments'
                                 className='text-gray-700 group flex items-center px-4 py-2 text-sm'
                                 role='menuitem'
                                 tabIndex={-1}
                                 id='menu-item-1'
                                 state={{ from: pathname }}
                              >
                                 <svg
                                    className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                    aria-hidden='true'
                                 >
                                    <path d='M7 3.5A1.5 1.5 0 018.5 2h3.879a1.5 1.5 0 011.06.44l3.122 3.12A1.5 1.5 0 0117 6.622V12.5a1.5 1.5 0 01-1.5 1.5h-1v-3.379a3 3 0 00-.879-2.121L10.5 5.379A3 3 0 008.379 4.5H7v-1z' />
                                    <path d='M4.5 6A1.5 1.5 0 003 7.5v9A1.5 1.5 0 004.5 18h7a1.5 1.5 0 001.5-1.5v-5.879a1.5 1.5 0 00-.44-1.06L9.44 6.439A1.5 1.5 0 008.378 6H4.5z' />
                                 </svg>
                                 Edit Sacraments
                              </Link>
                           </div>
                        </div>
                     </Transition>
                  </div>
               </div>
            </div>
         </div>
         <div className='grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x'>
            <div className='px-6 py-5 text-center text-sm font-medium'>
               <span
                  className={`text-lg ${
                     !student?.baptized ||
                     (student?.baptized &&
                        student?.baptized.toLowerCase() === 'no')
                        ? 'text-red-900'
                        : 'text-green-900'
                  }`}
               >
                  {!student?.baptized ||
                  (student?.baptized && student.baptized.toUpperCase() === 'no')
                     ? 'NO'
                     : student.baptized}
               </span>{' '}
               <span className='text-gray-600'>Baptized</span>
            </div>

            <div className='px-6 py-5 text-center text-sm font-medium'>
               <span
                  className={`text-lg ${
                     student?.firstCommunion?.toLowerCase() === 'yes'
                        ? 'text-green-900'
                        : 'text-red-900'
                  }`}
               >
                  {student?.firstCommunion?.toLowerCase() === 'yes'
                     ? 'YES'
                     : 'NO'}
               </span>{' '}
               <span className='text-gray-600'>First Holy Communion</span>
            </div>

            <div className='px-6 py-5 text-center text-sm font-medium'>
               {typeof currentYearRegistration === 'object' && (
                  <Link
                     to='absences'
                     state={{
                        absences: currentYearRegistration?.absences,
                        studentName,
                        yearName: currentYear?.yearName,
                     }}
                  >
                     <span className='text-gray-900 text-lg'>
                        {Array.isArray(currentYearRegistration?.absences)
                           ? currentYearRegistration.absences.length
                           : 0}
                     </span>{' '}
                     <span className='text-gray-600'>Absences</span>
                  </Link>
               )}
            </div>
         </div>
      </div>
   );
}
