import { Link } from 'react-router-dom';

type BreadCrumbItem =
   | {
        link: string;
        title: string;
        active?: false;
     }
   | {
        title: string;
        active: true;
     };

type Props = {
   items: BreadCrumbItem[];
   homeLink: string;
};

export default function BreadCrumb({ items, homeLink }: Props) {
   return (
      <nav
         className='bg-white border-b border-gray-200 flex'
         aria-label='Breadcrumb'
      >
         <ol className='max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8'>
            <li className='flex'>
               <div className='flex items-center'>
                  <Link
                     to={homeLink}
                     className='text-gray-400 hover:text-gray-500'
                  >
                     {/* <!-- Heroicon name: mini/home --> */}
                     <svg
                        className='flex-shrink-0 h-5 w-5'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        aria-hidden='true'
                     >
                        <path
                           fillRule='evenodd'
                           d='M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z'
                           clipRule='evenodd'
                        />
                     </svg>
                     <span className='sr-only'>Home</span>
                  </Link>
               </div>
            </li>
            {items.map(item => (
               <li className='flex' key={item.title}>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     {!item?.active ? (
                        <Link
                           to={item?.link}
                           className='ml-4 text-sm font-light text-gray-500 hover:text-gray-700'
                        >
                           {item.title}
                        </Link>
                     ) : (
                        <span className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'>
                           {item.title}
                        </span>
                     )}
                  </div>
               </li>
            ))}
         </ol>
         <div className='mr-10 mt-3'>
            <svg
               xmlns='http://www.w3.org/2000/svg'
               fill='none'
               viewBox='0 0 24 24'
               strokeWidth='1.5'
               stroke='currentColor'
               className='w-6 h-6'
            >
               <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
               />
            </svg>
         </div>
      </nav>
   );
}
