import { getZoneDate } from '../../../../utils/dateFunctions';

const AddAttendanceStep4 = ({
   selectedStudents,
   selectedDate,
   allRegistrations: registrations,
}) => {
   const selectedRegistrations = registrations?.filter(registration =>
      selectedStudents?.includes(registration?._id)
   );
   return (
      <div className='max-w-7xl xl:w-3/5 xl:min-w-0 lg:min-w-3/4 md:min-w-4/5 sm:min-w-5/6 sm:w-auto w-full mx-auto px-4 sm:px-6 lg:px-8'>
         <div className='px-4 sm:px-6 lg:px-8 mt-20 mb-40'>
            {/* <!--confirmation --> */}
            <div className='sm:flex sm:items-center'>
               <div className='sm:flex-auto'>
                  <h1 className='text-xl font-semibold text-gray-900'>
                     Confirmation
                  </h1>
                  <p className='mt-2 text-sm text-gray-700'>
                     The following students were marked absent for{' '}
                     {selectedDate &&
                        getZoneDate(selectedDate).toLocaleDateString('en-us', {
                           month: 'long',
                           day: '2-digit',
                           year: 'numeric',
                        })}
                     .
                  </p>
               </div>
               <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'></div>
            </div>
            <div className='mt-8 flex flex-col'>
               <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                  <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                     <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                           <thead className='bg-gray-50'>
                              <tr>
                                 <th
                                    scope='col'
                                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                                 >
                                    Name
                                 </th>
                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Class
                                 </th>
                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Date
                                 </th>
                              </tr>
                           </thead>
                           <tbody className='divide-y divide-gray-200 bg-white'>
                              {selectedRegistrations
                                 ?.sort((a, b) =>
                                    a?.student?.firstname
                                       ?.toString()
                                       ?.trim()
                                       ?.localeCompare(
                                          b?.student?.firstname
                                             ?.toString()
                                             ?.trim()
                                       )
                                 )
                                 ?.map(registration => (
                                    <tr key={registration?._id}>
                                       <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                          {registration?.student
                                             ? `${
                                                  registration.student
                                                     ?.firstname || ''
                                               }${
                                                  registration.student
                                                     ?.middlename
                                                     ? ' ' +
                                                       registration.student
                                                          .middlename
                                                     : ''
                                               } ${
                                                  registration.student
                                                     ?.lastname || ''
                                               }`
                                             : ''}
                                       </td>
                                       <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                          {registration?.schoolClass
                                             ?.className || ''}
                                       </td>
                                       <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                          {new Date(
                                             selectedDate
                                          ).toLocaleDateString('en-us', {
                                             month: '2-digit',
                                             day: '2-digit',
                                             year: 'numeric',
                                          })}
                                       </td>
                                    </tr>
                                 ))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AddAttendanceStep4;
