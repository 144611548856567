import { DocumentTextIcon } from '@heroicons/react/solid';
import { getFormattedDate } from '../../../utils/dateFunctions';
import { TStudentResponse } from '../../../types/ReligiousEducation/Student';

type Props = { student: TStudentResponse };

export default function BirthCertTable({ student }: Props) {
   return (
      <div className='px-4 sm:px-6 lg:px-8 mt-10'>
         <div className='sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
               <h2 className='text-lg font-semibold text-gray-900'>
                  Birth Certificate
               </h2>
            </div>
         </div>
         <div className='mt-5 flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
               <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                     <table className='min-w-full divide-y divide-gray-300'>
                        <thead className='bg-gray-50'>
                           <tr>
                              <th
                                 scope='col'
                                 className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                              >
                                 Birth Date
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Docs
                              </th>
                           </tr>
                        </thead>

                        <tbody className='divide-y divide-gray-200 bg-white'>
                           {/* <!-- Odd row --> */}
                           <tr className='hover:bg-gray-100'>
                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                 {student?.dateOfBirth
                                    ? getFormattedDate(student.dateOfBirth)
                                    : null}
                              </td>
                              <td className='text-indigo-600'>
                                 {student?.birthCertificate?.src && (
                                    <a
                                       href={student.birthCertificate.src}
                                       target='_blank'
                                       rel='noreferrer'
                                    >
                                       <DocumentTextIcon className='h-5 w-5' />
                                    </a>
                                 )}
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
