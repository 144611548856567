import {
   DownloadIcon,
   HomeIcon,
   MapIcon,
   OfficeBuildingIcon,
   UserIcon,
   ViewListIcon,
} from '@heroicons/react/outline';
import SidebarItemsComp from '../Common/SidebarItemsComp';

const NewDocumentIcon = () => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
   >
      <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
      />
   </svg>
);

const WeddingSidebarItems = ({ mobile, setSidebarOpen }) => {
   const sidebarItems = [
      {
         name: 'Home',
         href: '',
         svg: HomeIcon,
         end: true,
      },
      {
         name: 'Priests',
         href: '/weddings/priests',
         svg: ViewListIcon,
      },
      {
         name: 'Export Data',
         href: '/weddings/export',
         svg: DownloadIcon,
      },
      {
         title: 'Approvals Required',
         type: 'header',
         items: [
            {
               name: 'Pastor',
               href: '/weddings/pastor-permissions',
               svg: UserIcon,
            },
            {
               name: 'Tribunal',
               href: '/weddings/tribunal-permissions',
               svg: OfficeBuildingIcon,
            },
            {
               name: 'Domicile',
               href: '/weddings/domicile-permissions',
               svg: MapIcon,
            },
         ],
      },
      {
         title: 'REPORTS',
         type: 'header',
         items: [
            {
               name: 'Notes Report',
               href: '/weddings/notes-report',
               svg: NewDocumentIcon,
            },
            {
               name: 'Items Needed',
               href: '/weddings/items-needed',
               svg: NewDocumentIcon,
            },
            {
               name: 'Fees Report',
               href: '/weddings/fees-report',
               svg: NewDocumentIcon,
            },
         ],
      },
   ];
   return (
      <SidebarItemsComp
         mobile={mobile}
         primarySidebarItems={sidebarItems}
         setSidebarOpen={setSidebarOpen}
         showPriest
      />
   );
};

export default WeddingSidebarItems;
