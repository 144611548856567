import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BillingSidebarItems from '../Billing/SidebarItems';
import EducationSidebarItems from '../Religious_Education/SidebarItems';
import SettingsSidebarItems from '../Settings/SidebarItems';
import WebsiteSidebarItems from '../Website/SidebarItems';
import WeddingSidebarItems from '../Weddings/SidebarItems';
import FuneralsSidebarItems from '../Funerals/SidebarItems';
import DashboardSidebarItems from '../Dashboard/SidebarItems';
import BaptismsSidebarItems from '../Baptisms/SidebarItems';
import GiftStoreSidebarItems from '../GiftStore/SidebarItems';

const SidebarRoutes = ({ setSidebarOpen, mobile }) => {
   return (
      <Routes>
         <Route
            path='/dashboard/*'
            element={
               <DashboardSidebarItems
                  mobile={mobile ? true : false}
                  setSidebarOpen={setSidebarOpen}
               />
            }
         />
         <Route
            path='/weddings/*'
            element={
               <WeddingSidebarItems
                  mobile={mobile ? true : false}
                  setSidebarOpen={setSidebarOpen}
               />
            }
         />
         <Route
            path='/funerals/*'
            element={
               <FuneralsSidebarItems
                  mobile={mobile ? true : false}
                  setSidebarOpen={setSidebarOpen}
               />
            }
         />

         {/* <Route path='/donor-management/*' element={<Future />} /> */}
         {/* <Route path='/sacramental-records/*' element={<Future />} /> */}
         <Route
            path='/website/*'
            element={
               <WebsiteSidebarItems
                  mobile={mobile ? true : false}
                  setSidebarOpen={setSidebarOpen}
               />
            }
         />
         <Route
            path='/religious-education/*'
            element={
               <EducationSidebarItems
                  mobile={mobile ? true : false}
                  setSidebarOpen={setSidebarOpen}
               />
            }
         />
         <Route
            path='/baptisms/*'
            element={
               <BaptismsSidebarItems
                  mobile={mobile ? true : false}
                  setSidebarOpen={setSidebarOpen}
               />
            }
         />
         <Route
            path='/gift-store/*'
            element={
               <GiftStoreSidebarItems
                  mobile={mobile}
                  setSidebarOpen={setSidebarOpen}
               />
            }
         />
         {/* <Route path='/profile/*' element={<Future />} /> */}
         <Route
            path='/billing/*'
            element={
               <BillingSidebarItems
                  mobile={mobile ? true : false}
                  setSidebarOpen={setSidebarOpen}
               />
            }
         />
         <Route
            path='/settings/*'
            element={
               <SettingsSidebarItems
                  mobile={mobile ? true : false}
                  setSidebarOpen={setSidebarOpen}
               />
            }
         />
      </Routes>
   );
};

export default SidebarRoutes;
