import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRefreshToken from '../../hooks/useRefreshToken';
import useAuth from '../../hooks/useAuth';
import Suspense from '../UI/Suspense';

const PersistLogin = () => {
   const [isLoading, setIsLoading] = useState(true);
   const refresh = useRefreshToken();
   const { auth } = useAuth();
   const persist = true;

   useEffect(() => {
      let isMounted = true;

      const verifyRefreshToken = async () => {
         try {
            await refresh();
         } catch (err) {
            console.error(err);
         } finally {
            isMounted && setIsLoading(false);
         }
      };

      // Avoids unwanted call to verifyRefreshToken
      !auth?.accessToken && persist
         ? verifyRefreshToken()
         : setIsLoading(false);

      return () => (isMounted = false);
   }, [auth.accessToken, persist, refresh]);

   return (
      <>
         {!persist ? (
            <Suspense>
               <Outlet />
            </Suspense>
         ) : isLoading ? (
            <div
               className='flex items-center justify-center w-full'
               style={{ height: '80vh' }}
            >
               <div className='w-24 h-24 border-l-2 border-t-2 border-gray-900 rounded-full animate-spin'></div>
            </div>
         ) : (
            <Suspense>
               <Outlet />
            </Suspense>
         )}
      </>
   );
};

export default PersistLogin;
