import { useContext, useDebugValue } from 'react';
import AuthContext from '../context/AuthContext';

const useAuth = () => {
   const context = useContext(AuthContext);
   if (!context) {
      throw new Error('useAuthContext must be used within a AuthProvider');
   }
   const auth = context.auth;
   useDebugValue(auth, auth => (auth?.user ? 'Logged In' : 'Logged Out'));
   return context;
};

export default useAuth;
