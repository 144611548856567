import { useState } from 'react';
import useDownload from '../../../../hooks/useDownload';
import ConfirmBox from '../../../UI/ConfirmBox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEducationContext } from '../../../../context/ReligiousEducation/EducationContext';
import { toast } from 'react-toastify';
import { TStudentResponse } from '../../../../types/ReligiousEducation/Student';

type Props = {
   fileName: string;
   student: TStudentResponse;
   inputFile: {} | File;
   cloudFileInfo?: { src: string; publicId: string; type?: string };
   setFile: React.Dispatch<React.SetStateAction<{} | File | null>>;
};

export default function FileActionButtons({
   fileName,
   inputFile,
   student,
   cloudFileInfo,
   setFile,
}: Props) {
   const download = useDownload();
   const queryClient = useQueryClient();
   const { deleteStudentFile } = useEducationContext();

   const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

   const cancelDelete = () => {
      setOpenDeleteConfirm(false);
   };

   const deleteMutation = useMutation({
      mutationFn: () =>
         deleteStudentFile(student._id, cloudFileInfo?.publicId as string),
      onSuccess: () => {
         toast.success(
            `${fileName} of ${student?.firstname || ''}${
               student?.middlename ? ' ' + student.middlename : ''
            } ${student?.lastname || ''} deleted successfully.`
         );
         queryClient.invalidateQueries(['students', student._id]);
         setFile(null);
      },
      onError: (error: any) => {
         toast.error(error?.message ?? `Failed to delete ${fileName}!`);
      },
   });

   return (
      <>
         <div className='flex items-center mb-2'>
            {cloudFileInfo?.src ? (
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='mr-2 w-6 h-6 text-green-400'
               >
                  <path
                     fillRule='evenodd'
                     d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z'
                     clipRule='evenodd'
                  />
               </svg>
            ) : null}

            <p className='block font-bold text-lg'>{fileName}</p>
         </div>

         <span className='isolate inline-flex rounded-md shadow-sm mb-10'>
            {cloudFileInfo?.src && !('path' in inputFile) ? (
               <>
                  <a
                     href={cloudFileInfo?.src}
                     target='_blank'
                     rel='noreferrer'
                     className='relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                  >
                     View
                  </a>
                  <button
                     type='button'
                     onClick={() =>
                        download(cloudFileInfo?.src as string, {
                           name: fileName,
                        })
                     }
                     className='relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                  >
                     Download
                  </button>
                  <button
                     type='button'
                     onClick={() => setOpenDeleteConfirm(true)}
                     disabled={deleteMutation.isLoading}
                     className='disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-wait relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                  >
                     {deleteMutation.isLoading ? 'Deleting...' : 'Delete'}
                  </button>
               </>
            ) : null}
            <button
               type='button'
               onClick={() => setFile(null)}
               className='relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
            >
               Change
            </button>
         </span>
         <ConfirmBox
            open={openDeleteConfirm}
            setOpen={setOpenDeleteConfirm}
            handleConfirmCancel={cancelDelete}
            confirmMsg={
               <>
                  Are you sure to delete the {fileName} of{' '}
                  <strong>
                     {student?.firstname || ''}
                     {student?.middlename ? ' ' + student.middlename : ''}{' '}
                     {student?.lastname || ''}
                  </strong>
                  ?
               </>
            }
            title={`Delete ${fileName}`}
            callback={deleteMutation.mutate}
            loading={deleteMutation.isLoading}
         />
      </>
   );
}
