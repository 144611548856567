import { createContext } from 'react';
import { Outlet } from 'react-router-dom';
import useInput from '../hooks/useInput';

type TSearchContext = {
   searchQuery: string;
   searchInputAttr: {
      value: string;
      onChange: (
         ev:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
      ) => void;
   };
   resetSearch: () => void;
};

const SearchContext = createContext<TSearchContext | null>(null);

export const SearchProvider = () => {
   const [searchQuery, searchInputAttr, resetSearch] = useInput('');

   return (
      <SearchContext.Provider
         value={{
            searchQuery,
            searchInputAttr,
            resetSearch,
         }}
      >
         <Outlet />
      </SearchContext.Provider>
   );
};

export default SearchContext;
