import { Route, Routes } from 'react-router-dom';
import NotFound from '../../components/NotFound';
import Dashboard from './Dashboard/Dashboard';
import BaptismDates from './Dates/BaptismDates';
import AddBaptismDate from './Dates/AddBaptismDate';
import { BaptismProvider } from '../../context/Baptisms/BaptismContext';
import EditBaptismDate from './Dates/EditBaptismDate';
import AddBaptism from './AddBaptism';
import EditBaptism from './EditBaptism';
import BaptismsNotesReport from './Reports/NotesReport';

const BaptismRoutes = () => {
   return (
      <BaptismProvider>
         <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/add-baptism' element={<AddBaptism />} />
            <Route path='/:baptismId' element={<EditBaptism />} />
            <Route path='/dates' element={<BaptismDates />} />
            <Route path='/dates/add' element={<AddBaptismDate />} />
            <Route path='/dates/:dateId/edit' element={<EditBaptismDate />} />
            <Route
               path='/reports/notes-report'
               element={<BaptismsNotesReport />}
            />
            <Route path='*' element={<NotFound />} />
         </Routes>
      </BaptismProvider>
   );
};

export default BaptismRoutes;
