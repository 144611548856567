import BreadCrumb from '../../../components/UI/BreadCrumb';
import useBaptismContext from '../../../context/Baptisms/BaptismContext';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import PageSpinner from '../../../components/UI/PageSpinner';
import { TBaptismDate } from '../../../types/Baptisms/BaptismDates';
import { IBaptism } from '../../../types/Baptisms/Baptisms';
import { getFormattedDate } from '../../../utils/dateFunctions';
import { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Dashboard() {
   const { getUpcomingDatesWithBaptisms, getBaptismsOfPastDates } =
      useBaptismContext();

   const { data: upcomingDatesWithBaptisms, isLoading } = useQuery({
      queryFn: getUpcomingDatesWithBaptisms,
      queryKey: ['upcomingDatesWithBaptisms'],
   });

   const pastBaptismsQuery = useInfiniteQuery({
      queryKey: ['infinitePastBaptisms'],
      queryFn: getBaptismsOfPastDates,
      getNextPageParam: (lastpage, pages) => {
         const currentPage = parseInt(lastpage.currentPage as string);
         const totalPages = parseInt(lastpage.totalPages as string);

         if (currentPage < totalPages) return currentPage + 1;
         return;
      },
   });

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <main className='flex-1'>
         <BreadCrumb
            homeLink='/dashboard'
            items={[{ active: true, title: 'Baptisms' }]}
         />

         <div className='max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto mb-5 mt-10'>
            <div className='mb-5'>
               <div className='flex items-center'>
                  <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                     Upcoming Baptisms
                  </h4>
               </div>
            </div>

            {isLoading ? (
               <PageSpinner height='20rem' />
            ) : (
               (upcomingDatesWithBaptisms ?? []).map(upcomingDate => (
                  <UpcomingDate
                     upcomingDate={upcomingDate}
                     key={upcomingDate._id}
                  />
               ))
            )}

            {/* <!-- End Grid --> */}
         </div>

         {/* <!-- Past Baptisms --> */}
         <div className='max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto mb-20'>
            <div className='mb-10'>
               <div className='flex items-center'>
                  <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                     Past Baptisms
                  </h4>

                  <div className='flex-1 border-t-2 border-gray-200'></div>
                  <p></p>
               </div>
            </div>
            {/* <!-- Grid --> */}
            <div className='grid lg:grid-cols-1 lg:gap-y-5 gap-10'>
               {/* <!-- Card --> */}
               {pastBaptismsQuery.isLoading ? (
                  <PageSpinner height='20rem' />
               ) : (
                  pastBaptismsQuery?.data?.pages?.map(page => (
                     <Fragment key={page.currentPage}>
                        {(page.pastBaptisms ?? []).map(baptism => (
                           <PastBaptismCard
                              key={baptism._id}
                              baptism={baptism}
                           />
                        ))}
                     </Fragment>
                  ))
               )}

               {/* <!-- End Card --> */}
               {!pastBaptismsQuery.isLoading ? (
                  <button
                     type='button'
                     onClick={() => pastBaptismsQuery.fetchNextPage()}
                     disabled={!pastBaptismsQuery.hasNextPage}
                     className='disabled:cursor-default block w-fit self-center justify-self-center border-gray-200 border text-indigo-600 rounded-md bg-white px-4 py-2 text-center text-sm font-semibold shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  >
                     {pastBaptismsQuery.isFetchingNextPage
                        ? 'Loading...'
                        : pastBaptismsQuery?.hasNextPage
                        ? 'Load More'
                        : 'No more baptisms!'}
                  </button>
               ) : null}
            </div>
            {/* <!-- End Grid --> */}
         </div>
      </main>
   );
}

function PastBaptismCard({ baptism }: { baptism: IBaptism }) {
   return (
      <a
         className='group rounded-xl overflow-hidden hover:bg-gray-100 border border-gray-200 py-10'
         href='#'
      >
         <div className='sm:flex'>
            <div className='grow mt-4 sm:mt-0 sm:ml-6 px-4 sm:px-0'>
               <h3 className='text-xl font-medium text-gray-900 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-gray-800'>
                  {baptism?.firstname ?? ''} {baptism?.middlename ?? ''}{' '}
                  {baptism?.lastname ?? ''}
               </h3>
               <p>
                  <span className='font-semibold'>Baptism Date:</span>{' '}
                  <span className='font-light'>
                     {typeof baptism.dateTime === 'object'
                        ? new Date(baptism.dateTime.date).toLocaleDateString(
                             'en-us',
                             { day: 'numeric', month: 'long', year: 'numeric' }
                          )
                        : ''}
                  </span>
               </p>
            </div>
         </div>
      </a>
   );
}

function UpcomingDate({ upcomingDate }: { upcomingDate: TBaptismDate }) {
   return (
      <div>
         <div className='py-10'>
            <div className='flex items-center'>
               <h4 className='flex-shrink-0 bg-white pr-4 text-xl font-semibold text-gray-600'>
                  {new Date(upcomingDate.date).toLocaleString('en-us', {
                     year: 'numeric',
                     day: 'numeric',
                     month: 'long',
                     hour: 'numeric',
                     minute: 'numeric',
                     weekday: 'long',
                  })}
               </h4>

               <div className='flex-1 border-t-2 border-gray-200'></div>
               <span className='ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-2xl font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10'>
                  {typeof upcomingDate.officiant === 'object'
                     ? (upcomingDate.officiant?.firstname ?? '') +
                       ' ' +
                       (upcomingDate.officiant?.lastname ?? '')
                     : ''}
               </span>
            </div>
         </div>

         {/* <!--new test--> */}
         <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
            {(upcomingDate?.baptisms ?? []).map(baptism =>
               typeof baptism === 'object' ? (
                  <UpcomingBaptismCard baptism={baptism} key={baptism._id} />
               ) : null
            )}
         </div>
      </div>
   );
}

function UpcomingBaptismCard({ baptism }: { baptism: IBaptism }) {
   return (
      <div className='p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700'>
         <div className=''>
            <Link to={`${baptism._id}`}>
               <h5 className='flex items-center text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>
                  {baptism.firstname} {baptism?.middlename ?? ''}{' '}
                  {baptism.lastname}
                  {baptism?.birthCertificate &&
                  baptism?.allParentDocs &&
                  baptism?.godmotherDocs &&
                  baptism?.godfatherDocs &&
                  baptism?.baptismClassParents &&
                  baptism?.baptismClassGodmother &&
                  baptism?.baptismClassGodfather ? (
                     <span className='w-6 h-6 ml-2 text-green-400'>
                        <svg
                           xmlns='http://www.w3.org/2000/svg'
                           fill='none'
                           viewBox='0 0 24 24'
                           strokeWidth='1.5'
                           stroke='currentColor'
                           className='w-full h-full'
                        >
                           <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                           />
                        </svg>
                     </span>
                  ) : (
                     <span className='w-6 h-6 ml-2 text-red-700'>
                        <svg
                           xmlns='http://www.w3.org/2000/svg'
                           fill='none'
                           viewBox='0 0 24 24'
                           strokeWidth='1.5'
                           stroke='currentColor'
                           className='w-full h-full'
                        >
                           <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z'
                           />
                        </svg>
                     </span>
                  )}
               </h5>
            </Link>
            <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>
               Born: {getFormattedDate(baptism.dateOfBirth)}
            </p>

            <p className='font-bold text-xs text-gray-900 dark:text-gray-400'>
               Parents
            </p>

            <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>
               {baptism?.father?.firstname ?? ''}{' '}
               {baptism?.father?.middlename ?? ''}{' '}
               {baptism?.father?.lastname ?? ''}{' '}
               {baptism?.father && baptism?.mother ? 'and' : ''}{' '}
               {baptism?.mother?.firstname ?? ''}{' '}
               {baptism?.mother?.middlename ?? ''}{' '}
               {baptism?.mother?.lastname ?? ''}
               {baptism?.allParentDocs ? (
                  <span className='ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10'>
                     All Docs
                  </span>
               ) : (
                  <span className='ml-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10'>
                     Need Docs
                  </span>
               )}
            </p>

            <p className='font-bold text-xs text-gray-900 dark:text-gray-400'>
               Godparents
            </p>

            <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>
               {baptism?.godParent1?.firstname ?? ''}{' '}
               {baptism?.godParent1?.middlename ?? ''}{' '}
               {baptism?.godParent1?.lastname ?? ''}{' '}
               {baptism?.godParent1 && baptism?.godParent2 ? 'and' : ''}{' '}
               {baptism?.godParent2?.firstname ?? ''}{' '}
               {baptism?.godParent2?.middlename ?? ''}{' '}
               {baptism?.godParent2?.lastname}
               {baptism?.godmotherDocs && baptism?.godfatherDocs ? (
                  <span className='ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10'>
                     All Docs
                  </span>
               ) : (
                  <span className='ml-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10'>
                     Need Docs
                  </span>
               )}
            </p>
         </div>
      </div>
   );
}
