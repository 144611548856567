import { ReactNode, createContext, useState } from 'react';

type TAuth = {
   user: string;
   accessToken: string;
   church?: string;
   firstname: string;
   lastname: string;
   middlename?: string;
   photo?: {
      src: string;
      publicId: string;
   };
   moduleOnLogin?: string;
   churchId?: string;
};

type TAuthContext = {
   auth: TAuth | {};
   setAuth: React.Dispatch<React.SetStateAction<{} | TAuth>>;
};

const AuthContext = createContext<TAuthContext | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
   const [auth, setAuth] = useState<TAuthContext['auth'] | {}>({});
   // const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);

   return (
      <AuthContext.Provider value={{ auth, setAuth }}>
         {children}
      </AuthContext.Provider>
   );
};

export default AuthContext;
