import { useSearchParams } from 'react-router-dom';
import axios from '../../api/axios';
import { EXTERNAL_PARENT_REGISTRATION_API } from '../../utils/constants';
import { useQuery } from '@tanstack/react-query';
import PageSpinner from '../../components/UI/PageSpinner';

export default function HandleParentsUnsub() {
   const [queryParams] = useSearchParams();

   const token = queryParams.get('t');
   const requestFn = () =>
      axios
         .get(
            `${EXTERNAL_PARENT_REGISTRATION_API}/verifyUnsubscriptionToken?token=${token}`,
            {
               headers: { 'Content-Type': 'application/json' },
            }
         )
         .then(res => res.data);

   const tokenQuery = useQuery({
      queryKey: ['token', token],
      queryFn: requestFn,
      enabled: token != null && token !== '',
   });

   if (!token) return <div>Invalid Link</div>;

   if (tokenQuery.isLoading) return <PageSpinner />;

   if (tokenQuery.isError) return <div>Error! Please try again.</div>;

   if (!tokenQuery?.data?.church?.church_name) return <div>Invalid Link</div>;

   return (
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
         <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 mt-20'>
            <div className='text-center'>
               <p className='text-base font-semibold text-indigo-600'>
                  {tokenQuery.data.church.church_name ?? ''}
               </p>
               <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                  You're Unsubscribed!
               </h1>
               <p className='mt-6 text-base leading-7 text-gray-600 max-w-2xl mx-auto'>
                  Sorry to see you go.
               </p>
               <p className='text-base leading-7 text-gray-600 max-w-2xl mx-auto'>
                  You will miss critical emails from the Religious Education
                  Program.
               </p>
               <p className='text-base leading-7 text-gray-600 max-w-2xl mx-auto'>
                  If you change your mind, you can subscribe again.
               </p>

               <div className='mt-10 flex items-center justify-center gap-x-6'>
                  <a
                     href='#'
                     className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  >
                     Subscribe
                  </a>
               </div>
            </div>
         </main>
      </div>
   );
}
