import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import FilterDropdown from '../../../UI/FilterDropdown';
import { TStudentResponse } from '../../../../types/ReligiousEducation/Student';
import { TSchoolClassResponse } from '../../../../types/ReligiousEducation/SchoolClass';
import { TSchoolYearResponse } from '../../../../types/ReligiousEducation/SchoolYear';
import { TRegistrationResponse } from '../../../../types/ReligiousEducation/Registration';
import useURLState from '../../../../hooks/useURLState';
import jwtDecode from 'jwt-decode';
import useAuth from '../../../../hooks/useAuth';

type Props = {
   students: TStudentResponse[];
   schoolClasses: TSchoolClassResponse[];
   schoolYears?: TSchoolYearResponse[];
   registrations?: TRegistrationResponse[];
};

export default function MissingDocsComp({
   students,
   schoolClasses,
   schoolYears,
   registrations,
}: // currentSchoolYear,
Props) {
   const navigate = useNavigate();

   const { auth } = useAuth();
   const decoded =
      'accessToken' in auth && auth?.accessToken
         ? jwtDecode<any>(auth.accessToken)
         : undefined;
   const roles = decoded?.UserInfo?.roles || [];

   //Filters Logic start
   const [documentFilter, setDocumentFilter] = useURLState('document');

   // const [documentFilter, setDocumentFilter] = useState({});
   const [studentTypeFilter, setStudentTypeFilter] = useState<{
      name?: string;
      value?: string;
   }>({});
   const [yearFilter, setYearFilter] = useState<{
      name?: string;
      value?: TSchoolYearResponse;
   }>({});
   const [classFilter, setClassFilter] = useState<{
      name?: string;
      value?: TSchoolClassResponse;
   }>({});

   const currentStudents = useMemo(
      () => students.filter(student => student?.status === 'Current'),
      [students]
   );

   const documentFilteredStudents = useMemo(
      () =>
         !documentFilter
            ? currentStudents
            : currentStudents.filter(student => {
                 switch (documentFilter) {
                    case 'Baptism Doc':
                       return (
                          student?.baptized != null &&
                          student?.baptized !== '' &&
                          student?.baptized?.toLowerCase() !== 'no' &&
                          !student?.baptismalCertificate?.src
                       );

                    case 'First Communion Doc':
                       return (
                          student?.firstCommunion != null &&
                          student?.firstCommunion !== '' &&
                          student?.firstCommunion?.toLowerCase() !== 'no' &&
                          !student?.firstCommunionCertificate?.src
                       );

                    case 'Confirmation Doc':
                       return (
                          student?.confirmation != null &&
                          student?.confirmation !== '' &&
                          student?.confirmation?.toLowerCase() !== 'no' &&
                          !student?.confirmationCertificate?.src
                       );
                    default:
                       return false;
                 }
              }),
      [documentFilter, currentStudents]
   );

   const typeFilteredStudents = useMemo(
      () =>
         !studentTypeFilter?.value
            ? documentFilteredStudents
            : documentFilteredStudents?.filter(student => {
                 return (
                    student?.type?.toLowerCase() ===
                    studentTypeFilter?.value?.toLowerCase()
                 );
              }),
      [documentFilteredStudents, studentTypeFilter?.value]
   );

   const yearFilteredStudents = useMemo(
      () =>
         !yearFilter?.value
            ? typeFilteredStudents
            : typeFilteredStudents.filter(student => {
                 const year = student?.registrationId?.map(registration =>
                    typeof registration === 'object'
                       ? registration?.schoolYear
                       : registrations?.find(r => r._id === registration)
                            ?.schoolYear
                 );
                 return year?.includes(yearFilter?.value?._id);
              }),
      [yearFilter?.value, typeFilteredStudents, registrations]
   );

   const classFilteredStudents = useMemo(
      () =>
         !classFilter?.value
            ? yearFilteredStudents
            : yearFilteredStudents.filter(student => {
                 const schoolClass = student?.registrationId.map(registration =>
                    typeof registration === 'object'
                       ? !yearFilter?.value?._id
                          ? typeof registration?.schoolClass === 'object'
                             ? registration.schoolClass._id
                             : registration.schoolClass
                          : yearFilter?.value?._id === registration?.schoolYear
                          ? typeof registration?.schoolClass === 'object'
                             ? registration.schoolClass._id
                             : registration.schoolClass
                          : null
                       : null
                 );
                 return schoolClass.includes(classFilter?.value?._id ?? null);
              }),
      [classFilter?.value, yearFilteredStudents, yearFilter?.value?._id]
   );

   const sortedStudents = useMemo(
      () =>
         classFilteredStudents.sort((a, b) =>
            a?.lastname < b?.lastname ? -1 : b?.lastname < a?.lastname ? 1 : 0
         ),
      [classFilteredStudents]
   );

   const documentDropdownItems = useMemo(
      () => ['', 'Baptism Doc', 'First Communion Doc', 'Confirmation Doc'],
      []
   );

   const typeDropdownItems = useMemo(
      () => [
         { name: 'All', value: '' },
         { name: 'Church', value: 'Church Student' },
         { name: 'School', value: 'School Student' },
      ],
      []
   );

   const sortedYearsForDropdown = useMemo(
      () =>
         Array.isArray(schoolYears) && schoolYears.length > 0
            ? [
                 { name: 'All', value: '' },
                 ...schoolYears.map(year => ({
                    name: year?.yearName,
                    value: year,
                 })),
              ]
            : [],
      [schoolYears]
   );

   const classDropdownItems = useMemo(
      () => [
         { name: 'All', value: '' },
         ...schoolClasses
            .sort((a, b) =>
               a?.className < b?.className
                  ? -1
                  : b?.className < a?.className
                  ? 1
                  : 0
            )
            .map(eachClass => ({
               name: eachClass?.className,
               value: eachClass,
            })),
      ],
      [schoolClasses]
   );

   return (
      <div className='px-4 sm:px-6 lg:px-8 mt-10 mb-40'>
         <div className='sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
               <h2 className='text-lg font-semibold text-gray-900'>
                  Missing Docs Report
               </h2>
               <p className='mt-2 text-sm text-gray-700'></p>
            </div>
            <div>
               <FilterDropdown
                  items={documentDropdownItems}
                  selectedState={[documentFilter, setDocumentFilter]}
                  defaultValue='Missing Docs'
                  nullishName='All'
               />
            </div>
            <div>
               <FilterDropdown
                  items={typeDropdownItems}
                  selectedState={[studentTypeFilter, setStudentTypeFilter]}
                  defaultValue='Student Type'
               />
            </div>
            {sortedYearsForDropdown.length > 0 ? (
               <div>
                  <FilterDropdown
                     items={sortedYearsForDropdown}
                     selectedState={[yearFilter, setYearFilter]}
                     defaultValue='School Year'
                     // selectedValue={currentSchoolYear}
                  />
               </div>
            ) : null}

            {Array.isArray(schoolClasses) && schoolClasses.length > 1 ? (
               <div>
                  <FilterDropdown
                     items={classDropdownItems}
                     selectedState={[classFilter, setClassFilter]}
                     defaultValue='Class'
                  />
               </div>
            ) : null}
         </div>
         <div className='mt-10 flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
               <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                     <table className='min-w-full divide-y divide-gray-300'>
                        <thead className='bg-gray-50'>
                           <tr>
                              <th
                                 scope='col'
                                 className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                              >
                                 Student Name
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Baptism Doc
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 First Communion Doc
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Confirmation Doc
                              </th>
                           </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 bg-white'>
                           {sortedStudents?.map(student => (
                              <tr
                                 className='hover:bg-gray-100'
                                 key={student?._id}
                              >
                                 <td
                                    className='whitespace-nowrap cursor-pointer py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'
                                    onClick={() =>
                                       navigate(
                                          roles.includes('teacher')
                                             ? `/re/teacher/students/${student._id}`
                                             : `/religious-education/students/${student.id}`
                                       )
                                    }
                                 >
                                    {`${student?.firstname || ''}${
                                       student?.middlename
                                          ? ' ' + student.middlename
                                          : ''
                                    } ${student?.lastname || ''}`}
                                 </td>
                                 <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6'>
                                    {student?.baptized == null ||
                                    student?.baptized === '' ||
                                    student?.baptized?.toLowerCase() ===
                                       'no' ? null : !student
                                         ?.baptismalCertificate?.src ? (
                                       <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                          strokeWidth='1.5'
                                          stroke='currentColor'
                                          className='w-6 h-6 text-red-500'
                                       >
                                          <path
                                             strokeLinecap='round'
                                             strokeLinejoin='round'
                                             d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                          />
                                       </svg>
                                    ) : (
                                       <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                          strokeWidth='1.5'
                                          stroke='currentColor'
                                          className='w-6 h-6  text-green-400'
                                       >
                                          <path
                                             strokeLinecap='round'
                                             strokeLinejoin='round'
                                             d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                          />
                                       </svg>
                                    )}
                                 </td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm'>
                                    {student?.firstCommunion == null ||
                                    student?.firstCommunion === '' ||
                                    student?.firstCommunion?.toLowerCase() ===
                                       'no' ? null : !student
                                         ?.firstCommunionCertificate?.src ? (
                                       <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                          strokeWidth='1.5'
                                          stroke='currentColor'
                                          className='w-6 h-6 text-red-500'
                                       >
                                          <path
                                             strokeLinecap='round'
                                             strokeLinejoin='round'
                                             d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                          />
                                       </svg>
                                    ) : (
                                       <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                          strokeWidth='1.5'
                                          stroke='currentColor'
                                          className='w-6 h-6  text-green-400'
                                       >
                                          <path
                                             strokeLinecap='round'
                                             strokeLinejoin='round'
                                             d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                          />
                                       </svg>
                                    )}
                                 </td>

                                 <td className='whitespace-nowrap px-3 py-4 text-sm'>
                                    {student?.confirmation == null ||
                                    student?.confirmation === '' ||
                                    student?.confirmation?.toLowerCase() ===
                                       'no' ? null : !student
                                         ?.confirmationCertificate?.src ? (
                                       <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                          strokeWidth='1.5'
                                          stroke='currentColor'
                                          className='w-6 h-6 text-red-500'
                                       >
                                          <path
                                             strokeLinecap='round'
                                             strokeLinejoin='round'
                                             d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                          />
                                       </svg>
                                    ) : (
                                       <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                          strokeWidth='1.5'
                                          stroke='currentColor'
                                          className='w-6 h-6 text-green-400'
                                       >
                                          <path
                                             strokeLinecap='round'
                                             strokeLinejoin='round'
                                             d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                          />
                                       </svg>
                                    )}
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
