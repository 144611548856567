import { ReactNode, createContext, useContext } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
   BAPTISM_DATES_API,
   EMAIL_API,
   FUNERALS_API,
   PRODUCTS_API,
   REGISTRATIONS_API,
   STAFFS_API,
   STUDENTS_API,
   VIDEO_TRAINING_API,
   WEBSITE_API,
   WEDDING_API,
} from '../utils/constants';
import { Outlet } from 'react-router-dom';
import { IStaffs, IStaffsResponse } from '../types/Staffs';
import { TResponseWebsiteEvent } from '../types/WebsiteEvent';
import { TAppContext } from '../types/AppContext';
import axios from '../api/axios';

const AppContext = createContext<TAppContext | null>(null);

export const AppProvider = ({ children }: { children?: ReactNode }) => {
   const axiosPrivate = useAxiosPrivate();

   const getStaffs: TAppContext['getStaffs'] = ({
      personType,
      status,
      role,
   } = {}): Promise<IStaffsResponse[]> =>
      axiosPrivate
         .get(STAFFS_API, { params: { personType, status, role } })
         .then(res => res.data);
   const addStaff = (data: IStaffs): Promise<IStaffsResponse> =>
      axiosPrivate.post(STAFFS_API, data).then(res => res.data);
   const getStaff = (staffID: string): Promise<IStaffsResponse> =>
      axiosPrivate.get(`${STAFFS_API}/${staffID}`).then(res => res.data);
   const editStaff = ([staffID, data]: [
      string,
      IStaffs
   ]): Promise<IStaffsResponse> =>
      axiosPrivate
         .patch(`${STAFFS_API}/${staffID}`, data)
         .then(res => res.data);

   //Website Functions
   const getEvents = (): Promise<TResponseWebsiteEvent[]> =>
      axiosPrivate.get(`${WEBSITE_API}/events`).then(res => res.data);
   const getEvent = (eventID: string): Promise<TResponseWebsiteEvent> =>
      axiosPrivate
         .get(`${WEBSITE_API}/events/${eventID}`)
         .then(res => res.data);

   const editEvent = ([eventID, data]: [
      string,
      FormData
   ]): Promise<TResponseWebsiteEvent> =>
      axiosPrivate
         .patch(`${WEBSITE_API}/events/${eventID}`, data)
         .then(res => res.data);

   const saveEvent = (formData: FormData): Promise<TResponseWebsiteEvent> =>
      axiosPrivate
         .post(`${WEBSITE_API}/events`, formData)
         .then(res => res.data);
   const deleteEvent = (eventID: string): Promise<unknown> =>
      axiosPrivate
         .delete(`${WEBSITE_API}/events/${eventID}`)
         .then(res => res.data);

   //Counts
   const countUpcomingFunerals = (): Promise<number> =>
      axiosPrivate
         .get(`${FUNERALS_API}/count?date=upcoming`)
         .then(res => res.data);
   const countUpcomingEvents = (): Promise<number> =>
      axiosPrivate
         .get(`${WEBSITE_API}/events/count?date=upcoming`)
         .then(res => res.data);
   const countUpcomingWeddings = (): Promise<number> =>
      axiosPrivate
         .get(`${WEDDING_API}/count?date=upcoming`)
         .then(res => res.data);
   const countCurrentRegistrations = (): Promise<number> =>
      axiosPrivate.get(`${REGISTRATIONS_API}/count`).then(res => res.data);
   const countUnpaidCurrentRegistrations = (): Promise<number> =>
      axiosPrivate
         .get(`${REGISTRATIONS_API}/count?paid=No`)
         .then(res => res.data);
   const countFaithRequired = (): Promise<number> =>
      axiosPrivate
         .get(
            `${STUDENTS_API}/count?baptized=${encodeURIComponent(
               'Baptized Protestant'
            )}&baptized=${encodeURIComponent(
               'Baptized Orthodox'
            )}&professionOfFaith=false`
         )
         .then(res => res.data);
   const countMissingDocs = (): Promise<{
      baptism: number;
      firstCommunion: number;
      confirmation: number;
      birthCertificate: number;
      total: number;
   }> =>
      axiosPrivate
         .get(`${STUDENTS_API}/count/missingDocs`)
         .then(res => res.data);
   const countAttendanceIssues = (): Promise<number> =>
      axiosPrivate
         .get(`${REGISTRATIONS_API}/count?attendance=4`)
         .then(res => res.data);

   const getEmails: TAppContext['getEmails'] = module =>
      axiosPrivate.get(EMAIL_API, { params: { module } }).then(res => res.data);

   const getEmail: TAppContext['getEmail'] = id =>
      axiosPrivate.get(`${EMAIL_API}/${id}`).then(res => res.data);

   const getUpcomingFunerals: TAppContext['getUpcomingFunerals'] =
      async dateType => {
         const { data } = await axiosPrivate.get(FUNERALS_API, {
            params: { dateType },
         });
         return data;
      };

   const getVideoTrainings: TAppContext['getVideoTrainings'] = () =>
      axios.get(VIDEO_TRAINING_API).then(res => res.data);

   const getVideoTraining: TAppContext['getVideoTraining'] = trainingID =>
      axios.get(`${VIDEO_TRAINING_API}/${trainingID}`).then(res => res.data);

   const addVideoTraining: TAppContext['addVideoTraining'] = data =>
      axiosPrivate.post(VIDEO_TRAINING_API, data).then(res => res.data);

   const editVideoTraining: TAppContext['editVideoTraining'] = ([
      trainingID,
      data,
   ]) =>
      axiosPrivate
         .patch(`${VIDEO_TRAINING_API}/${trainingID}`, data)
         .then(res => res.data);

   const deleteVideoTraining: TAppContext['deleteVideoTraining'] = trainingID =>
      axiosPrivate
         .delete(`${VIDEO_TRAINING_API}/${trainingID}`)
         .then(res => res.data);

   const getUpcomingDatesWithBaptisms: TAppContext['getUpcomingDatesWithBaptisms'] =
      () =>
         axiosPrivate
            .get(`${BAPTISM_DATES_API}/upcomingDatesWithBaptisms`)
            .then(res => res.data);

   //Products
   const fetchProducts: TAppContext['fetchProducts'] = () =>
      axiosPrivate.get(PRODUCTS_API).then(res => res.data);
   const addProduct: TAppContext['addProduct'] = data =>
      axiosPrivate
         .post(PRODUCTS_API, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
         })
         .then(res => res.data);

   const value = {
      getStaffs,
      addStaff,
      getStaff,
      editStaff,
      getEvents,
      saveEvent,
      getEvent,
      editEvent,
      deleteEvent,
      countUpcomingFunerals,
      countUpcomingEvents,
      countUpcomingWeddings,
      countCurrentRegistrations,
      countUnpaidCurrentRegistrations,
      countFaithRequired,
      countMissingDocs,
      countAttendanceIssues,
      getEmails,
      getEmail,
      getUpcomingFunerals,
      getVideoTrainings,
      getVideoTraining,
      addVideoTraining,
      editVideoTraining,
      deleteVideoTraining,
      getUpcomingDatesWithBaptisms,
      fetchProducts,
      addProduct,
   };

   return (
      <AppContext.Provider value={value}>
         <Outlet />
      </AppContext.Provider>
   );
};

export default AppContext;

export const useAppContext = () => {
   const context = useContext(AppContext);
   if (!context) {
      throw new Error('useAppContext must be used within a AppProvider');
   }
   return context;
};
