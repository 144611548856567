import { Link, useNavigate } from 'react-router-dom';
import { TBaptismDate } from '../../types/Baptisms/BaptismDates';
import { getFormattedDate } from '../../utils/dateFunctions';

type Props = {
   baptismDates: TBaptismDate[];
};

export default function BaptismsTable({ baptismDates }: Props) {
   const navigate = useNavigate();

   return (
      <div className='max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto mb-20'>
         <div className='mb-10'>
            <div className='flex items-center'>
               <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                  Upcoming Baptisms
               </h4>

               <div className='flex-1 border-t-2 border-gray-200'></div>
            </div>
         </div>

         {/* <!-- Grid --> */}

         <div className='px-4 sm:px-6 lg:px-8'>
            <div className='sm:flex sm:items-center'>
               <div className='sm:flex-auto'></div>

               <div className='sm:ml-16 sm:mt-0 sm:flex-none'>
                  <button
                     type='button'
                     onClick={() => navigate('/baptisms')}
                     className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  >
                     Baptism Dashboard
                  </button>
               </div>
            </div>
            <div className='mt-8 flow-root'>
               <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                  <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                     <table className='min-w-full divide-y divide-gray-300'>
                        <thead>
                           <tr>
                              <th
                                 scope='col'
                                 className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                              >
                                 Date
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Presider
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Person to be Baptized
                              </th>

                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Time
                              </th>

                              <th
                                 scope='col'
                                 className='relative py-3.5 pl-3 pr-4 sm:pr-3'
                              >
                                 <span className='sr-only'></span>
                              </th>
                           </tr>
                        </thead>

                        <tbody className='bg-white'>
                           {baptismDates.map(baptismDate =>
                              (baptismDate?.baptisms ?? []).map(baptism =>
                                 typeof baptism === 'object' ? (
                                    <tr className='even:bg-gray-50'>
                                       <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                                          {getFormattedDate(baptismDate.date, {
                                             useZoneOffset: false,
                                          })}
                                       </td>

                                       <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                          {typeof baptismDate.officiant ===
                                          'object'
                                             ? (baptismDate.officiant
                                                  ?.firstname ?? '') +
                                               ' ' +
                                               (baptismDate.officiant
                                                  ?.lastname ?? '')
                                             : ''}
                                       </td>

                                       <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                          {`${baptism?.firstname || ''}${
                                             baptism?.middlename
                                                ? ' ' + baptism.middlename
                                                : ''
                                          } ${baptism?.lastname || ''}`}
                                       </td>

                                       <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                          {new Date(
                                             baptismDate.date
                                          ).toLocaleTimeString('en-us', {
                                             hour: '2-digit',
                                             minute: '2-digit',
                                          })}
                                       </td>

                                       <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3'>
                                          <Link
                                             to={`/baptisms/${baptism._id}`}
                                             className='text-indigo-600 hover:text-indigo-900'
                                          >
                                             View
                                          </Link>
                                       </td>
                                    </tr>
                                 ) : null
                              )
                           )}

                           {/* <!-- More people... --> */}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>

         {/* <!-- End Grid --> */}
      </div>
   );
}
