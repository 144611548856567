import { PlusSmIcon } from '@heroicons/react/solid';
import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FilterDropdown from '../../../UI/FilterDropdown';
import useAuth from '../../../../hooks/useAuth';
import jwtDecode from 'jwt-decode';
import useURLState from '../../../../hooks/useURLState';

const BaptismReportComp = ({
   registrations,
   currentSchoolYear,
   schoolClasses,
   schoolYears,
}) => {
   const navigate = useNavigate();

   const { auth } = useAuth();
   const decoded = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
   const roles = decoded?.UserInfo?.roles || [];

   //Filters Logic start
   const [baptismStatusFilter, setBaptismStatusFilter] =
      useURLState('baptismStatus');

   const [yearFilter, setYearFilter] = useState({});
   const [studentTypeFilter, setStudentTypeFilter] = useState({});
   const [classFilter, setClassFilter] = useState({});

   const baptismFilteredRegistrations = useMemo(
      () =>
         !baptismStatusFilter
            ? registrations
            : registrations?.filter(registration =>
                 !registration?.student?.baptized &&
                 baptismStatusFilter?.toLowerCase() === 'no'
                    ? true
                    : registration?.student?.baptized &&
                      registration?.student?.baptized?.toLowerCase() ===
                         baptismStatusFilter?.toLowerCase()
              ),
      [baptismStatusFilter, registrations]
   );

   const typeFilteredRegistrations = useMemo(
      () =>
         !studentTypeFilter?.value
            ? baptismFilteredRegistrations
            : baptismFilteredRegistrations?.filter(registration => {
                 return (
                    registration?.student?.type?.toLowerCase() ===
                    studentTypeFilter?.value?.toLowerCase()
                 );
              }),
      [baptismFilteredRegistrations, studentTypeFilter?.value]
   );

   const yearFilteredRegistrations = useMemo(
      () =>
         !yearFilter?.value
            ? typeFilteredRegistrations
            : typeFilteredRegistrations.filter(registration => {
                 return registration?.schoolYear === yearFilter?.value?._id;
              }),
      [typeFilteredRegistrations, yearFilter?.value]
   );

   const classFilteredRegistrations = useMemo(
      () =>
         !classFilter?.value
            ? yearFilteredRegistrations
            : yearFilteredRegistrations?.filter(registration => {
                 return (
                    registration?.schoolClass?._id === classFilter?.value?._id
                 );
              }),
      [classFilter?.value, yearFilteredRegistrations]
   );

   const sortedStudents = useMemo(
      () =>
         classFilteredRegistrations.sort((a, b) =>
            a?.student?.lastname < b?.student?.lastname
               ? -1
               : b?.student?.lastname < a?.student?.lastname
               ? 1
               : 0
         ),
      [classFilteredRegistrations]
   );

   const baptismDropdownItems = useMemo(
      () => [
         '',
         'Baptized Catholic',
         'Baptized Orthodox',
         'Baptized Protestant',
      ],
      []
   );

   const typeDropdownItems = useMemo(
      () => [
         { name: 'All', value: '' },
         { name: 'Church', value: 'Church Student' },
         { name: 'School', value: 'School Student' },
         { name: 'OCIA', value: 'RCIA' },
      ],
      []
   );

   const yearDropdownItems = useMemo(
      () => [
         { name: 'All', value: '' },
         ...schoolYears.map(year => ({
            name: year?.yearName,
            value: year,
         })),
      ],
      [schoolYears]
   );

   const classDropdownItems = useMemo(
      () => [
         { name: 'All', value: '' },
         ...schoolClasses
            .sort((a, b) =>
               a?.className < b?.className
                  ? -1
                  : b?.className < a?.className
                  ? 1
                  : 0
            )
            .map(eachClass => ({
               name: eachClass?.className,
               value: eachClass,
            })),
      ],
      [schoolClasses]
   );

   return (
      <main className='flex-1'>
         {/* <!-- BREADCRUMBS --> */}
         <nav
            className='bg-white border-b border-gray-200 flex'
            aria-label='Breadcrumb'
         >
            <ol className='max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8'>
               <li className='flex'>
                  <div className='flex items-center'>
                     <a href='#k' className='text-gray-400 hover:text-gray-500'>
                        {/* <!-- Heroicon name: mini/home --> */}
                        <svg
                           className='flex-shrink-0 h-5 w-5'
                           xmlns='http://www.w3.org/2000/svg'
                           viewBox='0 0 20 20'
                           fill='currentColor'
                           aria-hidden='true'
                        >
                           <path
                              fillRule='evenodd'
                              d='M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z'
                              clipRule='evenodd'
                           />
                        </svg>
                        <span className='sr-only'>Home</span>
                     </a>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <a
                        href='#k'
                        className='ml-4 text-sm font-light text-gray-500 hover:text-gray-700'
                     >
                        Religious Education Dashboard
                     </a>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <a
                        href='#k'
                        className='ml-4 text-sm font-light text-gray-500 hover:text-gray-700'
                     >
                        Reports
                     </a>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <a
                        href='#k'
                        className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                     >
                        Baptism Report
                     </a>
                  </div>
               </li>
            </ol>
            <div className='mr-10 mt-3'>
               {' '}
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
                  />
               </svg>
            </div>
         </nav>

         {/* <!--attendance table--> */}
         <div className='px-4 sm:px-6 lg:px-8 mt-10 mb-40'>
            <div className='lg:flex lg:items-center'>
               <div className='lg:flex-auto'>
                  <h2 className='text-lg font-semibold text-gray-900'>
                     Baptism Report
                  </h2>
                  <p className='mt-2 text-sm text-gray-700'>
                     From here you can view the Baptism Status & Type of
                     Students.
                  </p>
               </div>
               <div>
                  <FilterDropdown
                     items={baptismDropdownItems}
                     selectedState={[
                        baptismStatusFilter,
                        setBaptismStatusFilter,
                     ]}
                     defaultValue='Baptism Status'
                     nullishName='All'
                     className='relative inline-block text-left lg:ml-5 mt-4 lg:mt-0'
                  />
               </div>
               <div>
                  <FilterDropdown
                     items={typeDropdownItems}
                     selectedState={[studentTypeFilter, setStudentTypeFilter]}
                     defaultValue='Student Type'
                     className='relative inline-block text-left lg:ml-5 mt-4 lg:mt-0'
                  />
               </div>
               <div>
                  <FilterDropdown
                     items={yearDropdownItems}
                     selectedState={[yearFilter, setYearFilter]}
                     defaultValue='School Year'
                     selectedValue={currentSchoolYear}
                     className='relative inline-block text-left lg:ml-5 mt-4 lg:mt-0'
                  />
               </div>
               {Array.isArray(schoolClasses) && schoolClasses.length > 1 && (
                  <div>
                     <FilterDropdown
                        items={classDropdownItems}
                        selectedState={[classFilter, setClassFilter]}
                        defaultValue='Class'
                        className='relative inline-block text-left lg:ml-5 mt-4 lg:mt-0'
                     />
                  </div>
               )}
               <div className='mt-4 lg:mt-0 lg:ml-16 lg:flex-none'>
                  <Link to=''>
                     <button
                        type='button'
                        className='inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                     >
                        {/* <!-- Heroicon name: solid/plus-sm --> */}
                        <PlusSmIcon className='h-5 w-5' />
                     </button>
                  </Link>
               </div>
            </div>
            <div className='mb-40 mt-10 flex flex-col'>
               <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                  <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                     <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                           <thead className='bg-gray-50'>
                              <tr>
                                 <th
                                    scope='col'
                                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                                 >
                                    Student Name
                                 </th>
                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Baptized
                                 </th>

                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Baptism Type
                                 </th>
                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Profession of Faith Required
                                 </th>
                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Class
                                 </th>
                              </tr>
                           </thead>
                           <tbody className='divide-y divide-gray-200 bg-white'>
                              {sortedStudents.map((registration, index) => (
                                 <tr
                                    className='hover:bg-gray-100'
                                    key={registration?._id || index}
                                 >
                                    <td
                                       onClick={() =>
                                          navigate(
                                             roles.includes('teacher')
                                                ? `/re/teacher/students/${registration.student._id}`
                                                : `/religious-education/students/${registration.student.id}`
                                          )
                                       }
                                       className='cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'
                                    >
                                       {registration?.student
                                          ? `${
                                               registration.student
                                                  ?.firstname || ''
                                            }${
                                               registration.student?.middlename
                                                  ? ' ' +
                                                    registration.student
                                                       ?.middlename
                                                  : ''
                                            } ${
                                               registration.student?.lastname ||
                                               ''
                                            }`
                                          : ''}
                                    </td>
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                       {!registration?.student?.baptized ||
                                       (registration?.student?.baptized &&
                                          registration?.student?.baptized?.toLowerCase() ===
                                             'no')
                                          ? 'No'
                                          : 'Yes'}
                                    </td>

                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                       {registration?.student?.baptized &&
                                       registration.student.baptized?.toLowerCase() ===
                                          'no'
                                          ? 'Not Baptized'
                                          : !registration?.student?.baptized
                                          ? 'Not Baptized'
                                          : registration?.student?.baptized}
                                    </td>

                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                       {!registration?.student?.baptized
                                          ? ''
                                          : registration?.student?.baptized?.toLowerCase() ===
                                            'baptized protestant'
                                          ? 'Yes'
                                          : 'No'}
                                    </td>
                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                       {registration?.schoolClass?.className
                                          ? registration.schoolClass.className
                                          : ''}
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   );
};

export default BaptismReportComp;
