import { useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { TStudentResponse } from '../../../../types/ReligiousEducation/Student';
import useInput from '../../../../hooks/useInput';
import {
   dateObjToInputVal,
   getZoneDate,
} from '../../../../utils/dateFunctions';
import useFileInput from '../../../../hooks/useFileInput';
import TailwindDatepicker from '../../../UI/TailwindDatepicker';
import FileActionButtons from './FileActionButtons';

type Props = {
   student: TStudentResponse;
   editStudentFn: (studentId: string, formData: FormData) => any;
   studentLoader: boolean;
};

export default function EditSacramentsForm({
   student,
   editStudentFn,
   studentLoader,
}: Props) {
   const { state: urlState } = useLocation();

   const navigate = useNavigate();

   const [type, typeAttr] = useInput(student?.type || '');
   const [schoolExclusions, schoolExclusionsAttr] = useInput(
      student?.school_excluded_from_sacraments || ''
   );
   const [sacramentalTrack, sacramentalTrackAttr] = useInput(
      student?.sacramentalTrack || ''
   );
   const [easterVigilTarget, easterVigilTargetAttr] = useInput(
      student?.easterVigilTarget ?? ''
   );
   const [baptized, baptizedAttr] = useInput(student?.baptized || '');
   const [baptizedDate, setBaptizedDate] = useState(
      student?.baptizedDate ? dateObjToInputVal(student.baptizedDate) : ''
   );

   const [churchOfBaptism, baptismChurchAttr] = useInput(
      student?.churchOfBaptism || ''
   );
   const [firstCommunion, firstCommAttr] = useInput(
      student?.firstCommunion || ''
   );
   const [firstCommunionDate, setFirstCommunionDate] = useState(
      student?.firstCommunionDate
         ? dateObjToInputVal(student?.firstCommunionDate)
         : ''
   );
   const [churchOfFirstCommunion, commChurchAttr] = useInput(
      student?.churchOfFirstCommunion || ''
   );
   const [confirmation, confirmationAttr] = useInput(
      student?.confirmation || ''
   );
   const [confirmationDate, setConfirmationDate] = useState(
      student?.confirmationDate
         ? dateObjToInputVal(student?.confirmationDate)
         : ''
   );
   const [confirmationChurch, confirmChurchAttr] = useInput(
      student?.confirmationChurch || ''
   );

   const [professionOfFaith, setProfessionOfFaith] = useState(
      student?.professionOfFaith === true
   );
   const [professionOfFaithDate, professionOfFaithDateAttr] = useInput(
      dateObjToInputVal(student?.professionOfFaithDate)
   );

   //Files State
   const [baptismCertFile, baptismCertOptions, setBaptismCert] = useFileInput(
      student?.baptismalCertificate?.src
         ? { name: 'Baptismal_Certificate.pdf' }
         : null,
      { maxSizeInMB: 30 }
   );
   const [firstCommCertFile, firstCommCertOptions, setFirstCommCertFile] =
      useFileInput(
         student?.firstCommunionCertificate?.src
            ? { name: 'First_Communion_Certificate.pdf' }
            : null,
         { maxSizeInMB: 30 }
      );
   const [
      confirmationCertFile,
      confirmationCertOptions,
      setConfirmationCertFile,
   ] = useFileInput(
      student?.confirmationCertificate?.src
         ? { name: 'Confirmation_Certificate.pdf' }
         : null,
      { maxSizeInMB: 30 }
   );
   const [sacramentFormFile, sacramentalFormOptions, setSacramentFormFile] =
      useFileInput(
         student?.sacramentalForm?.src
            ? { name: 'Sacramental Form.pdf' }
            : null,
         { maxSizeInMB: 30 }
      );
   const [consentFormFile, consentFormOptions, setConsentFormFile] =
      useFileInput(
         student?.consentForm?.src ? { name: 'Consent Form.pdf' } : null,
         { maxSizeInMB: 30 }
      );

   const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      if (sacramentalTrack === 'Easter Vigil' && !easterVigilTarget) {
         toast.error(
            'Easter Vigil Target is required for Easter Vigil Sacramental Track!'
         );
         return;
      }

      const formData = new FormData();

      formData.append('type', type);
      formData.append('school_excluded_from_sacraments', schoolExclusions);
      formData.append('sacramentalTrack', sacramentalTrack);
      formData.append('easterVigilTarget', easterVigilTarget);
      formData.append('baptized', baptized);
      formData.append('baptizedDate', baptizedDate as string);
      formData.append('churchOfBaptism', churchOfBaptism);
      formData.append('firstCommunion', firstCommunion);
      formData.append('firstCommunionDate', firstCommunionDate);
      formData.append('churchOfFirstCommunion', churchOfFirstCommunion);
      formData.append('confirmation', confirmation);
      formData.append('confirmationDate', confirmationDate);
      formData.append('confirmationChurch', confirmationChurch);
      if (baptismCertFile != null && 'size' in baptismCertFile)
         formData.append('baptismalCertificate', baptismCertFile);
      if (firstCommCertFile != null && 'size' in firstCommCertFile)
         formData.append('firstCommunionCertificate', firstCommCertFile);
      if (confirmationCertFile != null && 'size' in confirmationCertFile)
         formData.append('confirmationCertificate', confirmationCertFile);
      if (sacramentFormFile != null && 'size' in sacramentFormFile)
         formData.append('sacramentalForm', sacramentFormFile);
      if (consentFormFile != null && 'size' in consentFormFile)
         formData.append('consentForm', consentFormFile);

      if (baptized === 'Baptized Protestant')
         formData.append(
            'professionOfFaith',
            JSON.stringify(professionOfFaith)
         );
      if (professionOfFaithDate)
         formData.append('professionOfFaithDate', professionOfFaithDate);

      try {
         await editStudentFn(student._id, formData);

         toast.success("Student's sacrament info updated successfully.");
         navigate(urlState?.from ? urlState.from : '/login', {
            replace: true,
         });
      } catch (err: any) {
         if (err) {
            toast.error(
               err?.message ??
                  'Failed to update sacrament info. Please try again.'
            );
         }
      }
   };
   return (
      <section className='bg-white dark:bg-gray-900'>
         <div className='py-8 px-4 mx-auto md:max-w-6xl lg:py-16'>
            <h2 className='mb-4 text-xl font-bold text-gray-900 dark:text-white'>
               Student Sacramental Records{' '}
               <span className='inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10'>
                  Edit Mode
               </span>
            </h2>

            <form onSubmit={handleSubmit}>
               <div className='gap-4 sm:grid sm:grid-cols-2 xl:grid-cols-3 sm:gap-6 sm:mb-2'>
                  <div className='mb-4 space-y-4 xl:col-span-2'>
                     <div>
                        <div className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                           Student Type
                        </div>
                        <div className='space-y-4 md:flex md:space-y-0 md:space-x-4'>
                           <div className='w-full'>
                              <label htmlFor='student-type' className='sr-only'>
                                 Student type
                              </label>
                              <select
                                 id='student-type'
                                 {...typeAttr}
                                 className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                              >
                                 <option value=''>
                                    --Choose Student Type--
                                 </option>
                                 <option value='Church Student'>Church</option>
                                 <option value='School Student'>School</option>
                                 <option value='RCIA'>OCIA</option>
                              </select>
                           </div>
                        </div>
                     </div>

                     {/* <!----> */}
                     <div>
                        <div className='space-y-4 md:flex md:space-y-0 md:space-x-4'>
                           <div className='w-1/2'>
                              <label
                                 htmlFor='sacramentalStatus'
                                 className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                              >
                                 Sacramental Status
                              </label>

                              <select
                                 id='sacramentalStatus'
                                 {...schoolExclusionsAttr}
                                 className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                              >
                                 <option value=''>
                                    --Choose Sacramental Status--
                                 </option>
                                 <option>Sacramental Program</option>
                                 <option>Excluded from Sacraments</option>
                              </select>
                           </div>

                           <div className='w-1/2'>
                              <label
                                 htmlFor='sacramental-track'
                                 className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                              >
                                 Sacramental Track
                              </label>

                              <select
                                 id='sacramental-track'
                                 {...sacramentalTrackAttr}
                                 className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                              >
                                 <option value=''>
                                    --Choose Sacramental Track--
                                 </option>
                                 <option>Normal</option>
                                 <option>Easter Vigil</option>
                              </select>
                           </div>
                        </div>
                     </div>
                     {sacramentalTrack === 'Easter Vigil' ? (
                        <div>
                           <div className='space-y-4 md:flex md:space-y-0 md:space-x-4'>
                              <div className='w-full'>
                                 <label
                                    htmlFor='easter-vigil-target'
                                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                 >
                                    Easter Vigil Target
                                 </label>

                                 <select
                                    id='easter-vigil-target'
                                    {...easterVigilTargetAttr}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                                 >
                                    <option value=''>--Choose Target--</option>
                                    <option>Easter Vigil 2024</option>
                                    <option>Easter Vigil 2025</option>
                                    <option>Easter Vigil 2026</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                     ) : null}

                     {/* <!--baptismal status--> */}
                     <div>
                        <div className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                           Baptismal Status
                        </div>
                        <div className='space-y-4 md:flex md:space-y-0 md:space-x-4'>
                           <div className='w-full'>
                              <label
                                 htmlFor='baptismal-status'
                                 className='sr-only'
                              >
                                 Baptismal Status
                              </label>
                              <select
                                 id='baptismal-status'
                                 {...baptizedAttr}
                                 className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                              >
                                 <option value=''>
                                    --Choose Baptismal Status--
                                 </option>
                                 <option>Baptized Catholic</option>
                                 <option>Baptized Protestant</option>
                                 <option>Baptized Orthodox</option>
                                 <option value='No'>Not Baptized</option>
                              </select>
                           </div>
                        </div>
                     </div>

                     {baptized !== 'No' ? (
                        <div>
                           <label
                              htmlFor='baptismalDetails'
                              className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                           >
                              Enter Baptismal Details
                           </label>
                           <div className='items-center space-y-4 md:flex md:space-y-0'>
                              <TailwindDatepicker
                                 options={{
                                    defaultDate: student?.baptizedDate
                                       ? getZoneDate(student.baptizedDate)
                                       : null,
                                    inputIdProp: 'baptismalDetails',
                                    inputPlaceholderProp: 'Select Baptism Date',
                                 }}
                                 onChange={date => setBaptizedDate(date)}
                              />
                              <span className='hidden text-gray-500 md:mx-4 md:flex'>
                                 at
                              </span>
                              <div className='relative w-full'>
                                 <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'></div>
                                 <input
                                    {...baptismChurchAttr}
                                    type='text'
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 datepicker-input'
                                    placeholder='Enter Baptism Church Name and Location'
                                 />
                              </div>
                           </div>
                        </div>
                     ) : null}

                     {/* Start Profession Of Faith fields */}
                     {baptized === 'Baptized Protestant' && (
                        <div className='mt-5 -space-y-px'>
                           <div>
                              <h3 className='text-sm font-bold leading-6 text-gray-700'>
                                 Profession of Faith
                              </h3>
                           </div>
                           <div className='ml-10'>
                              <fieldset>
                                 <div className='mt-2 space-y-4'>
                                    <div className='relative flex items-start'>
                                       <div className='flex h-5 items-center'>
                                          <input
                                             id='professionOfFaith'
                                             name='professionOfFaith'
                                             type='checkbox'
                                             checked={professionOfFaith}
                                             onChange={() =>
                                                setProfessionOfFaith(
                                                   prev => !prev
                                                )
                                             }
                                             className='h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                                          />
                                       </div>
                                       <div className='ml-3 text-sm'>
                                          <label
                                             htmlFor='professionOfFaith'
                                             className='font-medium text-gray-700'
                                          >
                                             Profession of Faith
                                          </label>
                                       </div>
                                    </div>
                                    {professionOfFaith && (
                                       <div className='w-1/3 min-w-0 flex-1'>
                                          {/* <!--checkbox end--> */}
                                          <legend className='block text-sm font-medium text-gray-700'>
                                             Profession of Faith Date
                                          </legend>

                                          <label
                                             htmlFor='professionOfFaithDate'
                                             className='sr-only'
                                          >
                                             Profession of Faith Date
                                          </label>
                                          <input
                                             type='date'
                                             name='professionOfFaithDate'
                                             id='professionOfFaithDate'
                                             {...professionOfFaithDateAttr}
                                             className='relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                          />
                                       </div>
                                    )}
                                 </div>
                              </fieldset>
                           </div>
                        </div>
                     )}
                     {/* End Profession Of Faith fields */}

                     {/* <!--communion status--> */}
                     <div className='mb-20'>
                        <div className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                           First Communion Status
                        </div>
                        <div className='space-y-4 md:flex md:space-y-0 md:space-x-4'>
                           <div className='w-full'>
                              <label
                                 htmlFor='first-communion'
                                 className='sr-only'
                              >
                                 First Communion Status
                              </label>
                              <select
                                 id='first-communion'
                                 {...firstCommAttr}
                                 className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                              >
                                 <option value=''>
                                    --Choose Communion Status--
                                 </option>
                                 <option>No</option>
                                 <option>Yes</option>
                              </select>
                           </div>
                        </div>
                     </div>

                     {firstCommunion === 'Yes' ? (
                        <div>
                           <label
                              htmlFor='firstCommunionDetails'
                              className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                           >
                              Enter First Communion Details
                           </label>
                           <div className='items-center space-y-4 md:flex md:space-y-0'>
                              <TailwindDatepicker
                                 options={{
                                    defaultDate: student?.firstCommunionDate
                                       ? getZoneDate(student.firstCommunionDate)
                                       : null,
                                    inputIdProp: 'firstCommunionDetails',
                                    inputPlaceholderProp:
                                       'Select First Communion Date',
                                 }}
                                 onChange={date => setFirstCommunionDate(date)}
                              />
                              <span className='hidden text-gray-500 md:mx-4 md:flex'>
                                 at
                              </span>
                              <div className='relative w-full'>
                                 <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'></div>
                                 <input
                                    type='text'
                                    {...commChurchAttr}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 datepicker-input'
                                    placeholder='Enter First Communion Church and Location'
                                 />
                              </div>
                           </div>
                        </div>
                     ) : null}

                     {/* <!--Confirmation status--> */}
                     <div>
                        <div className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                           Confirmation Status
                        </div>
                        <div className='space-y-4 md:flex md:space-y-0 md:space-x-4'>
                           <div className='w-full'>
                              <label
                                 htmlFor='confirmation-status'
                                 className='sr-only'
                              >
                                 Confirmation Status
                              </label>
                              <select
                                 id='confirmation-status'
                                 {...confirmationAttr}
                                 className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                              >
                                 <option value=''>
                                    --Choose Confirmation Status--
                                 </option>
                                 <option>No</option>
                                 <option>Yes</option>
                              </select>
                           </div>
                        </div>
                     </div>

                     {confirmation === 'Yes' ? (
                        <div>
                           <label
                              htmlFor='confirmationDetails'
                              className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                           >
                              Enter Confirmation Details
                           </label>
                           <div className='items-center space-y-4 md:flex md:space-y-0'>
                              <TailwindDatepicker
                                 options={{
                                    defaultDate: student?.confirmationDate
                                       ? getZoneDate(student.confirmationDate)
                                       : null,
                                    inputIdProp: 'confirmationDetails',
                                    inputPlaceholderProp:
                                       'Select Confirmation Date',
                                 }}
                                 onChange={date => setConfirmationDate(date)}
                              />
                              <span className='hidden text-gray-500 md:mx-4 md:flex'>
                                 at
                              </span>
                              <div className='relative w-full'>
                                 <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'></div>
                                 <input
                                    {...confirmChurchAttr}
                                    type='text'
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 datepicker-input'
                                    placeholder='Enter Confirmation Church and Location'
                                 />
                              </div>
                           </div>
                        </div>
                     ) : null}

                     {/* <!--save buttton--> */}
                     <div className='mt-10'></div>
                     <button
                        type='submit'
                        disabled={studentLoader}
                        className='disabled:bg-gray-400 disabled:cursor-wait rounded-md bg-indigo-600 px-40 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                     >
                        {studentLoader ? 'Saving...' : 'Save'}
                     </button>
                  </div>
                  <div className='mb-4 space-y-4'>
                     <div>
                        {/* <!--upload--> */}
                        {baptized !== 'No' ? (
                           !baptismCertFile ? (
                              <>
                                 <label
                                    htmlFor='baptism-certificate'
                                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                 >
                                    Upload Baptismal Certificate
                                 </label>
                                 <div className='flex justify-center items-center w-full mb-5'>
                                    <div
                                       {...baptismCertOptions.getRootProps()}
                                       className='flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                                    >
                                       <div className='flex flex-col justify-center items-center pt-5 pb-6'>
                                          <svg
                                             aria-hidden='true'
                                             className='mb-3 w-10 h-10 text-gray-400'
                                             fill='none'
                                             stroke='currentColor'
                                             viewBox='0 0 24 24'
                                             xmlns='http://www.w3.org/2000/svg'
                                          >
                                             <path
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='2'
                                                d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                                             ></path>
                                          </svg>
                                          <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                                             <span className='font-semibold'>
                                                Click to upload
                                             </span>
                                             or drag and drop
                                          </p>
                                          <p className='mb-4 text-xs text-gray-500 dark:text-gray-400'>
                                             Max. File Size: 30MB
                                          </p>
                                          <button
                                             type='button'
                                             className='py-2 px-3 text-xs font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
                                          >
                                             Extra small
                                          </button>
                                       </div>
                                       <input
                                          id='baptism-certificate'
                                          {...baptismCertOptions.getInputProps()}
                                          className='hidden'
                                       />
                                    </div>
                                 </div>
                              </>
                           ) : (
                              <FileActionButtons
                                 inputFile={baptismCertFile}
                                 setFile={setBaptismCert}
                                 cloudFileInfo={student?.baptismalCertificate}
                                 fileName='Baptism Certificate'
                                 student={student}
                              />
                           )
                        ) : null}

                        {/* <!--shows if there's a document--> */}

                        {/* <!--first communion doc--> */}
                        {firstCommunion === 'Yes' ? (
                           !firstCommCertFile ? (
                              <>
                                 <label
                                    htmlFor='first-communion-file'
                                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                 >
                                    Upload First Communion Certificate
                                 </label>
                                 <div className='flex justify-center items-center w-full mb-5'>
                                    <div
                                       {...firstCommCertOptions.getRootProps()}
                                       className='flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                                    >
                                       <div className='flex flex-col justify-center items-center pt-5 pb-6'>
                                          <svg
                                             aria-hidden='true'
                                             className='mb-3 w-10 h-10 text-gray-400'
                                             fill='none'
                                             stroke='currentColor'
                                             viewBox='0 0 24 24'
                                             xmlns='http://www.w3.org/2000/svg'
                                          >
                                             <path
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='2'
                                                d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                                             ></path>
                                          </svg>
                                          <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                                             <span className='font-semibold'>
                                                Click to upload
                                             </span>
                                             or drag and drop
                                          </p>
                                          <p className='mb-4 text-xs text-gray-500 dark:text-gray-400'>
                                             Max. File Size: 30MB
                                          </p>
                                          <button
                                             type='button'
                                             className='py-2 px-3 text-xs font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
                                          >
                                             Extra small
                                          </button>
                                       </div>
                                       <input
                                          id='first-communion-file'
                                          type='file'
                                          className='hidden'
                                          {...firstCommCertOptions.getInputProps()}
                                       />
                                    </div>
                                 </div>
                              </>
                           ) : (
                              <FileActionButtons
                                 inputFile={firstCommCertFile}
                                 setFile={setFirstCommCertFile}
                                 fileName='First Communion Certificate'
                                 cloudFileInfo={
                                    student?.firstCommunionCertificate
                                 }
                                 student={student}
                              />
                           )
                        ) : null}

                        {/* <!--shows if there's a document--> */}

                        {/* <!----> */}

                        {/* <!--confirmation  doc--> */}
                        {confirmation === 'Yes' ? (
                           !confirmationCertFile ? (
                              <>
                                 <label
                                    htmlFor='confirmation-certificate'
                                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                 >
                                    Upload Confirmation Certificate
                                 </label>
                                 <div className='flex justify-center items-center w-full mb-5'>
                                    <div
                                       {...confirmationCertOptions.getRootProps()}
                                       className='flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                                    >
                                       <div className='flex flex-col justify-center items-center pt-5 pb-6'>
                                          <svg
                                             aria-hidden='true'
                                             className='mb-3 w-10 h-10 text-gray-400'
                                             fill='none'
                                             stroke='currentColor'
                                             viewBox='0 0 24 24'
                                             xmlns='http://www.w3.org/2000/svg'
                                          >
                                             <path
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='2'
                                                d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                                             ></path>
                                          </svg>
                                          <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                                             <span className='font-semibold'>
                                                Click to upload
                                             </span>
                                             or drag and drop
                                          </p>
                                          <p className='mb-4 text-xs text-gray-500 dark:text-gray-400'>
                                             Max. File Size: 30MB
                                          </p>
                                          <button
                                             type='button'
                                             className='py-2 px-3 text-xs font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
                                          >
                                             Extra small
                                          </button>
                                       </div>
                                       <input
                                          id='confirmation-certificate'
                                          type='file'
                                          className='hidden'
                                          {...confirmationCertOptions.getInputProps()}
                                       />
                                    </div>
                                 </div>
                              </>
                           ) : (
                              <FileActionButtons
                                 inputFile={confirmationCertFile}
                                 setFile={setConfirmationCertFile}
                                 fileName='Confirmation Certificate'
                                 cloudFileInfo={
                                    student?.confirmationCertificate
                                 }
                                 student={student}
                              />
                           )
                        ) : null}
                        {/* <!--shows if there's a document--> */}

                        {/* <!----> */}

                        {/* <!--sacramental doc--> */}
                        {!sacramentFormFile ? (
                           <>
                              <label
                                 htmlFor='sacramental-form'
                                 className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                              >
                                 Upload Sacramental Form
                              </label>
                              <div className='flex justify-center items-center w-full mb-5'>
                                 <div
                                    {...sacramentalFormOptions.getRootProps()}
                                    className='flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                                 >
                                    <div className='flex flex-col justify-center items-center pt-5 pb-6'>
                                       <svg
                                          aria-hidden='true'
                                          className='mb-3 w-10 h-10 text-gray-400'
                                          fill='none'
                                          stroke='currentColor'
                                          viewBox='0 0 24 24'
                                          xmlns='http://www.w3.org/2000/svg'
                                       >
                                          <path
                                             strokeLinecap='round'
                                             strokeLinejoin='round'
                                             strokeWidth='2'
                                             d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                                          ></path>
                                       </svg>
                                       <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                                          <span className='font-semibold'>
                                             Click to upload
                                          </span>
                                          or drag and drop
                                       </p>
                                       <p className='mb-4 text-xs text-gray-500 dark:text-gray-400'>
                                          Max. File Size: 30MB
                                       </p>
                                       <button
                                          type='button'
                                          className='py-2 px-3 text-xs font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
                                       >
                                          Extra small
                                       </button>
                                    </div>
                                    <input
                                       id='sacramental-form'
                                       type='file'
                                       className='hidden'
                                       {...sacramentalFormOptions.getInputProps()}
                                    />
                                 </div>
                              </div>
                           </>
                        ) : (
                           <FileActionButtons
                              inputFile={sacramentFormFile}
                              setFile={setSacramentFormFile}
                              fileName='Sacramental Form'
                              cloudFileInfo={student?.sacramentalForm}
                              student={student}
                           />
                        )}
                        {!consentFormFile ? (
                           <>
                              <label
                                 htmlFor='consent-form'
                                 className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                              >
                                 Upload Consent Form
                              </label>
                              <div className='flex justify-center items-center w-full mb-5'>
                                 <div
                                    {...consentFormOptions.getRootProps()}
                                    className='flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                                 >
                                    <div className='flex flex-col justify-center items-center pt-5 pb-6'>
                                       <svg
                                          aria-hidden='true'
                                          className='mb-3 w-10 h-10 text-gray-400'
                                          fill='none'
                                          stroke='currentColor'
                                          viewBox='0 0 24 24'
                                          xmlns='http://www.w3.org/2000/svg'
                                       >
                                          <path
                                             strokeLinecap='round'
                                             strokeLinejoin='round'
                                             strokeWidth='2'
                                             d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                                          ></path>
                                       </svg>
                                       <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                                          <span className='font-semibold'>
                                             Click to upload
                                          </span>
                                          or drag and drop
                                       </p>
                                       <p className='mb-4 text-xs text-gray-500 dark:text-gray-400'>
                                          Max. File Size: 30MB
                                       </p>
                                       <button
                                          type='button'
                                          className='py-2 px-3 text-xs font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
                                       >
                                          Extra small
                                       </button>
                                    </div>
                                    <input
                                       id='consent-form'
                                       type='file'
                                       className='hidden'
                                       {...consentFormOptions.getInputProps()}
                                    />
                                 </div>
                              </div>
                           </>
                        ) : (
                           <FileActionButtons
                              inputFile={consentFormFile}
                              setFile={setConsentFormFile}
                              fileName='Consent Form'
                              cloudFileInfo={student?.consentForm}
                              student={student}
                           />
                        )}
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </section>
   );
}
