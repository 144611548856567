import { toast } from 'react-toastify';

const AddAttendanceStep2 = ({
   setStep,
   selectedStudents,
   setSelectedStudents,
   registrations,
}) => {
   const handleSubmit = () => {
      if (selectedStudents.length < 1)
         return toast.error(
            'Select at least one student to update attendance.'
         );
      setStep(3);
   };

   const handleCheck = id => {
      setSelectedStudents(prev =>
         prev?.includes(id) ? prev?.filter(_id => _id !== id) : [...prev, id]
      );
   };

   return (
      <div className='max-w-7xl xl:w-3/5 xl:min-w-0 lg:min-w-3/4 md:min-w-4/5 sm:min-w-5/6 sm:w-auto w-full mx-auto px-4 sm:px-6 lg:px-8'>
         <nav aria-label='Progress' className='mt-20'>
            <ol className='space-y-4 md:flex md:space-y-0 md:space-x-8'>
               <li className='md:flex-1'>
                  <button
                     onClick={() => setStep(1)}
                     type='button'
                     className='group flex w-full flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0'
                  >
                     <span className='text-sm font-medium text-indigo-600 group-hover:text-indigo-800'>
                        Step 1
                     </span>
                     <span className='text-sm font-medium'>Choose Date</span>
                  </button>
               </li>

               <li className='md:flex-1'>
                  <a
                     href='#k'
                     className='flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0'
                     aria-current='step'
                  >
                     <span className='text-sm font-medium text-indigo-600'>
                        Step 2
                     </span>
                     <span className='text-sm font-medium'>
                        Choose Students
                     </span>
                  </a>
               </li>

               <li className='md:flex-1'>
                  <a
                     href='#k'
                     className='group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0'
                  >
                     <span className='text-sm font-medium text-gray-500 group-hover:text-gray-700'>
                        Step 3
                     </span>
                     <span className='text-sm font-medium'>Preview</span>
                  </a>
               </li>
            </ol>
         </nav>
         <div className='px-4 sm:px-6 lg:px-8 mt-20'>
            <div className='sm:flex sm:items-center'>
               <div className='sm:flex-auto'>
                  <h1 className='text-xl font-semibold text-gray-900'>
                     Select Students
                  </h1>
               </div>
               <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'></div>
            </div>
            <div className='mt-8 flex flex-col mb-40'>
               <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                  <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                     <div className='relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        {/* <!-- Selected row actions, only show when rows are selected. --> */}
                        <div className='absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16'>
                           <button
                              onClick={handleSubmit}
                              type='button'
                              className='inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                           >
                              Mark Absent
                           </button>
                        </div>

                        <table className='min-w-full table-fixed divide-y divide-gray-300'>
                           <thead className='bg-gray-50'>
                              <tr>
                                 <th
                                    scope='col'
                                    className='relative w-12 px-6 sm:w-16 sm:px-8'
                                 >
                                    <input
                                       type='checkbox'
                                       className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6'
                                    />
                                 </th>
                                 <th
                                    scope='col'
                                    className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Name
                                 </th>
                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Class
                                 </th>
                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Total Absences
                                 </th>
                              </tr>
                           </thead>
                           <tbody className='divide-y divide-gray-200 bg-white'>
                              {/* <!-- Selected: "bg-gray-50" --> */}
                              {registrations &&
                                 registrations.length > 0 &&
                                 registrations
                                    ?.sort((a, b) =>
                                       a?.student?.firstname
                                          ?.toString()
                                          ?.trim()
                                          ?.localeCompare(
                                             b?.student?.firstname
                                                ?.toString()
                                                ?.trim()
                                          )
                                    )
                                    ?.map(registration => (
                                       <tr key={registration?._id}>
                                          <td className='relative w-12 px-6 sm:w-16 sm:px-8'>
                                             {/* <!-- Selected row marker, only show when row is selected. --> */}
                                             {selectedStudents.includes(
                                                registration?._id
                                             ) && (
                                                <div className='absolute inset-y-0 left-0 w-0.5 bg-indigo-600'></div>
                                             )}

                                             <input
                                                type='checkbox'
                                                checked={selectedStudents.includes(
                                                   registration?._id
                                                )}
                                                onChange={() =>
                                                   handleCheck(
                                                      registration?._id
                                                   )
                                                }
                                                className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6'
                                             />
                                          </td>
                                          {/* <!-- Selected: "text-indigo-600", Not Selected: "text-gray-900" --> */}
                                          <td className='whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900'>
                                             {registration?.student
                                                ? `${
                                                     registration.student
                                                        ?.firstname || ''
                                                  }${
                                                     registration.student
                                                        ?.middlename
                                                        ? ' ' +
                                                          registration.student
                                                             .middlename
                                                        : ''
                                                  } ${
                                                     registration.student
                                                        ?.lastname || ''
                                                  }`
                                                : ''}
                                          </td>
                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                             {typeof registration?.schoolClass ===
                                             'object'
                                                ? registration?.schoolClass
                                                     ?.className
                                                : ''}
                                          </td>
                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                             {registration?.absences
                                                ? registration.absences.length
                                                : '0'}
                                          </td>
                                       </tr>
                                    ))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AddAttendanceStep2;
