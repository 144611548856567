import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../../../context/AppContext';
import PageSpinner from '../../UI/PageSpinner';
import useInput from '../../../hooks/useInput';
import { FormEvent, useState } from 'react';
import TailwindDatepicker from '../../UI/TailwindDatepicker';
import {
   TBaptismDate,
   TNewBaptismDate,
} from '../../../types/Baptisms/BaptismDates';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = { isLoading: boolean } & (
   | {
        baptismDate: TBaptismDate;
        submitFn: ([data, id]: [TNewBaptismDate, string]) => any;
     }
   | {
        baptismDate?: null;
        submitFn: (data: TNewBaptismDate) => any;
     }
);

export default function BaptismDateForm({
   isLoading,
   submitFn,
   baptismDate,
}: Props) {
   const navigate = useNavigate();
   const { getStaffs } = useAppContext();
   const [dateObj] = useState(
      baptismDate?.date ? new Date(baptismDate.date) : null
   );

   const [date, setDate] = useState(
      dateObj
         ? dateObj.getFullYear().toString().padStart(4, '0') +
              '-' +
              (dateObj.getMonth() + 1).toString().padStart(2, '0') +
              '-' +
              dateObj.getDate().toString().padStart(2, '0')
         : ''
   );
   const [time, timeAttr] = useInput(
      dateObj
         ? dateObj.toLocaleTimeString('en-us', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
           })
         : ''
   );
   const [officiant, officiantAttr] = useInput(
      baptismDate
         ? typeof baptismDate?.officiant === 'object'
            ? baptismDate?.officiant?._id
            : baptismDate?.officiant
         : ''
   );

   const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (!date || !time || !officiant)
         return toast.error('All fields are required.');

      const isoDate = new Date(date + 'T' + time).toISOString();

      const data = {
         date: isoDate,
         officiant,
      };

      if (baptismDate) {
         submitFn([data, baptismDate._id]);
      } else {
         submitFn(data);
      }
   };

   const { data: priestsAndDeacons, isLoading: isOfficiantLoading } = useQuery({
      queryFn: () =>
         getStaffs({ status: 'Active', role: ['Priest', 'Deacon'] }),
      queryKey: ['staffs', { role: ['Priest', 'Deacon'], status: 'Active' }],
   });

   if (isOfficiantLoading) return <PageSpinner />;

   return (
      <div className='max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto mb-20 mt-10'>
         <form onSubmit={handleSubmit}>
            <div className=''>
               <div className='sm:flex sm:items-center'>
                  <div className='sm:flex-auto'>
                     <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                        Create Baptism
                     </h4>
                  </div>
                  <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'></div>
               </div>
               <div className='mt-8 flow-root'>
                  <div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
                     <div className='py-5'>
                        <label
                           htmlFor='date'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Date
                        </label>
                        <div className='mt-2'>
                           <TailwindDatepicker
                              options={{
                                 defaultDate: baptismDate?.date
                                    ? new Date(baptismDate.date)
                                    : null,
                                 inputIdProp: 'date',
                                 inputPlaceholderProp: 'Select Date',
                              }}
                              inputClassName='bg-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              onChange={date => setDate(date)}
                           />
                        </div>
                        {/* <div className='mt-2'>
                        <input
                           type='date'
                           name='date'
                           id='date'
                           {...dateAttr}
                           className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           placeholder='put datepicker the new one'
                        />
                     </div> */}
                     </div>

                     <div className='py-5'>
                        <label
                           htmlFor='time'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Time
                        </label>
                        <div className='mt-2'>
                           <input
                              type='time'
                              {...timeAttr}
                              name='time'
                              id='time'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              placeholder='put datepicker the new one'
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <label
               htmlFor='officiant'
               className='block text-sm font-medium leading-6 text-gray-900'
            >
               Officiant
            </label>
            <div className='mt-2'>
               <select
                  id='officiant'
                  name='officiant'
                  {...officiantAttr}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
               >
                  <option value=''>Select</option>
                  {priestsAndDeacons?.map(officiant => (
                     <option value={officiant._id} key={officiant._id}>
                        {officiant.firstname} {officiant.lastname}
                     </option>
                  ))}
               </select>
            </div>

            {/* <!-- End Grid --> */}
            <div className='pt-5 flex justify-between mt-10'>
               <div className='flex'>
                  {/* {funeral?._id != null && (
                        <button
                           className='disabled:bg-gray-400 disabled:cursor-wait disabled:hover:bg-gray-400 ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2'
                           type='button'
                           onClick={() => setOpenDeleteModal(true)}
                           disabled={funeralDeleteMutation.isLoading}
                        >
                           {funeralDeleteMutation.isLoading
                              ? 'Deleting...'
                              : 'Delete Funeral'}
                        </button>
                     )} */}
               </div>
               <div className='flex justify-end'>
                  <button
                     type='button'
                     onClick={() => navigate(-1)}
                     className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  >
                     Cancel
                  </button>
                  <button
                     type='submit'
                     disabled={isLoading}
                     className='disabled:bg-gray-400 disabled:hover:bg-gray-400 disabled:cursor-wait ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  >
                     Save
                  </button>
               </div>
            </div>
         </form>
      </div>
   );
}
