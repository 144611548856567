import { getZoneDate } from '../../../../utils/dateFunctions';
import { useMemo } from 'react';

const AddAttendanceStep3 = ({
   setStep,
   selectedStudents,
   selectedDate,
   allRegistrations: registrations,
   addAttendanceFn,
}) => {
   const selectedRegistrations = useMemo(
      () =>
         registrations?.filter(registration =>
            selectedStudents?.includes(registration?._id)
         ),
      [registrations, selectedStudents]
   );
   return (
      <div className='max-w-7xl xl:w-3/5 xl:min-w-0 lg:min-w-3/4 md:min-w-4/5 sm:min-w-5/6 sm:w-auto w-full mx-auto px-4 sm:px-6 lg:px-8'>
         <nav aria-label='Progress' className='mt-20'>
            <ol className='space-y-4 md:flex md:space-y-0 md:space-x-8'>
               <li className='md:flex-1'>
                  <button
                     onClick={() => setStep(1)}
                     type='button'
                     className='group w-full flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0'
                  >
                     <span className='text-sm font-medium text-indigo-600 group-hover:text-indigo-800'>
                        Step 1
                     </span>
                     <span className='text-sm font-medium'>Choose Date</span>
                  </button>
               </li>

               <li className='md:flex-1'>
                  <button
                     onClick={() => setStep(2)}
                     type='button'
                     className='group w-full flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0'
                     aria-current='step'
                  >
                     <span className='text-sm font-medium text-indigo-600 group-hover:text-indigo-800'>
                        Step 2
                     </span>
                     <span className='text-sm font-medium'>
                        Choose Students
                     </span>
                  </button>
               </li>

               <li className='md:flex-1'>
                  <a
                     href='#k'
                     className='flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0'
                  >
                     <span className='text-sm font-medium text-indigo-600'>
                        Step 3
                     </span>
                     <span className='text-sm font-medium'>Preview</span>
                  </a>
               </li>
            </ol>
         </nav>
         <div className='px-4 sm:px-6 lg:px-8 mt-20 mb-40'>
            {/* <!--confirm students absent--> */}
            <div className='sm:flex sm:items-center'>
               <div className='sm:flex-auto'>
                  <h1 className='text-xl font-semibold text-gray-900'>
                     Confirm Absent Students
                  </h1>
               </div>
               <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
                  <button
                     type='button'
                     onClick={addAttendanceFn}
                     className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
                  >
                     Confirm
                  </button>
               </div>
            </div>
            <div className='mt-8 flex flex-col'>
               <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                  <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                     <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                           <thead className='bg-gray-50'>
                              <tr>
                                 <th
                                    scope='col'
                                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                                 >
                                    Name
                                 </th>
                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Class
                                 </th>
                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Date
                                 </th>
                              </tr>
                           </thead>
                           <tbody className='divide-y divide-gray-200 bg-white'>
                              {selectedRegistrations &&
                                 selectedRegistrations.length > 0 &&
                                 selectedRegistrations
                                    ?.sort((a, b) =>
                                       a?.student?.firstname
                                          ?.toString()
                                          ?.trim()
                                          ?.localeCompare(
                                             b?.student?.firstname
                                                ?.toString()
                                                ?.trim()
                                          )
                                    )
                                    ?.map(registration => (
                                       <tr key={registration?._id}>
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                             {registration?.student
                                                ? `${
                                                     registration.student
                                                        ?.firstname || ''
                                                  }${
                                                     registration.student
                                                        ?.middlename
                                                        ? ' ' +
                                                          registration.student
                                                             .middlename
                                                        : ''
                                                  } ${
                                                     registration.student
                                                        ?.lastname || ''
                                                  }`
                                                : ''}
                                          </td>
                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                             {registration?.schoolClass
                                                ?.className || ''}
                                          </td>
                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                             {getZoneDate(
                                                selectedDate
                                             ).toLocaleDateString('en-us', {
                                                month: '2-digit',
                                                day: '2-digit',
                                                year: 'numeric',
                                             })}
                                          </td>
                                       </tr>
                                    ))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AddAttendanceStep3;
