import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTeacherContext } from '../../../context/ReligiousEducation/TeacherContext';
import PageSpinner from '../../../components/UI/PageSpinner';
import NotFound from '../../../components/NotFound';
import { useParams } from 'react-router-dom';
import EditSacramentsForm from '../../../components/ReligiousEducation/Forms/Student/EditSacramentForm';

export default function EditSacraments() {
   const { studentId } = useParams();
   const { getParents, editStudent, getStudent } = useTeacherContext();
   const queryClient = useQueryClient();

   const parentsQuery = useQuery({
      queryFn: getParents,
      queryKey: ['parents'],
   });

   const { isLoading: isStudentLoading, data: student } = useQuery({
      queryKey: ['students', studentId],
      queryFn: () => getStudent(studentId),
   });

   const studentMutation = useMutation({
      mutationFn: ([id, formdata]: [string, FormData]) =>
         editStudent(id, formdata),
      onSuccess: student => {
         queryClient.invalidateQueries(['students', student._id]);
         queryClient.invalidateQueries(['registrations']);
         queryClient.invalidateQueries(['registrations', 'all']);
      },
      onError: () => {},
   });

   if (parentsQuery.isLoading || isStudentLoading) return <PageSpinner />;

   if (!parentsQuery.data || !student) return <NotFound />;

   return (
      <EditSacramentsForm
         student={student}
         editStudentFn={(id: string, formdata: FormData) =>
            studentMutation.mutateAsync([id, formdata])
         }
         studentLoader={studentMutation.isLoading}
      />
   );
}
