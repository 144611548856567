import { createContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { PRIESTS_API } from '../utils/constants';

const PriestContext = createContext({});

export const PriestProvider = () => {
   const [loading, setLoading] = useState(false);
   const [priests, setPriests] = useState([]);
   const [selectedPriests, setSelectedPriests] = useState('');
   const axiosPrivate = useAxiosPrivate();

   const getPriests = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(PRIESTS_API);
         setPriests(data);
      } catch (err) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const addPriest = async data => {
      try {
         setLoading(true);
         const res = await axiosPrivate.post(PRIESTS_API, data);
         setPriests(prev => [res.data, ...prev]);
         return undefined;
      } catch (err) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const editPriest = async (data, id) => {
      if (!id) throw new Error('Priest ID is required to delete a priest!');
      try {
         setLoading(true);
         const res = await axiosPrivate.patch(`${PRIESTS_API}/${id}`, data);
         setPriests(prev =>
            prev.map(priest => (priest._id === id ? res.data : priest))
         );
      } catch (err) {
         if (!err.response)
            throw new Error(
               'No server response! Check your internet connection.'
            );
         throw new Error(err?.response?.data?.message || false);
      } finally {
         setLoading(false);
      }
   };

   const selectPriest = id => {
      setSelectedPriests(prev => {
         if (prev === id) {
            return '';
         }
         return id;
      });
   };
   // const selectPriest = (id) => {
   //    setSelectedPriests((prev) => {
   //       if (prev.includes(id)) {
   //          return prev.filter((pid) => pid !== id);
   //       }
   //       return [...prev, id];
   //    });
   // };

   useEffect(() => {
      let mounted = true;
      if (mounted) {
         getPriests();
      }

      return () => (mounted = false);
      //eslint-disable-next-line
   }, []);

   return (
      <PriestContext.Provider
         value={{
            priests,
            setPriests,
            selectedPriests,
            selectPriest,
            loading,
            addPriest,
            editPriest,
         }}
      >
         <Outlet />
      </PriestContext.Provider>
   );
};

export default PriestContext;
