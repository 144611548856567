import { ReactNode, createContext, useContext } from 'react';
import { TTeacherContext } from '../../types/ReligiousEducation/TeacherContext';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
   PARENTS_API,
   REGISTRATIONS_API,
   SCHOOL_CLASSES_API,
   SCHOOL_YEARS_API,
   STUDENTS_API,
   TEACHERS_API,
} from '../../utils/constants';
import { saveAs } from 'file-saver';

const TeacherContext = createContext<TTeacherContext | null>(null);

export const TeacherProvider = ({ children }: { children?: ReactNode }) => {
   const axiosPrivate = useAxiosPrivate();

   const getClasses = () =>
      axiosPrivate
         .get(`${TEACHERS_API}/getClassesByTeacher`)
         .then(res => res.data);

   const getCurrentSchoolYear = () =>
      axiosPrivate
         .get(`${SCHOOL_YEARS_API}/current-year`)
         .then(res => res.data);

   const getSchoolYears = () =>
      axiosPrivate.get(SCHOOL_YEARS_API).then(res => res.data);

   const downloadClassRosterXLSX: TTeacherContext['downloadClassRosterXLSX'] =
      async ({ classId, className, yearId, yearName }) => {
         const res = await axiosPrivate.get(
            `${SCHOOL_CLASSES_API}/exportAsXLSX/students`,
            {
               responseType: 'blob',
               params: { classId, yearId },
            }
         );
         saveAs(res.data, `${className}${yearName ? `_${yearName}` : ''}.xlsx`);
      };

   const getCurrentRegistrationsByClass: TTeacherContext['getCurrentRegistrationsByClass'] =
      classId =>
         axiosPrivate
            .get(`${TEACHERS_API}/currentRegsByClass`, { params: { classId } })
            .then(res => res.data);

   const getCurrentRegistrations: TTeacherContext['getCurrentRegistrations'] =
      yearId =>
         axiosPrivate
            .get(`${TEACHERS_API}/currentRegs`, { params: { yearId } })
            .then(res => res.data);

   const getStudent: TTeacherContext['getStudent'] = id =>
      axiosPrivate
         .get(`${STUDENTS_API}/${id}`, { params: { populate: 'all' } })
         .then(res => res.data);

   const getParents = () => axiosPrivate.get(PARENTS_API).then(res => res.data);

   const editStudent: TTeacherContext['editStudent'] = async (id, formdata) => {
      if (!id) return { message: 'Student ID is required!' };

      const res = await axiosPrivate.patch(`${STUDENTS_API}/${id}`, formdata, {
         headers: { 'Content-Type': 'multipart/form-data' },
      });

      return res.data;
   };

   const addAttendance: TTeacherContext['addAttendance'] = (date, absences) =>
      axiosPrivate
         .post(`${REGISTRATIONS_API}/attendance`, {
            date,
            absences,
         })
         .then(res => res.data);

   return (
      <TeacherContext.Provider
         value={{
            getClasses,
            getCurrentSchoolYear,
            getSchoolYears,
            downloadClassRosterXLSX,
            getCurrentRegistrationsByClass,
            getStudent,
            getParents,
            editStudent,
            getCurrentRegistrations,
            addAttendance,
         }}
      >
         {children}
      </TeacherContext.Provider>
   );
};

export default TeacherContext;

export const useTeacherContext = () => {
   const context = useContext(TeacherContext);
   if (!context) {
      throw new Error(
         'useTeacherContext must be used within a TeacherProvider'
      );
   }

   return context;
};
