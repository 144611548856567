import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useURLState(fieldName: string, initialValue?: string) {
   const [searchParams, setSearchParams] = useSearchParams();

   const initialValueRef = useRef(initialValue);

   const value = searchParams.get(fieldName)
      ? decodeURIComponent(searchParams.get(fieldName) ?? '')
      : '';

   useEffect(() => {
      if (initialValueRef.current && !value)
         setSearchParams(
            prev => {
               prev.set(
                  fieldName,
                  encodeURIComponent(initialValueRef.current?.trim()!)
               );
               return prev;
            },
            { replace: true }
         );
      initialValueRef.current = '';
   }, [setSearchParams, fieldName, value]);

   const setValue = useCallback(
      (newValue: string) => {
         setSearchParams(
            prev => {
               if (!newValue) {
                  prev.delete(fieldName);
                  return prev;
               }
               prev.set(fieldName, encodeURIComponent(newValue));
               return prev;
            },
            { replace: true }
         );
      },
      [fieldName, setSearchParams]
   );

   return useMemo(() => [value, setValue] as const, [value, setValue]);
}
