import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { AUTH_API } from '../utils/constants';
import useAuth from './useAuth';

interface ILogoutOptions {
   navigateTo?: string | null;
}

const useLogout = () => {
   const { setAuth } = useAuth();
   const navigate = useNavigate();

   const logout = async ({ navigateTo }: ILogoutOptions = {}) => {
      try {
         await axios.delete(`${AUTH_API}/logout`, {
            withCredentials: true,
         });
         setAuth({});
         navigate(navigateTo ?? '/login', { replace: true });
      } catch (err) {
         console.error(err);
      }
   };

   return logout;
};

export default useLogout;
