import { useMutation, useQueryClient } from '@tanstack/react-query';
import BaptismDateForm from '../../../components/Baptisms/Forms/BaptismDateForm';
import BreadCrumb from '../../../components/UI/BreadCrumb';
import useBaptismContext from '../../../context/Baptisms/BaptismContext';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AddBaptismDate() {
   const { addBaptismDate } = useBaptismContext();
   const queryClient = useQueryClient();
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();

   const from = searchParams.get('from');

   const baptismDateMutation = useMutation({
      mutationFn: addBaptismDate,
      onSuccess: () => {
         queryClient.invalidateQueries(['baptismDates']);
         toast.success('Baptism Date addded successfully.');
         navigate(from ?? '/baptisms', { replace: true });
      },
   });

   return (
      <main className='flex-1'>
         {/* <!-- BREADCRUMBS --> */}
         <BreadCrumb
            homeLink='/dashboard'
            items={[
               { link: '/baptisms', title: 'Baptisms' },
               {
                  title: 'Add Baptism Date',
                  active: true,
               },
            ]}
         />
         <BaptismDateForm
            submitFn={baptismDateMutation.mutate}
            isLoading={baptismDateMutation.isLoading}
         />
      </main>
   );
}
