import React from 'react';
import useAuth from '../../hooks/useAuth';
import jwtDecode from 'jwt-decode';

const TrialAlert = ({ children }) => {
   const { auth } = useAuth();

   const decoded = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
   const subscriptions = decoded?.UserInfo?.subscribedTo || [];

   const trialSubscription = subscriptions.filter(
      subscription =>
         subscription?.status?.toLowerCase() === 'trialing' ||
         subscription?.status?.toLowerCase() === 'free_trial'
   );

   const isFreeTrial = subscriptions.some(
      subscription => subscription?.status?.toLowerCase() === 'free_trial'
   );

   if (trialSubscription.length < 1) return children;

   const daysToEndTrial = Math.ceil(
      (trialSubscription[0]?.trial_end * 1000 - Date.now()) / (1000 * 3600 * 24)
   );

   return (
      <>
         <div className='rounded-md bg-blue-50 p-4 sticky top-16 lg:top-0 z-10'>
            <div className='flex'>
               <div className='flex-shrink-0'>
                  {/* <!-- Heroicon name: mini/information-circle --> */}

                  <svg
                     className='h-5 w-5 text-blue-400'
                     xmlns='http://www.w3.org/2000/svg'
                     viewBox='0 0 20 20'
                     fill='currentColor'
                     aria-hidden='true'
                  >
                     <path
                        fillRule='evenodd'
                        d='M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z'
                        clipRule='evenodd'
                     />
                  </svg>
               </div>

               <div className='ml-3 flex-1 md:flex md:justify-between'>
                  <p className='text-sm text-blue-700'>
                     You are currently enjoying a free trial of Sacramatic.{' '}
                     {!isFreeTrial &&
                        `Your
                     trial ends in ${daysToEndTrial} days.`}
                  </p>

                  <p className='mt-3 text-sm md:mt-0 md:ml-6'>
                     <a
                        href='#k'
                        className='whitespace-nowrap font-medium text-blue-700 hover:text-blue-600'
                     >
                        Details
                        <span aria-hidden='true'> &rarr;</span>
                     </a>
                  </p>
               </div>
            </div>
         </div>
         {children}
      </>
   );
};

export default TrialAlert;
