// import { useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { TRegistrationResponse } from '../../../../types/ReligiousEducation/Registration';
import { calcAge } from '../../../../utils/dateFunctions';

type Props = { registrations: TRegistrationResponse[] };

export default function ClassStudentsTable({ registrations }: Props) {
   const navigate = useNavigate();

   return (
      <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
         <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
               <tr>
                  <th
                     scope='col'
                     className={`cursor-pointer px-3 py-3.5 text-left text-sm font-semibold text-gray-900`}
                  >
                     Name
                  </th>
                  <th
                     scope='col'
                     className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                     Age
                  </th>
                  <th
                     scope='col'
                     className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                     Baptized
                  </th>
                  <th
                     scope='col'
                     className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                     First Communion
                  </th>
                  <th
                     scope='col'
                     className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                     Missed Classes
                  </th>
               </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
               {/* <!-- Odd row --> */}
               {(registrations ?? []).map((registration, index) => (
                  <tr
                     className='hover:bg-gray-100'
                     key={registration?._id || index}
                  >
                     <td
                        className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 cursor-pointer'
                        onClick={() =>
                           navigate(
                              `/re/teacher/students/${registration?.student?._id}`
                           )
                        }
                     >
                        {`${registration?.student?.firstname || ''}${
                           registration?.student?.middlename
                              ? ' ' + registration?.student.middlename
                              : ''
                        } ${registration?.student?.lastname || ''}`}
                     </td>
                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {calcAge(registration?.student?.dateOfBirth ?? '')}
                     </td>
                     {!registration?.student?.baptized ||
                     (registration?.student?.baptized &&
                        registration?.student?.baptized.toLowerCase() ===
                           'no') ? (
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-red-500'>
                           <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='h-6 w-6'
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                              strokeWidth='2'
                           >
                              <path
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                                 d='M6 18L18 6M6 6l12 12'
                              />
                           </svg>
                        </td>
                     ) : (
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-green-500'>
                           <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='h-6 w-6'
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                              strokeWidth='2'
                           >
                              <path
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                                 d='M5 13l4 4L19 7'
                              />
                           </svg>
                        </td>
                     )}
                     {registration?.student?.firstCommunion &&
                     registration.student.firstCommunion.toLowerCase() ===
                        'yes' ? (
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-green-500'>
                           <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='h-6 w-6'
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                              strokeWidth='2'
                           >
                              <path
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                                 d='M5 13l4 4L19 7'
                              />
                           </svg>
                        </td>
                     ) : (
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-red-500'>
                           <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='h-6 w-6'
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                              strokeWidth='2'
                           >
                              <path
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                                 d='M6 18L18 6M6 6l12 12'
                              />
                           </svg>
                        </td>
                     )}
                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {registration?.absences && registration.absences.length}
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
}
