import React, { useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

export default function BirthCertInput({ setFile }) {
   const onDrop = useCallback(
      acceptedFiles => {
         const file = acceptedFiles[0];
         if (!file) return toast.info('Selected file is not valid!');
         if (
            file.type.toLowerCase() !== 'application/pdf' &&
            file.type.toLowerCase() !== '.pdf' &&
            file.type.toLowerCase() !== 'pdf'
         )
            return toast.info('Unsupported file format! Only PDF is accepted.');
         if (file.size > 20971520)
            return toast.error('File is too large! Up to 20MB is accepted.');
         setFile(file);
      },
      [setFile]
   );

   const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple: false,
      accept: { 'application/pdf': ['.pdf'] },
   });

   const buttonRef = useRef(null);

   useEffect(() => {
      if (!buttonRef?.current) return;
      isDragActive ? buttonRef.current.focus() : buttonRef.current.blur();
   }, [isDragActive]);

   return (
      <div className='sm:col-span-6'>
         <label
            htmlFor='birthCertificate'
            className='block text-sm font-medium text-gray-700'
         >
            Birth Certificate
         </label>
         <button
            type='button'
            className='mt-1 w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'
            {...getRootProps()}
            ref={buttonRef}
         >
            <div className='space-y-1 text-center'>
               <svg
                  className='mx-auto h-12 w-12 text-gray-400'
                  stroke='currentColor'
                  fill='none'
                  viewBox='0 0 48 48'
                  aria-hidden='true'
               >
                  <path
                     d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                     strokeWidth='2'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>
               <div className='flex text-sm text-gray-600'>
                  <label
                     htmlFor='birthCertificate'
                     className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                  >
                     <span>Upload a file</span>
                     <input
                        id='birthCertificate'
                        {...getInputProps()}
                        className='sr-only'
                     />
                  </label>
                  <p className='pl-1'>or drag and drop</p>
               </div>
               <p className='text-xs text-gray-500'>PDF file up to 20MB</p>
            </div>
         </button>
      </div>
   );
}
