import React from 'react';

const Suspense = ({ children }) => {
   return (
      <React.Suspense
         fallback={
            <div
               className='flex items-center justify-center w-full'
               style={{ height: '80vh' }}
            >
               <div className='w-24 h-24 border-l-2 border-t-2 border-gray-900 rounded-full animate-spin'></div>
            </div>
         }
      >
         {children}
      </React.Suspense>
   );
};

export default Suspense;
