import { Fragment, lazy, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
const PriestSelectionForWedding = lazy(() =>
   import('./PriestSelectionForWedding')
);

const SidebarItemsComp = ({
   primarySidebarItems,
   setSidebarOpen,
   mobile,
   showPriest = false,
}) => {
   return !mobile ? (
      <nav className='px-3 mt-6 mb-40'>
         {/*  Current: "bg-gray-200 text-gray-900", Default: "text-gray-700 hover:text-gray-900 hover:bg-gray-50"  */}
         {primarySidebarItems.map((item, index) => (
            <Fragment key={item?.items ? index : item.name}>
               {!item.items ? (
                  <NavLink
                     key={item.name}
                     to={item.href}
                     className={({ isActive }) =>
                        isActive
                           ? 'bg-gray-500 text-gray-900 flex items-center px-2 py-2 text-sm font-medium rounded-md mb-1'
                           : 'text-gray-400 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md mb-1'
                     }
                     end={item.end && item.end}
                  >
                     {({ isActive }) => (
                        <>
                           <item.svg
                              className={
                                 isActive
                                    ? 'text-gray-900 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                                    : 'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                              }
                           />
                           {item.name}
                        </>
                     )}
                  </NavLink>
               ) : (
                  <div className='mt-10' key={item.title}>
                     <h3
                        className='px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider'
                        id='desktop-teams-headline'
                     >
                        {item.title}
                     </h3>
                     <div
                        className='mt-1 space-y-1'
                        role='group'
                        aria-labelledby='desktop-teams-headline'
                     >
                        {item.items.map(innerItem => (
                           <NavLink
                              key={innerItem.name}
                              to={innerItem.href}
                              className={({ isActive }) =>
                                 isActive
                                    ? 'bg-gray-500 text-gray-900 flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    : 'text-gray-400 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                              }
                           >
                              {({ isActive }) => (
                                 <>
                                    {/* 
                              Heroicon name: outline/home
                              Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500"
                              */}
                                    <innerItem.svg
                                       className={
                                          isActive
                                             ? 'text-gray-900 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                                             : 'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                                       }
                                    />
                                    {innerItem.name}
                                 </>
                              )}
                           </NavLink>
                        ))}
                     </div>
                  </div>
               )}
            </Fragment>
         ))}
         <Suspense>
            {showPriest && <PriestSelectionForWedding mobile />}
         </Suspense>
      </nav>
   ) : (
      <nav className='px-2'>
         {/*  Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:text-gray-900 hover:bg-gray-50"  */}
         {primarySidebarItems.map((item, index) => (
            <Fragment key={item?.items ? index : item.name}>
               {!item.items ? (
                  <NavLink
                     key={item.name}
                     to={item.href}
                     onClick={() => setSidebarOpen(false)}
                     className={({ isActive }) =>
                        isActive
                           ? 'bg-gray-100 text-gray-900 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md mb-1 '
                           : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md mb-1'
                     }
                     end={item.end && item.end}
                  >
                     {({ isActive }) => (
                        <>
                           <item.svg
                              className={
                                 isActive
                                    ? 'text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                                    : 'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                              }
                           />
                           {item.name}
                        </>
                     )}
                  </NavLink>
               ) : (
                  <div className='mt-8' key={item.title}>
                     <h3
                        className='px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider'
                        id='desktop-teams-headline'
                     >
                        {item.title}
                     </h3>
                     <div
                        className='mt-1 space-y-1'
                        role='group'
                        aria-labelledby='desktop-teams-headline'
                     >
                        {item.items.map(innerItem => (
                           <NavLink
                              key={innerItem.name}
                              to={innerItem.href}
                              onClick={() => setSidebarOpen(false)}
                              className={({ isActive }) =>
                                 isActive
                                    ? 'bg-gray-100 text-gray-900 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md'
                                    : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md'
                              }
                           >
                              {({ isActive }) => (
                                 <>
                                    <innerItem.svg
                                       className={
                                          isActive
                                             ? 'text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                                             : 'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                                       }
                                    />
                                    {innerItem.name}
                                 </>
                              )}
                           </NavLink>
                        ))}
                     </div>
                  </div>
               )}
            </Fragment>
         ))}
         <Suspense>{showPriest && <PriestSelectionForWedding />}</Suspense>
      </nav>
   );
};

export default SidebarItemsComp;
