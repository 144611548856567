import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

type TAcceptedTypes =
   | 'pdf'
   | 'png'
   | 'jpeg'
   | 'jpg'
   | 'image'
   | 'xlsx'
   | 'xls'
   | 'any';

type TOptions = {
   type?: TAcceptedTypes[];
   maxSizeInMB?: number;
   multiple?: boolean;
   maxFiles?: number;
};

export default function useFileInput(
   initialFile: File | File[] | {} | null,
   {
      type = ['pdf'],
      maxSizeInMB = 20,
      multiple = false,
      maxFiles,
   }: TOptions = {}
) {
   const [file, setFile] = useState(initialFile);

   const onDrop = useCallback(
      (acceptedFiles: File[]) => {
         if (!multiple) {
            const file = acceptedFiles[0];
            if (file.size > Number(maxSizeInMB) * 1024 * 1024)
               return toast.error(
                  `File is too large! Up to ${maxSizeInMB}MB is accepted.`
               );
            setFile(file);
         } else {
            const files = acceptedFiles;

            const totalSize = files.reduce((acc, file) => {
               acc += file.size;
               return acc;
            }, 0);

            if (totalSize > Number(maxSizeInMB) * 1024 * 1024)
               return toast.error(
                  `Selected files exceed the size limit. Up to ${maxSizeInMB}MB is accepted.`
               );

            setFile(files as File[]);
         }
      },
      [setFile, maxSizeInMB, multiple]
   );

   const fileOptions = useDropzone({
      onDrop,
      multiple,
      onDropRejected(fileRejections) {
         if (fileRejections?.[0]?.errors?.[0]?.code === 'too-many-files') {
            toast.error(
               `Too many files. Up to ${maxFiles} files can be selected.`
            );
         } else if (fileRejections?.[0]?.errors?.[0]?.message) {
            toast.error(fileRejections[0].errors[0].message);
         }
      },
      ...(maxFiles ? { maxFiles } : {}),
      ...(!type.includes('any') && {
         accept: {
            ...(type.includes('pdf') && { 'application/pdf': ['.pdf'] }),
            ...(type.includes('png') && { 'image/png': ['.png'] }),
            ...((type.includes('jpeg') || type.includes('jpg')) && {
               'image/jpeg': ['.jpg', '.jpeg'],
            }),
            ...(type.includes('xlsx') && {
               'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx'],
            }),
            ...(type.includes('xls') && {
               'application/vnd.ms-excel': ['.xls'],
            }),
            ...(type.includes('image') && { 'image/*': [] }),
         },
      }),
   });

   return [file, fileOptions, setFile] as const;
}
