import { useAppContext } from '../../context/AppContext';
import { useQuery } from '@tanstack/react-query';
import PageSpinner from '../../components/UI/PageSpinner';
import { TVideoTraining } from '../../types/VideoTraining';
import { useMemo, useState } from 'react';
import FilterDropdown from '../../components/UI/FilterDropdown';

export default function VideoTrainings() {
   const { getVideoTrainings } = useAppContext();

   const [moduleFilter, setModuleFilter] = useState('');

   const { isLoading, data } = useQuery({
      queryKey: ['videoTrainings'],
      queryFn: getVideoTrainings,
   });

   const filteredTrainings = useMemo(
      () =>
         !moduleFilter
            ? data ?? []
            : (data ?? []).filter(training => training.module === moduleFilter),
      [data, moduleFilter]
   );

   return (
      <>
         <main className='flex-1 mt-10'>
            {/* <!-- Page title & actions --> */}
            <div className='border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8'>
               <div className='flex-1 min-w-0'>
                  <h1 className='text-lg font-medium leading-6 text-gray-900 sm:truncate'>
                     Video Training Library
                  </h1>
                  <div className='sm:flex-auto'>
                     <p className='mt-2 text-sm text-gray-700'>
                        Here you will find training videos on how to use
                        Sacramatic.
                     </p>
                  </div>
               </div>

               <div>
                  <FilterDropdown
                     items={[
                        '',
                        'Baptisms',
                        'Funerals',
                        'Religious Education',
                        'Weddings',
                        'Settings',
                     ]}
                     selectedState={[moduleFilter, setModuleFilter]}
                     defaultValue='Module'
                  />
               </div>
            </div>

            {/* <!-- Pinned projects --> */}

            {/* <!--table--> */}
            {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
            <div className='px-4 sm:px-6 lg:px-8'>
               <div className='mt-8 flex flex-col'>
                  <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                     <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                        <TrainingTable
                           isLoading={isLoading}
                           data={filteredTrainings}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </main>

         <footer className='bg-white mt-20'>
            <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8'></div>
         </footer>
      </>
   );
}

type TTableProps = {
   isLoading: boolean;
   data: TVideoTraining[];
};

function TrainingTable({ isLoading, data }: TTableProps) {
   return isLoading ? (
      <PageSpinner />
   ) : (
      <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
         <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
               <tr>
                  <th
                     scope='col'
                     className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                  >
                     Title
                  </th>
                  <th
                     scope='col'
                     className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                     Category
                  </th>
                  <th
                     scope='col'
                     className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                     Play Video
                  </th>
               </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
               {data.map(training => (
                  <tr key={training._id}>
                     <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                        {training.title}
                     </td>
                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {training.category}
                     </td>
                     <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {training?.videoUrl ? (
                           <a
                              href={training.videoUrl}
                              target='_blank'
                              rel='noreferrer'
                           >
                              <svg
                                 xmlns='http://www.w3.org/2000/svg'
                                 fill='none'
                                 viewBox='0 0 24 24'
                                 strokeWidth='1.5'
                                 stroke='currentColor'
                                 className='h-6 w-6'
                              >
                                 <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25'
                                 />
                              </svg>
                           </a>
                        ) : null}
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
}
