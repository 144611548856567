import { useQuery } from '@tanstack/react-query';
import NotesReportComp from '../../../components/ReligiousEducation/Reports/Notes/NotesReportComp';
import { useTeacherContext } from '../../../context/ReligiousEducation/TeacherContext';
import PageSpinner from '../../../components/UI/PageSpinner';
import NotFound from '../../../components/NotFound';

export default function NotesReport() {
   const { getCurrentSchoolYear, getClasses, getCurrentRegistrations } =
      useTeacherContext();

   const { isLoading: isYearLoading, data: currentSchoolYear } = useQuery({
      queryFn: getCurrentSchoolYear,
      queryKey: ['currentYear'],
   });

   const { isLoading: isClassLoading, data: schoolClasses } = useQuery({
      queryKey: ['classes'],
      queryFn: getClasses,
   });

   const { isLoading: isRegistrationsLoading, data: registrations } = useQuery({
      queryKey: ['registrations', 'all', currentSchoolYear?._id],
      queryFn: () => getCurrentRegistrations(currentSchoolYear?._id),
      enabled: !isYearLoading,
   });

   if (isYearLoading || isClassLoading || isRegistrationsLoading)
      return <PageSpinner />;

   if (!currentSchoolYear || !schoolClasses || !registrations)
      return <NotFound />;

   return (
      <NotesReportComp
         schoolClasses={schoolClasses}
         registrations={registrations}
      />
   );
}
