import { Route, Routes } from 'react-router-dom';
import GiftStoreDashboard from './Dashboard/GiftStoreDashboard';

export default function GiftStoreRoutes() {
   return (
      <Routes>
         <Route path='/' element={<GiftStoreDashboard />} />
      </Routes>
   );
}
