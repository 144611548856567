import { Fragment, Suspense, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import useSearch from '../../hooks/useSearch';
import { Transition } from '@headlessui/react';
import { LibraryIcon, MenuAlt1Icon, XIcon } from '@heroicons/react/outline';
import logo_dark from '../../assets/logo-dark.svg';
import logoSvg from '../../assets/Artboard_1.svg';
import { SearchIcon } from '@heroicons/react/solid';
import SidebarItemsComp from '../../Layouts/Common/SidebarItemsComp';
import TeacherProfileDropdown from '../../Layouts/Common/TeacherProfileDropdown';
import { SearchProvider } from '../../context/SidebarSearchContext';
import Classes from './Classes/Classes';
import { TeacherProvider } from '../../context/ReligiousEducation/TeacherContext';
import ClassDetails from './Classes/ClassDetails';
import NotFound from '../../components/NotFound';
import StudentRecord from './Students/StudentRecord';
import EditStudent from './Students/EditStudent';
import { NewDocumentIcon } from '../../Layouts/Dashboard/SidebarItems';
import NotesReport from './Reports/NotesReport';
import EditSacraments from './Students/EditSacraments';
import BaptismReport from './Reports/BaptismReport';
import MissingDocs from './Reports/MissingDocs';
import Attendance from './Reports/Attendance';
import AddAttendance from './Reports/AddAttendance';

export default function TeacherDashboardRoutes() {
   return (
      <TeacherProvider>
         <Routes>
            <Route element={<SearchProvider />}>
               <Route element={<Layout />}>
                  <Route index element={<Navigate to='classes' replace />} />
                  <Route path='/classes' element={<Classes />} />
                  <Route
                     path='/classes/:className'
                     element={<ClassDetails />}
                  />
                  <Route
                     path='/students/:studentId'
                     element={<StudentRecord />}
                  />
                  <Route
                     path='/students/:studentId/edit'
                     element={<EditStudent />}
                  />
                  <Route
                     path='/students/:studentId/edit-sacraments'
                     element={<EditSacraments />}
                  />
                  <Route path='reports/attendance' element={<Attendance />} />
                  <Route path='add-attendance' element={<AddAttendance />} />
                  <Route
                     path='reports/notes-report'
                     element={<NotesReport />}
                  />
                  <Route
                     path='reports/baptism-report'
                     element={<BaptismReport />}
                  />
                  <Route
                     path='reports/missing-docs'
                     element={<MissingDocs />}
                  />
                  <Route path='/*' element={<NotFound />} />
               </Route>
            </Route>
         </Routes>
      </TeacherProvider>
   );
}

function Layout() {
   const [mSidebarOpen, setMSidebarOpen] = useState(false);

   return (
      <div className='min-h-full'>
         {/*  Off-canvas menu for mobile, show/hide based on off-canvas menu state.  */}
         <Sidebar sidebarOpen={mSidebarOpen} setSidebarOpen={setMSidebarOpen} />
         <MobileHeader setSidebar={setMSidebarOpen} />

         {/*  Main column  */}
         <div className='lg:pl-64 flex flex-col'>
            <Suspense>
               <Outlet />
            </Suspense>
         </div>
      </div>
   );
}

const primarySidebarItems = [
   {
      name: 'Classes',
      href: '/re/teacher/classes',
      svg: LibraryIcon,
      end: true,
   },
   {
      title: 'REPORTS',
      type: 'header',
      items: [
         {
            name: 'Attendance',
            href: '/re/teacher/reports/attendance',
            svg: NewDocumentIcon,
         },
         {
            name: 'Baptism Report',
            href: '/re/teacher/reports/baptism-report',
            svg: NewDocumentIcon,
         },
         {
            name: 'Notes Report',
            href: '/re/teacher/reports/notes-report',
            svg: NewDocumentIcon,
         },
         {
            name: 'Missing Docs Report',
            href: '/re/teacher/reports/missing-docs',
            svg: NewDocumentIcon,
         },
      ],
   },
];

function Sidebar({
   sidebarOpen,
   setSidebarOpen,
}: {
   sidebarOpen: boolean;
   setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
   const { searchInputAttr } = useSearch();
   const navigate = useNavigate();

   const navigateToDashboard = () => {
      navigate('/dashboard');
   };

   return (
      <>
         <div
            className='relative z-50 lg:hidden'
            role='dialog'
            aria-modal='true'
         >
            <div>
               <Transition
                  show={sidebarOpen}
                  as={Fragment}
                  enter='transition-opacity ease-linear duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='transition-opacity ease-linear duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
               >
                  <div className='fixed inset-0 bg-gray-600 bg-opacity-75'></div>
               </Transition>

               <Transition
                  show={sidebarOpen}
                  as={Fragment}
                  enter='transition ease-in-out duration-300 transform'
                  enterFrom='-translate-x-full'
                  enterTo='translate-x-0'
                  leave='transition ease-in-out duration-300 transform'
                  leaveFrom='translate-x-0'
                  leaveTo='-translate-x-full'
               >
                  <div className='fixed inset-0 flex z-40'>
                     <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white'>
                        <Transition
                           show={sidebarOpen}
                           as={Fragment}
                           enter='transition-opacity ease-in-out duration-300'
                           enterFrom='opacity-0'
                           enterTo='opacity-100'
                           leave='transition-opacity ease-in-out duration-300'
                           leaveFrom='opacity-100'
                           leaveTo='opacity-0'
                        >
                           <div className='absolute top-0 right-0 -mr-12 pt-2'>
                              <button
                                 onClick={() => setSidebarOpen(false)}
                                 type='button'
                                 className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                              >
                                 <span className='sr-only'>Close sidebar</span>
                                 <XIcon className='h-6 w-6 text-white' />
                              </button>
                           </div>
                        </Transition>

                        <div className='flex-shrink-0 flex items-center px-4'>
                           <img
                              className='h-8 w-auto cursor-pointer'
                              src={logo_dark}
                              alt='Sacramatic Logo'
                              onClick={() => {
                                 setSidebarOpen(false);
                                 navigateToDashboard();
                              }}
                           />
                        </div>
                        <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                           <SidebarItemsComp
                              primarySidebarItems={primarySidebarItems}
                              setSidebarOpen={setSidebarOpen}
                              mobile
                           />
                        </div>
                     </div>
                     <div className='flex-shrink-0 w-14' aria-hidden='true'>
                        {/*  Dummy element to force sidebar to shrink to fit close icon  */}
                     </div>
                  </div>
               </Transition>
            </div>
         </div>

         {/*  Static sidebar for desktop  */}
         <div className='hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-700'>
            <div className='flex items-center flex-shrink-0 px-6'>
               <img
                  className='h-8 w-auto text-gray-400 cursor-pointer'
                  src={logoSvg}
                  alt='Sacramatic Logo'
                  onClick={navigateToDashboard}
               />
            </div>
            {/*  Sidebar component, swap this element with another sidebar if you like  */}
            <div className='mt-6 h-0 flex-1 flex flex-col overflow-y-auto'>
               <TeacherProfileDropdown />
               {/*  Sidebar Search  */}
               <div className='px-3 mt-5'>
                  <label htmlFor='search' className='sr-only'>
                     Search 6
                  </label>
                  <div className='mt-1 relative rounded-md shadow-sm'>
                     <div
                        className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'
                        aria-hidden='true'
                     >
                        <SearchIcon className='mr-3 h-4 w-4 text-gray-400' />
                     </div>
                     <input
                        type='search'
                        {...searchInputAttr}
                        className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm bg-gray-700 text-gray-300 border-gray-300 rounded-md'
                        placeholder='Search'
                     />
                  </div>
               </div>
               {/*  Navigation  */}
               <SidebarItemsComp
                  mobile={false}
                  primarySidebarItems={primarySidebarItems}
                  setSidebarOpen={setSidebarOpen}
               />
            </div>
         </div>
      </>
   );
}

const MobileHeader = ({
   setSidebar,
}: {
   setSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
   const { searchInputAttr } = useSearch();
   return (
      <div className='sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden'>
         {/* <!-- Sidebar toggle, controls the 'sidebarOpen' sidebar state. --> */}
         <button
            onClick={() => setSidebar(prev => !prev)}
            type='button'
            className='px-4 border-r cursor-pointer border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden'
         >
            <span className='sr-only'>Open sidebar</span>
            <MenuAlt1Icon className='h-6 w-6' />
         </button>
         <div className='flex-1 flex justify-between px-4 sm:px-6 lg:px-8'>
            <div className='flex-1 flex'>
               <form className='w-full flex md:ml-0' action='#' method='GET'>
                  <label htmlFor='search-field' className='sr-only'>
                     Search
                  </label>
                  <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                     <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                        <SearchIcon className='h-5 w-5' />
                     </div>
                     <input
                        className='block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm'
                        placeholder='Search'
                        type='search'
                        {...searchInputAttr}
                     />
                  </div>
               </form>
            </div>
            <div className='flex items-center'>
               {/* <!-- Profile dropdown --> */}
               <TeacherProfileDropdown mobile />
            </div>
         </div>
      </div>
   );
};
