import { Fragment, HTMLAttributes, useEffect, useRef } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';

type Props1<T extends string[] | readonly string[]> = {
   items: T;
   selectedState: [
      string,
      //  React.Dispatch<React.SetStateAction<string>>
      (value: string) => void
   ];
   selectedValue?: T[number];
   defaultValue?: string;
   nullishName?: string;
} & HTMLAttributes<HTMLDivElement>;

type Props2 = {
   items:
      | Record<'name' | 'value', any>[]
      | readonly Record<'name' | 'value', any>[];
   selectedState: [
      Record<'name' | 'value', any> | object,
      // React.Dispatch<React.SetStateAction<Record<'name' | 'value', any>>>
      (value: Record<'name' | 'value', any>) => void
   ];
   selectedValue?: any;
   defaultValue?: string;
   // nullishName?: string;
} & HTMLAttributes<HTMLDivElement>;

function FilterDropdown<T extends string[] | readonly string[]>(
   props: Props1<T>
): JSX.Element;
function FilterDropdown(props: Props2): JSX.Element;

function FilterDropdown<T extends string[] | readonly string[]>(
   props: Props1<T> | Props2
) {
   const { items, selectedState, selectedValue, defaultValue, ...attr } = props;
   if ('nullishName' in attr) delete attr.nullishName;
   const [selected, setSelected] = selectedState;

   const firstRender = useRef(true);

   useEffect(() => {
      if (!firstRender.current) {
      } else if (
         typeof selected === 'string'
            ? selected
               ? selectedValue && selected === selectedValue
               : !selectedValue && selected != null
            : typeof selected === 'object' && 'value' in selected
            ? selected.value
            : false
      ) {
         firstRender.current = false;
      } else if (!selectedValue) {
         if (typeof selected === 'string') {
            if (selected !== '') {
               firstRender.current = false;
            } else {
               (setSelected as Props1<T>['selectedState'][1])('');
            }
         } else {
            (setSelected as Props2['selectedState'][1])(
               (items as Props2['items']).find(item => item.value === '')!
            );
         }
         // firstRender.current = false;
      } else {
         if (typeof selected === 'string') {
            (setSelected as Props1<T>['selectedState'][1])(
               (items as Props1<T>['items']).find(
                  item => item === selectedValue
               )!
            );
         } else {
            (setSelected as Props2['selectedState'][1])(
               (items as Props2['items']).find(item =>
                  typeof selectedValue === 'object'
                     ? JSON.stringify(item.value) ===
                       JSON.stringify(selectedValue)
                     : item.value === selectedValue
               )!
            );
         }
         firstRender.current = false;
      }
   }, [items, selectedValue, setSelected, selected]);

   return (
      <div
         className='relative inline-block text-left sm:ml-5 mt-4 sm:mt-0'
         {...attr}
      >
         <Listbox value={selected} onChange={setSelected}>
            <div>
               <Listbox.Button className='inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'>
                  <span className='block truncate'>
                     {typeof selected === 'string'
                        ? selected || defaultValue
                        : typeof selected === 'object'
                        ? !('value' in selected) || !selected.value
                           ? defaultValue ||
                             ('name' in selected && selected.name)
                           : selected?.name
                        : ''}
                  </span>
                  <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' />
               </Listbox.Button>
               <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
               >
                  <Listbox.Options
                     className='origin-top-right absolute sm:right-0 sm:left-auto left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30'
                     aria-orientation='vertical'
                     aria-labelledby='menu-button'
                  >
                     {items.map(item => (
                        <Listbox.Option
                           key={
                              typeof item === 'string'
                                 ? item === '' &&
                                   ((props as Props1<T>)?.nullishName ?? 'All')
                                    ? (props as Props1<T>)?.nullishName ?? 'All'
                                    : item
                                 : item?.name ?? item?.value
                           }
                           className={({ active }) =>
                              `relative block pl-8 pr-4 py-2 text-sm cursor-pointer ${
                                 active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700'
                              }`
                           }
                           value={item}
                        >
                           {({ selected }) => (
                              <>
                                 <span
                                    className={`block truncate ${
                                       selected ? 'font-medium' : 'font-normal'
                                    }`}
                                 >
                                    {typeof item === 'string'
                                       ? item === '' &&
                                         ((props as Props1<T>)?.nullishName ??
                                            'All')
                                          ? (props as Props1<T>)?.nullishName ??
                                            'All'
                                          : item
                                       : item?.name}
                                 </span>
                                 {selected ? (
                                    <span className='absolute inset-y-0 left-0 flex items-center pl-2 text-amber-600'>
                                       <CheckIcon
                                          className='h-5 w-5'
                                          aria-hidden='true'
                                       />
                                    </span>
                                 ) : null}
                              </>
                           )}
                        </Listbox.Option>
                     ))}
                  </Listbox.Options>
               </Transition>
            </div>
         </Listbox>
      </div>
   );
}

export default FilterDropdown;
