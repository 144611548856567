import { Outlet } from 'react-router-dom';
import MobileHeader from './MobileHeader';
import Sidebar from './Sidebar';
import { useState } from 'react';
import TrialAlert from '../../components/UI/TrialAlert';
import Suspense from '../../components/UI/Suspense';

const DashboardLayout = () => {
   const [mSidebarOpen, setMSidebarOpen] = useState(false);

   return (
      <div className='min-h-full'>
         {/*  Off-canvas menu for mobile, show/hide based on off-canvas menu state.  */}
         <Sidebar sidebarOpen={mSidebarOpen} setSidebarOpen={setMSidebarOpen} />
         <MobileHeader setSidebar={setMSidebarOpen} />

         {/*  Main column  */}
         <div className='lg:pl-64 flex flex-col'>
            <TrialAlert>
               <Suspense>
                  <Outlet />
               </Suspense>
            </TrialAlert>
         </div>
      </div>
   );
};

export default DashboardLayout;
