import { useContext } from 'react';
import SearchContext from '../context/SidebarSearchContext';

const useSearch = () => {
   const context = useContext(SearchContext);
   if (!context) {
      throw new Error('useSearchContext must be used within a SearchProvider');
   }
   return context;
};

export default useSearch;
