import { Link, useNavigate } from 'react-router-dom';
import useBaptismContext from '../../../context/Baptisms/BaptismContext';
import { useQuery } from '@tanstack/react-query';
import PageSpinner from '../../../components/UI/PageSpinner';
import { TBaptismDate } from '../../../types/Baptisms/BaptismDates';
import BreadCrumb from '../../../components/UI/BreadCrumb';

export default function BaptismDates() {
   const navigate = useNavigate();
   const { getBaptismDates } = useBaptismContext();

   const { data: baptismDates, isLoading } = useQuery({
      queryFn: () => getBaptismDates(),
      queryKey: ['baptismDates'],
   });

   return (
      <main className='flex-1'>
         {/* <!-- BREADCRUMBS --> */}
         <BreadCrumb
            homeLink='/dashboard'
            items={[
               { link: '/baptisms', title: 'Baptisms' },
               {
                  title: 'Baptism Dates',
                  active: true,
               },
            ]}
         />

         {/* <!-- messages --> */}
         <div className='max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto mb-5 mt-10'>
            {/* <!--table--> */}
            <div className=''>
               <div className='sm:flex sm:items-center'>
                  <div className='sm:flex-auto'>
                     <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                        Baptism Dates
                     </h4>
                     <p className='mt-2 text-sm text-gray-700'>
                        A list of upcoming baptism dates.
                     </p>
                  </div>
                  <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
                     <button
                        type='button'
                        onClick={() => navigate('add')}
                        className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                     >
                        Add Baptism Date
                     </button>
                  </div>
               </div>
               <div className='mt-8 flow-root'>
                  <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                     <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                        {isLoading ? (
                           <PageSpinner height='20rem' />
                        ) : (
                           <table className='min-w-full divide-y divide-gray-300'>
                              <thead>
                                 <tr>
                                    <th
                                       scope='col'
                                       className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                                    >
                                       Date
                                    </th>
                                    <th
                                       scope='col'
                                       className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                    >
                                       Time
                                    </th>
                                    <th
                                       scope='col'
                                       className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                    >
                                       Officiant
                                    </th>

                                    <th
                                       scope='col'
                                       className='relative py-3.5 pl-3 pr-4 sm:pr-0'
                                    >
                                       <span className='sr-only'>Edit</span>
                                    </th>
                                 </tr>
                              </thead>
                              <tbody className='divide-y divide-gray-200'>
                                 {(baptismDates ?? []).map(baptismDate => (
                                    <BaptismDateRow
                                       baptismDate={baptismDate}
                                       key={baptismDate._id}
                                    />
                                 ))}
                              </tbody>
                           </table>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   );
}

function BaptismDateRow({ baptismDate }: { baptismDate: TBaptismDate }) {
   const date = new Date(baptismDate.date);
   return (
      <tr>
         <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
            {date.toLocaleDateString('en-us', {
               month: 'long',
               day: 'numeric',
               year: 'numeric',
            })}
         </td>
         <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
            {date.toLocaleTimeString('en-us', {
               hour: 'numeric',
               minute: 'numeric',
            })}
         </td>
         <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
            {typeof baptismDate?.officiant === 'object'
               ? baptismDate.officiant.firstname +
                 ' ' +
                 baptismDate.officiant?.lastname
               : ''}
         </td>
         <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
            <Link
               to={`${baptismDate._id}/edit`}
               className='text-indigo-600 hover:text-indigo-900'
            >
               Edit
               <span className='sr-only'></span>
            </Link>
         </td>
      </tr>
   );
}
