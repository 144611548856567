import { Link } from 'react-router-dom';
import videoIcon from '../../assets/videos.png';
import profileIcon from '../../assets/profile.png';
import faqIcon from '../../assets/faq.png';
import contactIcon from '../../assets/contact.png';
import useAuth from '../../hooks/useAuth';
import { useAppContext } from '../../context/AppContext';
import { useQuery } from '@tanstack/react-query';
import WeddingTable from '../../components/Dashboard/WeddingTable';
import FuneralsTable from '../../components/Dashboard/FuneralsTable';
import Stats from '../../components/Dashboard/Stats';
import useWeddingContext from '../../hooks/useWeddingContext';
import { useMemo } from 'react';
import { IBaptism } from '../../types/Baptisms/Baptisms';
import BaptismsTable from '../../components/Dashboard/BaptismsTable';

export default function Dashboard() {
   const { auth }: any = useAuth();
   const {
      countUpcomingFunerals,
      countUpcomingEvents,
      countUpcomingWeddings,
      getUpcomingFunerals,
      getUpcomingDatesWithBaptisms,
   } = useAppContext();

   const { upcomingWeddings }: any = useWeddingContext();

   const funeralsCountQuery = useQuery({
      queryFn: countUpcomingFunerals,
      queryKey: ['count', 'upcoming_funerals'],
   });

   const upcomingFuneralsQuery = useQuery({
      queryFn: () => getUpcomingFunerals('upcoming'),
      queryKey: ['upcomingFunerals'],
   });

   const eventsCountQuery = useQuery({
      queryFn: countUpcomingEvents,
      queryKey: ['count', 'upcoming_events'],
   });

   const weddingsCountQuery = useQuery({
      queryFn: countUpcomingWeddings,
      queryKey: ['count', 'upcoming_weddings'],
   });

   const { data: upcomingDatesWithBaptisms } = useQuery({
      queryFn: getUpcomingDatesWithBaptisms,
      queryKey: ['upcomingDatesWithBaptisms'],
   });

   const upcomingBaptisms: IBaptism[] = useMemo(() => {
      return (upcomingDatesWithBaptisms ?? []).flatMap(upcomingDate =>
         Array.isArray(upcomingDate?.baptisms)
            ? (upcomingDate.baptisms as IBaptism[])
            : []
      );
   }, [upcomingDatesWithBaptisms]);

   return (
      <main className='flex-1'>
         {/* <!-- BREADCRUMBS --> */}
         <nav
            className='bg-white border-b border-gray-200 flex'
            aria-label='Breadcrumb'
         >
            <ol className='max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8'>
               <li className='flex'>
                  <div className='flex items-center'>
                     {/* <!-- Heroicon name: mini/home --> */}
                     <svg
                        className='flex-shrink-0 h-5 w-5'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        aria-hidden='true'
                     >
                        <path
                           fillRule='evenodd'
                           d='M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z'
                           clipRule='evenodd'
                        />
                     </svg>
                     <span className='sr-only'>Home</span>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     Sacramatic Home Dashboard
                  </div>
               </li>
            </ol>
            <div className='mr-10 flex items-center'>
               {/* <!--notifications icon--> */}

               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6 mr-4'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     d='M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0'
                  />
               </svg>

               {/* <!--profile icon--> */}
               <Link to='/settings/manage-profile'>
                  <svg
                     xmlns='http://www.w3.org/2000/svg'
                     fill='none'
                     viewBox='0 0 24 24'
                     strokeWidth='1.5'
                     stroke='currentColor'
                     className='w-6 h-6 mr-4'
                  >
                     <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                     />
                  </svg>
               </Link>

               {/* <!--help icon--> */}
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6 mr-4'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                  />
               </svg>

               {/* <!--vidoes--> */}

               {/* <!--settings icon--> */}

               <Link to='/settings'>
                  <svg
                     xmlns='http://www.w3.org/2000/svg'
                     fill='none'
                     viewBox='0 0 24 24'
                     strokeWidth='1.5'
                     stroke='currentColor'
                     className='w-6 h-6'
                  >
                     <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z'
                     />
                     <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                     />
                  </svg>
               </Link>
            </div>
         </nav>

         {/* <!-- Resources --> */}

         {/* <!--Stats--> */}
         <p className='text-xs text-right mr-20 mt-5'>
            {auth?.church ?? ''} - Account {auth?.churchID ?? ''}
         </p>

         <Stats
            eventsCount={eventsCountQuery?.data}
            funeralsCount={funeralsCountQuery?.data}
            weddingsCount={weddingsCountQuery?.data}
            baptismsCount={upcomingBaptisms?.length}
         />

         <BaptismsTable baptismDates={upcomingDatesWithBaptisms ?? []} />

         <FuneralsTable funerals={upcomingFuneralsQuery?.data ?? []} />

         <WeddingTable weddings={upcomingWeddings} />

         <div className='max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto mb-40'>
            <div className='mb-10'>
               <div className='flex items-center'>
                  <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                     Resources
                  </h4>

                  <div className='flex-1 border-t-2 border-gray-200'></div>
               </div>
            </div>
            {/* <!-- Grid --> */}
            <div className='grid lg:grid-cols-2 lg:gap-y-16 gap-10'>
               {/* <!-- Card --> */}
               <Link
                  className='group rounded-xl overflow-hidden'
                  to='/dashboard/video-training'
               >
                  <div className='sm:flex'>
                     <div className='flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44'>
                        <img
                           className='group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 left-0 object-cover rounded-xl'
                           src={videoIcon}
                           alt='Video Icon'
                           loading='lazy'
                        />
                     </div>

                     <div className='grow mt-4 sm:mt-0 sm:ml-6 px-4 sm:px-0'>
                        <h3 className='text-xl font-semibold text-gray-900 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white'>
                           Video Training
                        </h3>
                        <p className='mt-3 text-gray-600 dark:text-gray-400'>
                           Learn how to use Sacramatic Software by watching our
                           series of training videos.
                        </p>
                        <p className='mt-4 inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium'>
                           More
                           <svg
                              className='w-2.5 h-2.5'
                              width='16'
                              height='16'
                              viewBox='0 0 16 16'
                              fill='none'
                           >
                              <path
                                 d='M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14'
                                 stroke='currentColor'
                                 strokeWidth='2'
                                 strokeLinecap='round'
                              />
                           </svg>
                        </p>
                     </div>
                  </div>
               </Link>
               {/* <!-- End Card --> */}

               {/* <!-- Card --> */}
               <Link
                  to='/settings/manage-profile'
                  className='group rounded-xl overflow-hidden'
               >
                  <div className='sm:flex'>
                     <div className='flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44'>
                        <img
                           className='group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 left-0 object-cover rounded-xl'
                           src={profileIcon}
                           alt='Profile icon'
                           loading='lazy'
                        />
                     </div>

                     <div className='grow mt-4 sm:mt-0 sm:ml-6 px-4 sm:px-0'>
                        <h3 className='text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white'>
                           Your Profile
                        </h3>
                        <p className='mt-3 text-gray-600 dark:text-gray-400'>
                           Manage your personal profile including photo,
                           password and software preferences.
                        </p>
                        <p className='mt-4 inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium'>
                           More
                           <svg
                              className='w-2.5 h-2.5'
                              width='16'
                              height='16'
                              viewBox='0 0 16 16'
                              fill='none'
                           >
                              <path
                                 d='M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14'
                                 stroke='currentColor'
                                 strokeWidth='2'
                                 strokeLinecap='round'
                              />
                           </svg>
                        </p>
                     </div>
                  </div>
               </Link>
               {/* <!-- End Card --> */}

               {/* <!-- Card --> */}
               <Link className='group rounded-xl overflow-hidden' to='/faq'>
                  <div className='sm:flex'>
                     <div className='flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44'>
                        <img
                           className='group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 left-0 object-cover rounded-xl'
                           src={faqIcon}
                           alt='FAQ Icon'
                           loading='lazy'
                        />
                     </div>

                     <div className='grow mt-4 sm:mt-0 sm:ml-6 px-4 sm:px-0'>
                        <h3 className='text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white'>
                           Frequently Asked Questions
                        </h3>
                        <p className='mt-3 text-gray-600 dark:text-gray-400'>
                           How to make objectives and key results work for your
                           company
                        </p>
                        <p className='mt-4 inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium'>
                           More
                           <svg
                              className='w-2.5 h-2.5'
                              width='16'
                              height='16'
                              viewBox='0 0 16 16'
                              fill='none'
                           >
                              <path
                                 d='M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14'
                                 stroke='currentColor'
                                 strokeWidth='2'
                                 strokeLinecap='round'
                              />
                           </svg>
                        </p>
                     </div>
                  </div>
               </Link>
               {/* <!-- End Card --> */}

               {/* <!-- Card --> */}
               <a
                  className='group rounded-xl overflow-hidden'
                  href='mailto:shannon@sacramatic.com'
               >
                  <div className='sm:flex'>
                     <div className='flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44'>
                        <img
                           className='group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 left-0 object-cover rounded-xl'
                           src={contactIcon}
                           alt='Customer Service Icon'
                           loading='lazy'
                        />
                     </div>

                     <div className='grow mt-4 sm:mt-0 sm:ml-6 px-4 sm:px-0'>
                        <h3 className='text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white'>
                           Contact Us
                        </h3>
                        <p className='mt-3 text-gray-600 dark:text-gray-400'>
                           Have a question about Sacramatic? Feel free to drop
                           us a note.
                        </p>
                        <p className='mt-4 inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium'>
                           More
                           <svg
                              className='w-2.5 h-2.5'
                              width='16'
                              height='16'
                              viewBox='0 0 16 16'
                              fill='none'
                           >
                              <path
                                 d='M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14'
                                 stroke='currentColor'
                                 strokeWidth='2'
                                 strokeLinecap='round'
                              />
                           </svg>
                        </p>
                     </div>
                  </div>
               </a>
               {/* <!-- End Card --> */}
            </div>
            {/* <!-- End Grid --> */}
         </div>
         {/* <!-- End Card Blog --> */}
      </main>
   );
}
