import { useQuery } from '@tanstack/react-query';
import useBaptismContext from '../../../context/Baptisms/BaptismContext';
import { TBaptismDate } from '../../../types/Baptisms/BaptismDates';
import { IBaptism } from '../../../types/Baptisms/Baptisms';
import PageSpinner from '../../../components/UI/PageSpinner';

export default function BaptismsNotesReport() {
   const { getUpcomingDatesWithBaptisms } = useBaptismContext();

   const { data: upcomingDatesWithBaptisms, isLoading } = useQuery({
      queryFn: getUpcomingDatesWithBaptisms,
      queryKey: ['upcomingDatesWithBaptisms'],
   });
   return (
      <main className='flex-1'>
         <div className='max-w-4xl sm:px-6 lg:pr-8 mb-40'>
            <div className='flex items-center justify-between flex-wrap'>
               <h1 className='text-4xl font-bold mt-20 ml-5 mb-5 sm:mb-10'>
                  Upcoming Baptisms Notes Report
               </h1>
            </div>
            {isLoading ? (
               <PageSpinner height='20rem' />
            ) : (
               (upcomingDatesWithBaptisms ?? []).map(upcomingDate => (
                  <DateComp key={upcomingDate._id} baptismDate={upcomingDate} />
               ))
            )}
         </div>
      </main>
   );
}

function DateComp({ baptismDate }: { baptismDate: TBaptismDate }) {
   return (
      <div className='bg-gray-100 border-dashed' key={baptismDate._id}>
         <div className='mx-auto max-w-lg overflow-hidden rounded-lg lg:flex lg:max-w-none'>
            <div className='flex-1 bg-white px-6 lg:p-6'>
               <div className=''>
                  <div className='flex items-center'>
                     <h4 className='flex-shrink-0 bg-white pr-4 text-3xl font-semibold text-indigo-600'>
                        {new Date(baptismDate.date).toLocaleString('en-us', {
                           year: 'numeric',
                           day: 'numeric',
                           month: 'long',
                           hour: 'numeric',
                           minute: 'numeric',
                           weekday: 'long',
                        })}
                     </h4>
                     <div className='flex-1 border-t-2 border-gray-200'></div>
                  </div>
               </div>

               {(baptismDate?.baptisms ?? []).map(baptism =>
                  typeof baptism === 'object' && baptism?.notes ? (
                     <BaptismComp key={baptism._id} baptism={baptism} />
                  ) : null
               )}
            </div>
         </div>
      </div>
   );
}

function BaptismComp({ baptism }: { baptism: IBaptism }) {
   return (
      <>
         <p className='text-base font-bold text-gray-900 sm:tracking-tight mt-5 ml-5'>
            {baptism.firstname} {baptism?.middlename ?? ''} {baptism.lastname}
         </p>
         <p className='mt-3 text-base text-gray-500 ml-5'>{baptism.notes}</p>
      </>
   );
}
