import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AUTH_API } from '../../../utils/constants';

export default function AuthorizeTeacher() {
   const axiosPrivate = useAxiosPrivate();
   const { setAuth } = useAuth();
   const [searchParams] = useSearchParams();
   const token = searchParams.get('token');
   const urlToNavigate = searchParams.get('u');
   const navigate = useNavigate();

   const mounted = useRef(true);

   useEffect(() => {
      (async () => {
         if (mounted.current === true) {
            try {
               const res = await axiosPrivate.get(
                  `${AUTH_API}/teacher/login?token=${token}`
               );
               const accessToken = res?.data?.accessToken;
               const church = res?.data?.church;
               const firstname = res?.data?.firstname;
               const lastname = res?.data?.lastname;
               setAuth({
                  user: res.data?.user,
                  accessToken,
                  church,
                  firstname,
                  lastname,
               });
               if (urlToNavigate != null)
                  return navigate(decodeURIComponent(urlToNavigate));
               navigate('/re/teacher', { replace: true });
            } catch (err: any) {
               if (err?.code === 'ERR_NETWORK') {
                  toast.error('No Server Response');
               } else if (err.response?.data?.message) {
                  toast.error(err.response.data.message);
               } else {
                  toast.error('Something went wrong! Try again.');
               }
               navigate('/login', { replace: true });
            }
         }
      })();
      mounted.current = false;
   }, [axiosPrivate, navigate, token, setAuth, urlToNavigate]);

   return (
      <div
         className='flex items-center justify-center w-full'
         style={{ height: '80vh' }}
      >
         <div className='w-24 h-24 border-l-2 border-t-2 border-gray-900 rounded-full animate-spin'></div>
      </div>
   );
}
